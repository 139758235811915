import _ from "lodash";

function createCategoryTree(categories) {
  const roots = _.sortBy(_.remove(categories, category => category.parentId == null), "label");
  fillChildCategories(categories, roots);
  if (categories.length > 0) {
    console.log("Found orphan nodes: \n", categories);
  }
  return roots;
}

function fillChildCategories(categories, nodes) {
  for (let node of nodes) {
    let children = _.remove(categories, category => category.parentId == node.id);
    node.children = _.sortBy(children, "label");
    if (children.length > 0) {
      fillChildCategories(categories, children);
    }
  }
}

export default createCategoryTree;
