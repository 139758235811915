import React from "react";
import styled from "styled-components";
import _ from "lodash";
import QRCode from "qrcode.react";
import { Col, Row } from "../components/FlexboxGrid";
import Tag from "../components/Tag";
import LatexContent from "../components/LatexContent";
import ApiCaller from "../utils/ApiCaller";
import compose from "../utils/compose";
import didMount from "../enhancers/didMount";
import AppState from "../AppState";
import { store, view } from "react-easy-state";
import { grades, questionTypes, difficulties } from "../utils/constants";
import config from "../../config";

const pageData = new store({ categories: [] });

const Page = styled(Col)`
  text-align: left;
  > div {
    margin-bottom: 0.8em;
  }
`;

const OptionList = styled(Row)`
  flex-wrap: wrap;
  width: 100%;
  .option1 {
    width: 100%;
  }
  .option2 {
    width: 50%;
    padding-right: 1rem;
  }
  .option3 {
    width: 33%;
    padding-right: 1rem;
  }
  .option4 {
    width: 25%;
    padding-right: 1rem;
  }
`;

const OptionItem = styled(Row)`
  align-items: center;
  margin-bottom: 0.5em;
  flex-wrap: nowrap;
  input {
    margin-right: 0.5em;
  }
  label {
    margin-right: 1em;
    margin-bottom: 0;
  }
`;

const QuestionOptions = ({ options, answer, optionsPerLine }) => {
  return (
    <OptionList>
      {options &&
        options.map(
          option =>
            option.value && (
              <OptionItem className={"option" + optionsPerLine} key={option.label}>
                <input type="checkbox" readOnly={true} checked={_.includes(answer, option.label)} />
                <label>{option.label}.</label>
                <LatexContent latex={option.value.content} />
              </OptionItem>
            )
        )}
    </OptionList>
  );
};

const QuestionDetail = ({ question }) => {
  const {
    id,
    subject,
    grade,
    type,
    tags,
    body,
    options,
    correctOptions,
    difficulty,
    answer,
    analysis,
    from,
    subQuestions,
  } = question;
  return (
    <Page>
      <Row>
        科目：
        {AppState.subjects[subject]}
      </Row>
      <Row>
        年级：
        {grades[grade]}
      </Row>
      <Row>
        类型：
        {questionTypes[type]}
      </Row>
      <Row valign="middle">
        归类：
        {tags && tags.map((tag, i) => <Tag key={i}>{tag}</Tag>)}
      </Row>
      {from && <Row>来源：{[from.year, from.area, from.paper, from.number].join("-")}</Row>}
      <Row valign="middle">
        知识点：
        {pageData.categories.map((category, i) => (
          <Tag key={i}>{category.name}</Tag>
        ))}
      </Row>
      <Row>
        题干：
        <div>
          <LatexContent latex={body.content} />
          {subQuestions &&
            subQuestions.map((subQuestion, index) => (
              <Row key={index}>
                <label>( {index + 1} )&nbsp;&nbsp;</label>
                <LatexContent latex={subQuestion.body.content} />
              </Row>
            ))}
        </div>
      </Row>
      {(type == "choice" || type == "multichoice") && (
        <Row>
          选项：
          <QuestionOptions options={options} answer={correctOptions} optionsPerLine={1} />
        </Row>
      )}
      {(type == "fill" || type == "essay") && (
        <Row>
          答案：
          <LatexContent latex={answer.content} />
        </Row>
      )}
      {(type == "fillMulti" || type == "essayMulti") && (
        <Row>
          答案：
          {subQuestions && (
            <div>
              {subQuestions.map((subQuestion, index) => (
                <Row key={index}>
                  <label>( {index + 1} )&nbsp;&nbsp;</label>
                  <LatexContent latex={subQuestion.answer.content} />
                </Row>
              ))}
            </div>
          )}
        </Row>
      )}
      <Row>
        解析：
        {subQuestions ? (
          <div>
            {subQuestions.map((subQuestion, index) => (
              <Row key={index}>
                <label>( {index + 1} )&nbsp;&nbsp;</label>
                <LatexContent latex={subQuestion.analysis.content} />
              </Row>
            ))}
          </div>
        ) : (
          analysis && <LatexContent latex={analysis.content} />
        )}
      </Row>
      <Row>
        难度：
        {difficulties[difficulty]}
      </Row>
      <Row>
        视频：
        {pageData.liveCover && pageData.videoFile ? (
          <>
            <img src={pageData.liveCover} onClick={() => playLive(pageData.videoFile.ccvid)} />
            <span>&nbsp;ID: {pageData.videoFile.ccvid}</span>
          </>
        ) : (
          "-"
        )}
      </Row>
      <Row>
        二维码：
        <QRCode
          value={`${config.mkWebServer}/wechat/?channel=timuqrcode#/wechat/questionAnalysis/${id}`}
        />
      </Row>
    </Page>
  );
};

async function playLive(ccvid) {
  pageData.playLiveUrl = await ApiCaller.getPlayLiveUrl(ccvid);
  window.open(`${pageData.playLiveUrl}`);
}

export default compose(
  didMount(async p => {
    pageData.categories = await ApiCaller.getCategories(_.map(p.question.categories, "id"));
    if (p.question.videoFile) {
      pageData.videoFile = p.question.videoFile;
      pageData.liveCover = await ApiCaller.getLiveCover(p.question.videoFile.ccvid);
    } else {
      pageData.videoFile = null;
      pageData.liveCover = null;
    }
  }),
  view
)(QuestionDetail);
