import React from "react";
import styled, { css } from "styled-components";
import { Col, Row } from "../components/FlexboxGrid";
import Popup from "../widgets/Popup";
import Button from "../components/Button";
import ApiCaller from "../utils/ApiCaller";
import _ from "lodash";

const ValidationError = styled(Row)`
  color: red;
  font-size: 14px;
  margin: 0 10px;
`;

const AddInput = styled(Row)`
  input {
    width: 100%;
    height: 36px;
    line-height: 36px;
    outline: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    padding: 0 15px;
  }
`;

const ButtonGroup = styled(Row)`
  display: flex;
  justify-content: space-around;
  button {
    width: 100px;
  }
  margin-top: 10px;
`;

export default class AddQuestionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async addQuestion() {
    const questions = await ApiCaller.fetchQuestions([
      this.state.addQuestionId,
    ]);
    if (questions[0]) {
      if (
        _.indexOf(_.map(this.props.questionCartData, "id"), questions[0].id) ==
        -1
      ) {
        if (this.props.type == "addQuestion") {
          this.props.addQuestion(questions);
        } else {
          this.props.replaceQuestion(questions);
        }
      } else {
        this.setState({ addQuestionError: "此题目ID已存在" });
      }
    } else {
      this.setState({ addQuestionError: "ID错误" });
    }
  }

  render() {
    return (
      <Popup title={this.props.type == "addQuestion" ? "添加题目" : "替换题目"}>
        <AddInput>
          <input
            placeholder="请输入题目或题组ID添加题目"
            onChange={event => (this.state.addQuestionId = event.target.value)}
          />
        </AddInput>
        {this.state.addQuestionError && (
          <ValidationError>{this.state.addQuestionError}</ValidationError>
        )}
        <ButtonGroup>
          <Button primary onClick={() => this.props.dialogClose()}>
            取消
          </Button>
          <Button primary onClick={() => this.addQuestion()}>
            提交
          </Button>
        </ButtonGroup>
      </Popup>
    );
  }
}
