import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";

const RadioItem = styled.label`
  margin-right: 2em;
  margin-bottom: 0;
  input {
    margin-right: 0.5em;
    transform: translateY(-0.1em);
  }
`;

export default class RadioButtonList extends Component {
  static propTypes = {
    name: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    theme: "default",
  };

  state = { value: this.props.value || "" };

  radioChange = value => {
    return event => {
      if (event.target.checked) {
        this.setState({ value });
        this.props.onChange && this.props.onChange(value);
      }
    };
  };

  get value() {
    return this.state.value;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value != this.value) {
      this.setState({ value: nextProps.value });
    }
  }

  render() {
    const {
      name,
      options,
      value,
      theme,
      disabled,
      onChange,
      ...other
    } = this.props;

    const createRadio = (option, i) => {
      const { label, value } =
        typeof option == "string" ? { label: option, value: option } : option;
      const checked = this.state.value == value;
      return (
        <RadioItem key={i} className={{ disabled, checked }}>
          <input
            type="radio"
            name={name}
            disabled={disabled}
            checked={checked}
            onChange={this.radioChange(value)}
          />
          {label}
        </RadioItem>
      );
    };

    return (
      <div className={`radio-list theme-${theme}`} {...other}>
        {options.map(createRadio)}
      </div>
    );
  }
}
