import Session from "./Session";
import ApiCaller from "../utils/ApiCaller";

class AccountManager {
  async loadAccount() {
    let account = Session.current();
    if (account != null) {
      account = await ApiCaller.getCurrentUser();
      if (account == null) {
        Session.destroy();
      }
    }
    if (account == null) {
      window.history.pushState(null, null, "/login");
      return {};
    } else {
      return account;
    }
  }

  logout() {
    Session.destroy();
  }
}

export default new AccountManager();
