import React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import { Col, Row } from "../components/FlexboxGrid";
import LatexContent from "../components/LatexContent";
import QuestionItem from "./QuestionItem";

const Card = styled(Col)`
  margin-bottom: 20px;
  background-color: #fff;
  color: #303133;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  border-radius: 4px;
  transition: 0.3s;
  overflow: hidden;
  width: 100%;
`;

const CardHeader = styled(Row)`
  padding: 18px 0 18px 20px;
  border-bottom: 1px solid #ebeef5;
  h4,
  label {
    margin: 0;
  }
`;

const CardBody = styled(Col)`
  padding: 18px 20px;
`;

const CardDiv = styled(Col)`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 18px;
`;

const QuestionCard = styled(Col)`
  border: 1px solid #ebeef5;
  width: 100%;
`;

const QuestionGroup = ({
  container = Card,
  questionGroup,
  buttons,
  questionButtons,
}) => {
  const { id, internalName, body, groupQuestions } = questionGroup;
  const Container = container;
  return (
    <Container>
      <CardHeader space="between">
        <h4>
          ID:
          {id}
          &nbsp;&nbsp;&nbsp;
          {internalName}
        </h4>
        {buttons}
      </CardHeader>
      <CardBody>
        <LatexContent latex={body.content} />
        {groupQuestions.map((question, index) => (
          <CardDiv key={index}>
            <div>({index + 1})</div>
            <QuestionItem
              container={QuestionCard}
              question={question}
              buttons={questionButtons && questionButtons(question)}
            />
          </CardDiv>
        ))}
      </CardBody>
    </Container>
  );
};

export default QuestionGroup;
