import React from "react";
import styled from "styled-components";
import { store, view } from "react-easy-state";
import { Link } from "react-router-dom";
import _ from "lodash";
import queryString from "query-string";
import compose from "../utils/compose";
import PageFrame from "../widgets/PageFrame";
import DateTime from "../components/DateTime";
import Table from "../components/Table";
import Pager from "../components/Pager";
import parseQuery from "../enhancers/parseQuery";
import fetchData from "../enhancers/fetchData";
import Button from "../components/Button";
import SearchInput from "../components/SearchInput";
import LinkButton from "../components/LinkButton";
import Dialog from "../components/Dialog";
import Popup from "../widgets/Popup";
import axios from "axios";
import Svg from "react-svg";
// import { PdfViewer } from "../components/PdfViewer";
import Alert from "../components/Alert";
import didMount from "../enhancers/didMount";
import DataStore from "../utils/DataStore";
import ApiCaller from "../utils/ApiCaller";
import PaperPreview from "../widgets/PaperPreview";
import MultiSelector from "../components/MultiSelector";
import { createOptions } from "../components/Selector";
import { subjects, grades, questionTags, examinationPaperFuns } from "../utils/constants";
import { Col, Row } from "../components/FlexboxGrid";
import appConfig from "../../config";
import hasPermission from "../utils/hasPermission";
import AppState from "../AppState";
import DropdownList from "../components/DropdownList";

const DefaultPageSize = 10;
const dataStore = new DataStore();
const GroupSearch = styled.div`
  flex-grow: 3;
`;
const GroupBtn = styled.div`
  display: flex;
  button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-color: hsla(0, 0%, 100%, 0.5);
    margin: 16px 0 16px 0;
  }
  button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin: 16px 16px 16px 0;
  }
  button {
    margin: 0;
    display: flex;
    svg {
      fill: #fff;
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }
  }
`;

const FilterBox = styled(Col)`
  padding: 0.5em;
  border: 1px solid #ebeef5;
  margin-bottom: 10px;
`;

const FieldRow = styled(Row)`
  margin-bottom: 0.8em;
`;

const ExamPaperStatusFilter = styled(DropdownList)`
  height: 36px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  color: #606266;
  background: #fff;
  margin-right: 5px;
`;

async function htmlPreview(paper) {
  const questions = await ApiCaller.fetchQuestions(_.map(paper.questions, "questionId"));
  await Dialog.show(
    <Popup title="试卷预览">
      <PaperPreview examPaper={paper} questions={questions} />
    </Popup>,
    {
      buttons: false,
      className: "dialog",
    }
  );
}

async function pdfPreview(id) {
  const result = await axios.get("/examinationpapers/preview/" + id);
  if (result.status == 200) {
    if (result.data.pdfUrl) {
      const { PdfViewer } = await import("../components/PdfViewer");
      Dialog.show(
        <Popup title="试卷预览">
          <PdfViewer fileUrl={result.data.pdfUrl} />
        </Popup>,
        {
          buttons: false,
          className: "dialog",
        }
      );
    } else {
      Alert.info("PDF文件未生成");
    }
  } else {
    Alert.info("网络异常");
  }
}

async function deletePaper(paper, reload) {
  if (hasPermission("删除试卷")) {
    const ok = await Alert.warning("确认要删除？", { buttons: ["否", "是"] });
    if (ok === true) {
      const result = await ApiCaller.deleteExamPaper(paper.id);
      if (result.status == 200) {
        reload();
        Alert.info("删除成功");
      } else {
        Alert.warning(result.data);
      }
    }
  } else {
    Alert.warning("没有删除试卷的权限!");
  }
}

async function filterExaminationpapers(history, location, query, newFilter, newOrder) {
  const defaultFilter = JSON.stringify({
    createdBy: hasPermission("查看所有试卷") ? null : AppState.account.id,
  });
  const { order, page, filter = defaultFilter, ...other } = query;
  let newQuery = {
    ...other,
    order: newOrder || order,
    page: 1,
    filter: JSON.stringify({
      ...JSON.parse(filter),
      ...newFilter,
    }),
  };
  history.push(location.pathname + "?" + queryString.stringify(newQuery));
}

function print(paperId, paperName, showInfo, fontSize) {
  let params = ["showQR", "showFrom"];
  if (showInfo) {
    params.push("showInfo");
  }
  if (fontSize) {
    params.push(`fontSize=${fontSize}`);
  }
  params = params.join("&");
  window.open(
    `${appConfig.tikuJobServer}/exampaper/pdf/0/0/%20/${paperId}/${encodeURIComponent(
      paperName
    )}?${params}`
  );
}

function previewAnswer(paperId) {
  window.open(`/examPaper/previewAnswer?id=${paperId}`);
}

const ExamPaperList = props => {
  const { history, location, query, loading, data } = props;
  const filter = JSON.parse(query.filter || "{}");
  if (data == null) return null;
  const reload = () => {
    history.push(location.pathname + "?" + queryString.stringify(query));
  };
  const tagOptions = createOptions(questionTags).concat({
    label: "未归类",
    value: null,
  });
  return (
    <PageFrame
      title="试卷管理"
      actions={
        <Row valign="middle">
          <GroupSearch>
            <ExamPaperStatusFilter
              firstSelected={true}
              defaultValue={filter.submitted || "all"}
              options={[
                { label: "答题状态-全部", value: "all" },
                { label: "答题状态-已答题", value: "yes" },
                { label: "答题状态-未答题", value: "no" },
              ]}
              onChange={value =>
                filterExaminationpapers(history, location, query, {
                  submitted: value,
                })
              }
            />
          </GroupSearch>
          <GroupSearch>
            <SearchInput
              width="300px"
              margin="0 0.5rem 0 0"
              placeholder="请输入试卷ID或标题的关键字进行查询"
              defaultValue={filter.title}
              onEnter={value => {
                const filter = value.match(/[0-9a-fA-F]{24}/)
                  ? { ids: [value], title: null }
                  : { ids: null, title: value };
                filterExaminationpapers(history, location, query, filter);
              }}
            />
          </GroupSearch>
          <GroupSearch>
            <SearchInput
              width="300px"
              margin="0 0.5rem 0 0"
              placeholder="请输入试卷知识点关键字进行查询"
              defaultValue={filter.knowledgePoints}
              onEnter={value =>
                filterExaminationpapers(history, location, query, {
                  knowledgePoints: value,
                })
              }
            />
          </GroupSearch>
          <GroupBtn>
            <Button primary>
              <Svg src={require("../resources/icons/documentation.svg")} />
              {dataStore.state.questionCart && dataStore.state.questionCart.length}
            </Button>
            <Button primary onClick={() => history.push("/examPaper/edit")}>
              组卷中心
            </Button>
          </GroupBtn>
        </Row>
      }
    >
      {/* <FilterBox>
        <FieldRow valign="middle">
          科目：
          <MultiSelector
            selectAll="全选"
            options={createOptions(subjects)}
            values={filter.subjects}
            onChange={values =>
              filterExaminationpapers(history, location, query, {
                subjects: values,
              })
            }
          />
        </FieldRow>
        <FieldRow valign="middle">
          年级：
          <MultiSelector
            selectAll="全选"
            options={createOptions(grades)}
            values={filter.grades}
            onChange={values =>
              filterExaminationpapers(history, location, query, {
                grades: values,
              })
            }
          />
        </FieldRow>
        <FieldRow valign="middle">
          归类：
          <MultiSelector
            selectAll="全选"
            options={tagOptions}
            values={filter.tags}
            onChange={values =>
              filterExaminationpapers(history, location, query, {
                tags: values,
              })
            }
          />
        </FieldRow>
      </FilterBox> */}
      <Table
        headerRow={
          <tr>
            <th>序号</th>
            <th>试卷ID</th>
            <th>试卷标题</th>
            <th>题数</th>
            <th>已引用</th>
            <th>已答卷</th>
            <th>生成时间</th>
            {AppState.account.isAdmin && <th>创建者</th>}
            <th>操作</th>
          </tr>
        }
      >
        {data.papers.length > 0 ? (
          data.papers.map((paper, index) => (
            <tr key={index}>
              <td className="center">{index + 1}</td>
              <td className="center">{paper.id}</td>
              <td className="center">{paper.title}</td>
              <td className="center">{paper.questions.length}</td>
              <td className="center">{paper.referenceCnt}</td>
              <td className="center">{paper.submitCnt}</td>
              <td className="center">{paper.updatedAt && <DateTime value={paper.updatedAt} />}</td>
              {AppState.account.isAdmin && <td className="center">{paper.createdBy}</td>}
              <td className="center">
                <LinkButton onClick={() => previewAnswer(paper.id)}>答案</LinkButton>
                <LinkButton
                  onClick={() =>
                    print(paper.id, paper.title, paper.studentInfoDisplay, paper.printFontSize)
                  }
                >
                  查看
                </LinkButton>
                {hasPermission("编辑试卷") && (
                  <Link to={`/examPaper/edit?id=${paper.id}`} style={{ whiteSpace: "nowrap" }}>
                    编辑
                  </Link>
                )}
                {paper.referenceCnt + paper.submitCnt == 0 && (
                  <LinkButton onClick={() => deletePaper(paper, reload)}>删除</LinkButton>
                )}
                {hasPermission("复制试卷") && (
                  <Link to={`/examPaper/copy?id=${paper.id}`} style={{ whiteSpace: "nowrap" }}>
                    复制
                  </Link>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="8" className="center">
              暂无数据
            </td>
          </tr>
        )}
      </Table>
      <Pager
        history={history}
        location={location}
        query={query}
        total={data.total}
        pageSize={DefaultPageSize}
        pageSizes={[10, 20, 30]}
      />
    </PageFrame>
  );
};

export default compose(
  parseQuery,
  fetchData({
    url: p => "/examinationpapers/fetch",
    config: p => {
      const query = {
        page: 1,
        size: DefaultPageSize,
        filter: JSON.stringify({
          createdBy: hasPermission("查看所有试卷") ? null : AppState.account.id,
        }),
        order: "-updatedAt",
        ...p.query,
      };
      return {
        method: "POST",
        data: {
          ...JSON.parse(query.filter),
          order: query.order,
          currentPage: query.page - 1 || query.currentPage,
          pageSize: query.size || DefaultPageSize,
          skip: (query.currentPage - 1) * query.pageSize,
          limit: query.pageSize,
        },
      };
    },
    extract: result => result.data,
  }),
  didMount(async p => {
    dataStore.state.questionCart = await ApiCaller.questionCartFetch("exam");
  }),
  view
)(ExamPaperList);
