import React from "react";
import styled from "styled-components";
import schema from "schm";
import _ from "lodash";
import { Row } from "../components/FlexboxGrid";
import Selector, { createOptions } from "../components/Selector";
import Dialog from "../components/Dialog";
import TextBox from "../components/TextBox";
import ValidationError from "../components/ValidationError";
import EditorDialog from "./EditorDialog";
import ApiCaller from "../utils/ApiCaller";
import AppState from "../AppState";
import { importances } from "../utils/constants";

const FieldRow = styled(Row)`
  margin-bottom: 0.5em;
  input {
    margin-left: 0.5em;
  }
  li {
    padding: 10px 10px;
  }
`;

const categorySchema = schema({
  id: String,
  subject: String,
  parentId: String,
  number: {
    type: String,
    required: [true, "请输入知识点编号"],
    maxlength: [20, "知识点编号最多20是个数字"],
  },
  name: {
    type: String,
    required: [true, "请输入知识点名称"],
    maxlength: [50, "知识点名称最多50个字符"],
  },
  importance: {
    type: String,
    required: [true, "请选择重要性"],
  },
});

export default class CategoryTreeEditor extends React.Component {
  constructor(props) {
    super(props);
    const { id, subject, number, name, importance, parentId } = props.category;
    this.state = {
      id,
      subject,
      number,
      name,
      importance,
      parentId,
      errors: {},
    };
  }

  createOrUpdateCategory = async () => {
    try {
      const fields = await categorySchema.validate(this.state);
      if (fields.id == null) {
        const category = await ApiCaller.addCategory(fields);
        if (category) {
          this.props.created && (await this.props.created(category));
        }
      } else {
        const category = await ApiCaller.updateCategory(fields);
        if (category) {
          this.props.updated && (await this.props.updated(category));
        }
      }
      return true;
    } catch (errors) {
      console.log(errors);
      this.setState({ errors: _.keyBy(errors, "param") });
      return false;
    }
  };

  render() {
    const { title } = this.props;
    const { subject, number, name, importance, errors } = this.state;
    return (
      <EditorDialog
        title={title}
        confirm={this.createOrUpdateCategory}
        close={() => Dialog.close()}
      >
        {subject && <FieldRow valign="middle">所属科目：{AppState.subjects[subject]}</FieldRow>}
        <FieldRow valign="middle">
          知识点编号:
          <TextBox value={number} onChange={text => this.setState({ number: text })} />
          {errors["number"] && <ValidationError>{errors["number"].message}</ValidationError>}
        </FieldRow>
        <FieldRow valign="middle">
          知识点名称:
          <TextBox value={name} onChange={text => this.setState({ name: text })} />
          {errors["name"] && <ValidationError>{errors["name"].message}</ValidationError>}
        </FieldRow>
        <FieldRow nowrap>
          重要性：
          <Selector
            name="grade"
            options={createOptions(importances)}
            value={importance}
            onChange={option => this.setState({ importance: option.value })}
          />
        </FieldRow>
        {errors["importance"] && <ValidationError>{errors["importance"].message}</ValidationError>}
      </EditorDialog>
    );
  }
}
