import React from "react";
import styled from "styled-components";
import { view } from "react-easy-state";
import { Row, Col } from "../components/FlexboxGrid";
import Dialog from "../components/Dialog";
import Button from "../components/Button";
import Popup from "../widgets/Popup";

const Form = styled(Col)`
  align-items: left;
  div {
    color: #666;
    align-items: center;
  }
  label {
    color: #333;
    width: 5em;
    text-align: right;
    margin: 0.5em 1em;
  }
`;

const ButtonRow = styled(Row)`
  justify-content: flex-end;
  button {
    padding: 0.375rem 1rem;
    margin: 4px 8px 0px 8px;
  }
`;

function EditorDialog({ title, confirm, close, children }) {
  return (
    <Popup title={title} close={close}>
      <Form>{children}</Form>
      <hr />
      <ButtonRow>
        <Button
          primary
          onClick={async () => {
            const confirmed = await confirm();
            if (confirmed) {
              close();
            }
          }}
        >
          确认
        </Button>
        <Button onClick={close}>取消</Button>
      </ButtonRow>
    </Popup>
  );
}

export default EditorDialog;
