import React, { Component } from "react";

const didMount = callback => Comp => {
  return class DidMount extends Component {
    componentDidMount() {
      callback(this.props);
    }

    render() {
      return <Comp {...this.props} />;
    }
  };
};

export default didMount;
