import swal from "sweetalert";

export default class Toast {
  static info(message) {
    return swal({
      icons: "info",
      text: message,
      buttons: false,
      timer: 1500,
    });
  }

  static success(message) {
    return swal({
      icons: "success",
      text: message,
      buttons: false,
      timer: 1500,
    });
  }

  static warning(message) {
    return swal({
      icons: "warning",
      text: message,
      buttons: false,
      timer: 1500,
    });
  }

  static error(message) {
    return swal({
      icons: "error",
      text: message,
      buttons: false,
      timer: 1500,
    });
  }
}
