import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Svg from "react-svg";
import { Grid, Col, Row } from "../components/FlexboxGrid";
import AppState from "../AppState";
import _ from "lodash";

const NavPannel = styled(Col)`
  width: 13rem;
  height: 100%;
  box-shadow: 2px 0 6px 0 rgba(51, 51, 51, 0.25);
  z-index: 999;
  & > div {
    background-color: #304156;
    width: 13rem;
    height: 100%;
    position: fixed;
  }
`;

const NavItem = styled(NavLink).attrs({
  activeClassName: "active",
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 1rem 1.5rem 1rem;
  color: #bfcbd9;
  svg {
    fill: #bfcbd9;
    width: 1.2em;
    height: 1.2em;
    margin-top: 0.2em;
    margin-right: 1em;
  }
  font-size: 14px;
  &:hover {
    color: #bfcbd9;
    text-decoration: none;
    background-color: #1d2736;
    svg {
      fill: #bfcbd9;
    }
  }
  &.active,
  &:focus {
    color: #409eff;
    text-decoration: none;
    background-color: #304156;
    svg {
      fill: #409eff;
    }
  }
`;

function match(category) {
  return (_, { pathname }) => pathname.startsWith("/" + category);
}

const SideNav = () => {
  return (
    <NavPannel grow={0}>
      <Col>
        <NavItem to="/dashboard" isActive={match("dashboard")}>
          <Svg src={require("../resources/icons/cloud.svg")} />
          首页
        </NavItem>
        {_.includes(AppState.menuIds, "1") && (
          <NavItem to="/category" isActive={match("category")}>
            <Svg src={require("../resources/icons/hierarchy.svg")} />
            知识结构管理
          </NavItem>
        )}
        {_.includes(AppState.menuIds, "2") && (
          <NavItem to="/question/list" isActive={match("question")}>
            <Svg src={require("../resources/icons/note.svg")} />
            题目管理
          </NavItem>
        )}
        {_.includes(AppState.menuIds, "3") && (
          <NavItem to="/groupQuestion/list" isActive={match("groupQuestion")}>
            <Svg src={require("../resources/icons/clipboard.svg")} />
            题组管理
          </NavItem>
        )}
        {_.includes(AppState.menuIds, "4") && (
          <NavItem to="/examPaper/list" isActive={match("examPaper")}>
            <Svg src={require("../resources/icons/paper.svg")} />
            试卷管理
          </NavItem>
        )}
        {AppState.account.isAdmin && (
          <NavItem to="/role/list" isActive={match("role")}>
            <Svg src={require("../resources/icons/lock.svg")} />
            角色管理
          </NavItem>
        )}
        {AppState.account.isAdmin && (
          <NavItem to="/account/list" isActive={match("account")}>
            <Svg src={require("../resources/icons/peoples.svg")} />
            用户管理
          </NavItem>
        )}
      </Col>
    </NavPannel>
  );
};

export default SideNav;
