import React from "react";
import styled from "styled-components";
import Svg from "react-svg";
import { Row } from "../components/FlexboxGrid";
import ToggleButton from "../components/ToggleButton";
import DropdownMenu from "../components/DropdownMenu";
import AppState from "../AppState";
import LinkButton from "../components/LinkButton";
import AccountManager from "../utils/AccountManager";

const Bar = styled(Row)`
  border-bottom: 1px solid #e9e9e9;
`;

const Icon = styled(Svg)`
  div {
    display: flex;
    margin-left: 1rem;
  }
  svg {
    fill: #333;
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const TopBar = ({ setSideNavVisible }) => {
  return (
    <Bar valign="middle" space="between">
      <ToggleButton
        value="shrink"
        onClick={value => setSideNavVisible(value === "shrink")}
      >
        {{
          shrink: <Icon src={require("../resources/icons/shrink.svg")} />,
          expand: <Icon src={require("../resources/icons/menu-outline.svg")} />,
        }}
      </ToggleButton>
      <DropdownMenu
        value={AppState.account.username}
        menus={["退出"]}
        menuClick={i => {
          if (i == 0) {
            AccountManager.logout();
            window.location.href = "/login";
          }
        }}
      />
    </Bar>
  );
};

export default TopBar;
