export default class Session {
  static storageKey = 'account';

  static create(account) {
    window.localStorage.setItem(Session.storageKey, JSON.stringify(account));
  }

  static destroy() {
    window.localStorage.removeItem(Session.storageKey);
  }

  static current() {
    if (window.localStorage === undefined) {
      return null;
    }
    const account = window.localStorage.getItem(Session.storageKey);
    if (account != null) {
      return JSON.parse(account);
    } else {
      return null;
    }
  }

  static valid() {
    if (window.localStorage === undefined) {
      return false;
    }
    const str = window.localStorage.getItem(Session.storageKey);
    const account = JSON.parse(str);
    return account && account.phone;
  }
}
