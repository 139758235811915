import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";

const CheckBoxItem = styled.label`
  margin-right: 2em;
  input {
    margin-right: 0.5em;
    transform: translateY(-0.1em);
  }
`;

export default class CheckBoxList extends Component {
  static propTypes = {
    name: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    theme: "default",
  };

  state = { values: this.props.value || [] };

  checkChange = value => {
    return event => {
      let values = this.state.values;
      if (event.target.checked) {
        values.push(value);
      } else {
        _.remove(values, item => item == value);
      }
      this.setState({ values });
      this.props.onChange && this.props.onChange(values);
    };
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.value != this.value) {
      this.setState({ values: nextProps.value || [] });
    }
  }

  render() {
    const {
      name,
      options,
      value,
      theme,
      disabled,
      onChange,
      ...other
    } = this.props;

    const createCheckBox = (option, i) => {
      const { label, value } =
        typeof option == "string" ? { label: option, value: option } : option;
      const checked = this.state.values.indexOf(value) > -1;
      return (
        <CheckBoxItem key={i} className={{ disabled, checked }}>
          <input
            type="checkbox"
            name={name + "[]"}
            disabled={disabled}
            checked={checked}
            onChange={this.checkChange(value)}
          />
          {label}
        </CheckBoxItem>
      );
    };

    return (
      <div className={`checkbox-list theme-${theme}`} {...other}>
        {options.map(createCheckBox)}
      </div>
    );
  }
}
