import React from "react";
import styled from "styled-components";
import _ from "lodash";
import ApiCaller from "../utils/ApiCaller";
import DateTime from "../components/DateTime";

const List = styled.ul`
  text-align: left;
  list-style-type: none;
  padding-left: 0;
  line-height: 2;
  span {
    font-family: monospace;
    vertical-align: middle;
  }
`;

export default class QuestionHistory extends React.Component {
  state = { papers: [] };

  async loadData() {
    const questionId = this.props.question.id;
    const papers = await ApiCaller.findUsingPapers(questionId);
    this.setState({ papers: _.orderBy(papers, "updatedAt", "desc") });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return this.state.papers.length == 0 ? (
      <p>该题目没有被组过卷</p>
    ) : (
      <List>
        {this.state.papers.map(paper => (
          <li key={paper.id}>
            <DateTime value={paper.updatedAt} />
            &nbsp;&nbsp;&nbsp;&nbsp;{paper.title}
          </li>
        ))}
      </List>
    );
  }
}
