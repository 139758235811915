import React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import Svg from "react-svg";
import Tree from "react-ui-tree";
import { Row, Col } from "../components/FlexboxGrid";
import LinkButton from "../components/LinkButton";
import Alert from "../components/Alert";
import Tag from "../components/Tag";
import Dialog from "../components/Dialog";
import PageFrame from "../widgets/PageFrame";
import CategoryTreeEditor from "../widgets/CategoryTreeEditor";
import SubjectEditor from "../widgets/SubjectEditor";
import ApiCaller from "../utils/ApiCaller";
import { importances, knowledgePointFuns } from "../utils/constants";
import AppState from "../AppState";
import hasPermission from "../utils/hasPermission";

const SelectorRow = styled(Row)`
  margin-bottom: 1.5em;
`;

const Subject = styled(Col)`
  position: relative;
  margin-left: 1em;
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  label {
    font-size: 26px;
    font-weight: bold;
    cursor: pointer;
    ${p =>
      p.selected &&
      css`
        color: #ff9717;
      `};
  }
  svg {
    fill: #333;
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const TreeRow = styled(Row)``;

const TreeNode = styled(Row)`
  margin-left: 0.5em;
  svg {
    fill: #333;
    width: 1rem;
    height: 1rem;
    margin: 0.3rem 0 0 0.5rem;
    cursor: pointer;
  }
  label {
    margin-left: 4px;
    margin-right: 0;
    height: 26px;
    line-height: 26px;
  }
`;

const DeleteButton = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: red;
    width: 20px;
    height: 20px;
    circle {
      stroke: red;
    }
    line {
      stroke: #fff;
    }
  }
`;

export default class CategoryTree extends React.Component {
  state = {
    tree: { children: [] },
    subject: "1",
  };

  createSubject = () => {
    Dialog.show(
      <SubjectEditor
        title="新增科目"
        subject={{}}
        created={subject => {
          AppState.subjects[subject.number] = subject.name;
          AppState.categoryTrees[subject.number] = [];
          this.forceUpdate();
        }}
      />,
      {
        buttons: false,
        closeOnClickOutside: false,
      }
    );
  };

  deleteSubject = async id => {
    const result = await Alert.warning("是否删除此科目？", {
      buttons: ["取消", "确认"],
    });
    if (result === true) {
      const deleteResult = await ApiCaller.deleteSubject(id);
      if (deleteResult == true) {
        AppState.subjects = _.omit(AppState.subjects, [id]);
        this.forceUpdate();
      } else {
        Alert.warning(deleteResult);
      }
    }
  };

  createCategoryTree = subject => {
    if (hasPermission("新增知识树")) {
      Dialog.show(
        <CategoryTreeEditor
          title="新增知识树"
          category={{ subject }}
          created={category => {
            this.setState({ subject }, () => {
              this.updateCategoryTree(null, null, {
                type: "add",
                category,
              });
            });
          }}
        />,
        {
          buttons: false,
          closeOnClickOutside: false,
        }
      );
    } else {
      Alert.warning("没有新增知识树的权限!");
    }
  };

  addSubNode = async node => {
    if (hasPermission("新增知识点")) {
      Dialog.show(
        <CategoryTreeEditor
          title="新增知识点"
          category={{ subject: node.subject, parentId: node.id }}
          created={category => {
            this.updateCategoryTree(node.path, node.id, {
              type: "add",
              category,
            });
          }}
        />,
        {
          buttons: false,
          closeOnClickOutside: false,
        }
      );
    } else {
      Alert.warning("没有新增知识点权限!");
    }
  };

  editNode = async node => {
    if (hasPermission("编辑知识点")) {
      Dialog.show(
        <CategoryTreeEditor
          title="编辑知识点"
          category={node}
          updated={category => {
            this.updateCategoryTree(node.path, node.id, {
              type: "update",
              category,
            });
          }}
        />,
        {
          buttons: false,
          closeOnClickOutside: false,
        }
      );
    } else {
      Alert.warning(`没有修改知识点的权限!`);
    }
  };

  remove = async node => {
    if (hasPermission("删除知识点")) {
      const result = await Alert.warning("是否删除此节点？", {
        buttons: ["取消", "确认"],
      });
      if (result === true) {
        const deleteResult = await ApiCaller.deleteCategory(node.id);
        if (deleteResult == true) {
          this.updateCategoryTree(node.path, node.id, { type: "remove" });
        } else {
          Alert.warning(deleteResult);
        }
      }
    } else {
      Alert.warning(`没有删除知识点的权限!`);
    }
  };

  updateCategoryTree(path, id, action) {
    const { subject } = this.state;
    let parent = { children: AppState.categoryTrees[subject] };
    const parentIds = path ? _.compact(path.split(",")) : [];
    for (const parentId of parentIds) {
      parent = _.find(parent.children, node => node.id == parentId);
    }
    switch (action.type) {
      case "add":
        const parentNode = id ? _.find(parent.children, node => node.id == id) : parent;
        if (parentNode) {
          if (!parentNode.children) {
            parentNode.children = [];
          }
          action.category.label = action.category.number + " " + action.category.name;
          parentNode.children.push(action.category);
          parentNode.collapsed = false;
        }
        AppState.version++;
        break;
      case "update":
        const node = _.find(parent.children, node => node.id == id);
        if (node) {
          node.label = action.category.number + " " + action.category.name;
          node.number = action.category.number;
          node.name = action.category.name;
          node.importance = action.category.importance;
        }
        break;
      case "remove":
        const index = _.findIndex(parent.children, node => node.id == id);
        if (index != -1) {
          parent.children.splice(index, 1);
          AppState.version++;
        }
        break;
    }
    this.forceUpdate();
  }

  renderTreeNode = node => {
    return (
      <TreeNode
        key={node.id}
        onMouseDown={function(e) {
          e.stopPropagation();
        }}
        valign="middle"
      >
        {node.label}
        {node.importance && <Tag>{importances[node.importance]}</Tag>}
        <Svg onClick={() => this.addSubNode(node)} src={require("../resources/icons/plus.svg")} />
        {node.id && (
          <Svg onClick={() => this.editNode(node)} src={require("../resources/icons/edit.svg")} />
        )}
        {_.isEmpty(node.children) && (
          <Svg onClick={() => this.remove(node)} src={require("../resources/icons/trash.svg")} />
        )}
      </TreeNode>
    );
  };

  render() {
    const { loading } = this.props;
    const { subject } = this.state;
    return (
      <PageFrame title="知识结构管理" loading={loading}>
        <SelectorRow valign="middle">
          {Object.entries(AppState.subjects).map(([id, name]) => (
            <Subject key={id} align="center" selected={subject == id}>
              <label onClick={() => this.setState({ subject: id })}>{name}</label>
              <LinkButton primary onClick={() => this.createCategoryTree(id)}>
                新增知识树
              </LinkButton>
              {AppState.account.isAdmin &&
                (AppState.categoryTrees[id] && AppState.categoryTrees[id].length == 0) && (
                  <DeleteButton onClick={() => this.deleteSubject(id)}>
                    <Svg src={require("../resources/icons/minus-circle.svg")} />
                  </DeleteButton>
                )}
            </Subject>
          ))}
          {AppState.account.isAdmin && (
            <Subject>
              <LinkButton primary onClick={() => this.createSubject()}>
                <Svg src={require("../resources/icons/plus.svg")} />
                新增科目
              </LinkButton>
            </Subject>
          )}
        </SelectorRow>
        <TreeRow>
          {AppState.categoryTrees[subject] &&
            AppState.categoryTrees[subject].map(subtree => (
              <Tree
                key={subtree.id + AppState.version}
                paddingLeft={20}
                draggable={false}
                tree={subtree}
                renderNode={this.renderTreeNode}
              />
            ))}
        </TreeRow>
      </PageFrame>
    );
  }
}
