import React from "react";
import styled from "styled-components";
import { store, view } from "react-easy-state";
import { Col, Row } from "../components/FlexboxGrid";
import LatexEditor from "../components/LatexEditor";

const OptionList = styled(Col)`
  width: calc(100% - 3em);
`;

const OptionItem = styled(Row)`
  margin-bottom: 0.5em;
  input {
    margin-right: 0.5em;
  }
  label {
    margin-right: 0.5em;
  }
`;

const QuestionOptions = ({
  name,
  options,
  errors,
  onChange,
  onHtmlChange,
  onImageChange,
}) => {
  return (
    <OptionList>
      {options &&
        options.map(
          (option, index) =>
            option.value && (
              <OptionItem key={option.label} grow={1}>
                <label>{option.label}.</label>
                <LatexEditor
                  height="60px"
                  placeholder="请输入答案选项"
                  latex={option.value.content}
                  images={option.value.imgs}
                  onChange={content => onChange(index, content)}
                  onHtmlChange={html => onHtmlChange(index, html)}
                  onImageChange={images => onImageChange(index, images)}
                  isValid={
                    errors[`${name}.${index}.value.content`] === undefined
                  }
                />
              </OptionItem>
            )
        )}
    </OptionList>
  );
};

export default view(QuestionOptions);
