import React from "react";
import styled from "styled-components";
import Svg from "react-svg";

const Icon = styled(Svg)`
  div {
    display: flex;
  }
`;

export default Icon;
