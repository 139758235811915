import React from "react";
import { store, view } from "react-easy-state";
import _ from "lodash";
import queryString from "query-string";
import { Col, Row } from "../components/FlexboxGrid";
import fetchData from "../enhancers/fetchData";
import compose from "../utils/compose";
import parseQuery from "../enhancers/parseQuery";
import didMount from "../enhancers/didMount";
import PageFrame from "../widgets/PageFrame";
import Button from "../components/Button";
import Table from "../components/Table";
import Dialog from "../components/Dialog";
import DataStore from "../utils/DataStore";
import AccountEditor from "../widgets/AccountEditor";
import Pager from "../components/Pager";
import LinkButton from "../components/LinkButton";
import Alert from "../components/Alert";
import ApiCaller from "../utils/ApiCaller";
import AppState from "../AppState";

const dataStore = new DataStore();

const DefaultPageSize = 10;

function addAccount(reload) {
  Dialog.show(
    <AccountEditor
      title="新增用户"
      account={{}}
      roles={dataStore.state.roles}
      created={() => {
        Dialog.close();
        reload();
      }}
    />,
    {
      buttons: false,
    }
  );
}

function editorAccount(account, reload) {
  Dialog.show(
    <AccountEditor
      title="编辑用户"
      account={account}
      roles={dataStore.state.roles}
      created={() => {
        Dialog.close();
        reload();
      }}
    />,
    {
      buttons: false,
    }
  );
}

async function toggleEnabled(paper, reload) {
  const ok = await Alert.warning(
    `是否确认${paper.enabled ? "禁用" : "启用"}该用户？`,
    {
      buttons: ["否", "是"],
    }
  );
  if (ok === true) {
    const result = await ApiCaller.toggleEnabled(paper.id);
    if (result.status == 200) {
      reload();
      Alert.success(`${paper.enabled ? "禁用" : "启用"}成功`);
    } else {
      Alert.warning(result.data);
    }
  }
}

const AccountList = props => {
  const { history, location, query, loading, data } = props;
  const filter = JSON.parse(query.filter || "{}");
  if (data == null) return null;
  const reload = () => {
    history.push(location.pathname + "?" + queryString.stringify(query));
  };
  return (
    <PageFrame title="用户管理">
      <Row valign="middle">
        <Button primary onClick={() => addAccount(reload)}>
          新增用户
        </Button>
      </Row>
      <Table
        headerRow={
          <tr>
            <th>用户名称</th>
            <th>手机号</th>
            <th>角色</th>
            <th>状态</th>
            <th>操作</th>
          </tr>
        }
      >
        {data.total > 0 ? (
          data.accounts.map((account, index) => (
            <tr key={index}>
              <td className="center">{account.username}</td>
              <td className="center">{account.phoneNumber}</td>
              <td className="center">
                {dataStore.state.roles &&
                  account.roleIds
                    .map(roleId => dataStore.state.roles[roleId].name)
                    .join("、")}
              </td>
              <td className="center">{account.enabled ? "启用" : "禁用"}</td>
              <td className="center">
                <LinkButton onClick={() => editorAccount(account, reload)}>
                  编辑
                </LinkButton>
                <LinkButton onClick={() => toggleEnabled(account, reload)}>
                  {account.enabled ? "禁用" : "启用"}
                </LinkButton>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="8" className="center">
              暂无数据
            </td>
          </tr>
        )}
      </Table>
      <Pager
        history={history}
        location={location}
        query={query}
        total={data.total}
        pageSize={DefaultPageSize}
        pageSizes={[10, 20, 30]}
      />
    </PageFrame>
  );
};

export default compose(
  parseQuery,
  fetchData({
    url: p => "/users/fetch",
    config: p => {
      const query = {
        page: 1,
        size: DefaultPageSize,
        filter: "{}",
        ...p.query,
      };
      return {
        method: "POST",
        data: {
          ...JSON.parse(query.filter),
          currentPage: query.page - 1 || query.currentPage,
          pageSize: query.size || DefaultPageSize,
          skip: (query.currentPage - 1) * query.pageSize,
          limit: query.pageSize,
        },
      };
    },
    extract: result => result.data,
  }),
  didMount(async p => {
    dataStore.state.roles = await ApiCaller.getRoles();
  }),
  view
)(AccountList);
