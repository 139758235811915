import React from "react";
import styled, { css } from "styled-components";
import compose from "../utils/compose";
import parseQuery from "../enhancers/parseQuery";
import fetchData from "../enhancers/fetchData";
import didMount from "../enhancers/didMount";
import ApiCaller from "../utils/ApiCaller";
import { StyledButton } from "../components/Button";
import { Row, Col } from "../components/FlexboxGrid";
import Space from "../components/Space";
import DataStore from "../utils/DataStore";
import { view } from "react-easy-state";
import _ from "lodash";
import { isWechat } from "../utils/Misc";

const dataStore = new DataStore({});

const Page = styled.div`
  padding: 20px;
`;

const Header = styled(Row)`
  display: flex;
  justify-content: center;
  button {
    position: absolute;
    right: 15px;
    @media print {
      display: none !important;
    }
  }
`;

const Question = styled.div`
  label {
    white-space: nowrap;
  }
  .answer {
    text-align: left;
    img {
      vertical-align: text-top;
    }
  }
`;

const QuestionNumber = styled.label`
  min-width: 2em;
`;

const Answer = styled(Row)`
  page-break-inside: avoid;
`;

const Analysis = styled(Row)`
  page-break-inside: avoid;
  margin-left: 2em;
`;

const GroupQuestionAnswer = ({ question, number }) => (
  <div>
    <QuestionNumber>{number}．</QuestionNumber>
    <div>
      {question.groupQuestions &&
        question.groupQuestions.map((groupQuestion, index) => (
          <Question nowrap key={index}>
            {groupQuestion.subQuestions ? (
              <Row nowrap>
                <Col>
                  ({index + 1}
                  )&nbsp;
                </Col>
                <Col>
                  {groupQuestion.subQuestions.map((subQuestion, index) => (
                    <Row nowrap key={index}>
                      <label>
                        {String.fromCharCode(97 + index)}
                        )&nbsp;
                      </label>
                      <div>
                        <Row nowrap>
                          <label>答案：</label>
                          <p
                            className="answer"
                            dangerouslySetInnerHTML={{
                              __html:
                                subQuestion.answer && subQuestion.answer.html,
                            }}
                          />
                        </Row>
                        {subQuestion.analysis && subQuestion.analysis.html && (
                          <Row nowrap>
                            <label>解析：</label>
                            <p
                              className="analysis"
                              dangerouslySetInnerHTML={{
                                __html: subQuestion.analysis.html,
                              }}
                            />
                          </Row>
                        )}
                      </div>
                    </Row>
                  ))}
                </Col>
              </Row>
            ) : (
              <>
                <Answer nowrap>
                  <QuestionNumber> ({index + 1})</QuestionNumber>
                  <label>答案：</label>
                  {groupQuestion.answer && groupQuestion.answer.html ? (
                    <p
                      className="answer"
                      dangerouslySetInnerHTML={{
                        __html:
                          groupQuestion.answer && groupQuestion.answer.html,
                      }}
                    />
                  ) : (
                    groupQuestion.correctOptions &&
                    groupQuestion.correctOptions.sort()
                  )}
                </Answer>
                {groupQuestion.analysis && groupQuestion.analysis.html && (
                  <Analysis nowrap>
                    <label>解析：</label>
                    <p
                      className="analysis"
                      dangerouslySetInnerHTML={{
                        __html: groupQuestion.analysis.html,
                      }}
                    />
                  </Analysis>
                )}
              </>
            )}
          </Question>
        ))}
    </div>
  </div>
);

const QuestionAnswer = ({ question, number }) => (
  <Question>
    {question.subQuestions && (
      <Row nowrap>
        <label>{number}．</label>
        {question.subQuestions.map((subQuestion, index) => (
          <Row nowrap key={index}>
            <label>
              ({index + 1}
              )&nbsp;
            </label>
            <div>
              <Row nowrap>
                <label>答案：</label>
                <p
                  className="answer"
                  dangerouslySetInnerHTML={{
                    __html: subQuestion.answer && subQuestion.answer.html,
                  }}
                />
              </Row>
              {subQuestion.analysis && subQuestion.analysis.html && (
                <Row nowrap>
                  <label>解析：</label>
                  <p
                    className="analysis"
                    dangerouslySetInnerHTML={{
                      __html: subQuestion.analysis.html,
                    }}
                  />
                </Row>
              )}
            </div>
          </Row>
        ))}
      </Row>
    )}
    {!question.subQuestions && (
      <>
        <Answer nowrap>
          <QuestionNumber>{number}．</QuestionNumber>
          <label>答案：</label>
          {question.answer && question.answer.html ? (
            <p
              className="answer"
              dangerouslySetInnerHTML={{
                __html: question.answer && question.answer.html,
              }}
            />
          ) : (
            question.correctOptions && question.correctOptions.sort()
          )}
        </Answer>
        {question.analysis && question.analysis.html && (
          <Analysis nowrap>
            <label>解析：</label>
            <p
              className="analysis"
              dangerouslySetInnerHTML={{
                __html: question.analysis.html,
              }}
            />
          </Analysis>
        )}
      </>
    )}
  </Question>
);

const ExamPaperPreviewAnswer = ({ query }) => {
  if (dataStore.state.data == null) return null;
  const { paper, questions } = dataStore.state.data;
  return (
    <Page>
      <Header>
        <h1>{query.title || paper.title}</h1>
        {!isWechat() && (
          <StyledButton primary onClick={window.print}>
            打印
          </StyledButton>
        )}
      </Header>
      {questions.map((question, index) =>
        question.type == "group" ? (
          <GroupQuestionAnswer
            key={index}
            number={index + 1}
            question={question}
          />
        ) : (
          <QuestionAnswer key={index} number={index + 1} question={question} />
        )
      )}
    </Page>
  );
};

export default compose(
  parseQuery,
  fetchData({
    url: p => "/examinationpapers/list",
    config: p => {
      return {
        method: "POST",
        data: {
          ids: [p.query.id],
        },
      };
    },
    extract: async result => {
      const paper = result.data[0];
      const questions = await ApiCaller.fetchExamPaperQuestions(paper);
      const fetchedQuestions = await ApiCaller.fetchGroupQuestions(questions);
      dataStore.state.data = { paper, questions: fetchedQuestions };
    },
  }),
  didMount(() => {
    document.title = "试卷答案";
  }),
  view
)(ExamPaperPreviewAnswer);
