import React from "react";
import styled from "styled-components";
import schema from "schm";
import _ from "lodash";
import { Row, Col } from "../components/FlexboxGrid";
import Dialog from "../components/Dialog";
import TextBox from "../components/TextBox";
import ValidationError from "../components/ValidationError";
import EditorDialog from "./EditorDialog";
import ApiCaller from "../utils/ApiCaller";
import AppState from "../AppState";
import Alert from "../components/Alert";
import LinkButton from "../components/LinkButton";

const FieldRow = styled(Row)`
  margin-bottom: 0.5em;
  input {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-right: 30px;
    border-radius: 4px;
    outline: 0;
    padding: 0 15px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    margin: 5px 0;
  }
`;

const Required = styled.span`
  margin-right: 5px;
  color: red;
`;

const PermissionRow = styled(Row)`
  padding: 5px;
  justify-content: center;
  input {
    margin-right: 10px;
  }
  span {
    margin-right: 10px;
  }
`;

const accountSchema = schema({
  id: String,
  username: {
    type: String,
    required: [true, "请输入用户名"],
    maxlength: [10, "用户名至多10位"],
  },
  phoneNumber: {
    type: String,
    required: [true, "请输入手机号"],
    match: [/^1[3-9]\d{9}$/, "请输入有效的手机号"],
  },
  password: {
    type: String,
    required: [true, "请输入登录密码"],
    minlength: [6, "密码至少6位"],
  },
  roleIds: {
    type: Array,
    default: [],
    minlength: [1, "请选择用户角色"],
  },
  enabled: Boolean,
});

const resetPasswordSchema = schema({
  resetPassword: {
    type: String,
    required: [true, "请输入重置密码"],
    minlength: [6, "密码至少6位"],
  },
});

export default class AccountEditor extends React.Component {
  constructor(props) {
    super(props);
    const {
      id,
      username,
      phoneNumber,
      password,
      roleIds,
      enabled,
    } = props.account;
    this.state = {
      id,
      username,
      phoneNumber,
      password,
      roleIds,
      enabled: enabled || true,
      errors: {},
      isChangePassword: false,
      resetPassword: "",
    };
  }

  setRoleIds = () => {
    let roleIds = this.state.roleIds || [];
    if (event.target.checked) {
      roleIds.push(event.target.value);
    } else {
      _.remove(roleIds, item => item == event.target.value);
    }
    this.setState({ roleIds: roleIds });
  };

  createOrUpdateAccount = async () => {
    try {
      const account = await accountSchema.validate(this.state);
      const result = await ApiCaller.createOrUpdateAccount(account);
      if (result.status == 200) {
        Alert.info("编辑成功");
        this.props.created && (await this.props.created());
      } else {
        this.setState({ errors: { existenceError: { message: result.data } } });
      }
    } catch (errors) {
      this.setState({ errors: _.keyBy(errors, "param") });
    }
  };

  changePasswordState = () => {
    this.setState({ isChangePassword: true });
  };

  changePassword = async () => {
    try {
      const account = await resetPasswordSchema.validate({
        resetPassword: this.state.resetPassword,
      });
      const result = await ApiCaller.changePassword({
        phoneNumber: this.props.account.phoneNumber,
        password: account.resetPassword,
      });
      if (result.status == 200) {
        Alert.info("重置密码成功");
        this.props.created && (await this.props.created());
      } else {
        this.setState({
          errors: { changePasswordError: { message: result.data } },
        });
      }
    } catch (errors) {
      this.setState({ errors: _.keyBy(errors, "param") });
    }
  };

  render() {
    const { title, roles } = this.props;
    const {
      username,
      phoneNumber,
      password,
      roleIds,
      errors,
      isChangePassword,
      resetPassword,
    } = this.state;
    return (
      <div>
        {isChangePassword ? (
          <EditorDialog
            title="重置密码"
            confirm={() => this.changePassword()}
            close={() => Dialog.close()}
          >
            <FieldRow valign="middle">
              <Required>*</Required>密码：
              {errors["resetPassword"] && (
                <ValidationError>
                  {errors["resetPassword"].message}
                </ValidationError>
              )}
              <TextBox
                value={resetPassword}
                onChange={text => this.setState({ resetPassword: text })}
                placeholder="请输入重置密码"
              />
            </FieldRow>
          </EditorDialog>
        ) : (
          <EditorDialog
            title={title}
            confirm={this.createOrUpdateAccount}
            close={() => Dialog.close()}
          >
            <FieldRow valign="middle">
              <Required>*</Required>用户名：
              <TextBox
                value={username}
                onChange={text => this.setState({ username: text })}
                placeholder="请输入用户名"
              />
              {errors["username"] && (
                <ValidationError>{errors["username"].message}</ValidationError>
              )}
            </FieldRow>
            <FieldRow valign="middle">
              <Required>*</Required>手机号：
              <TextBox
                value={phoneNumber}
                onChange={text => this.setState({ phoneNumber: text })}
                placeholder="请输入手机号"
              />
              {errors["phoneNumber"] && (
                <ValidationError>
                  {errors["phoneNumber"].message}
                </ValidationError>
              )}
              {errors["existenceError"] && (
                <ValidationError>
                  {errors["existenceError"].message}
                </ValidationError>
              )}
            </FieldRow>
            {title == "新增用户" && (
              <FieldRow valign="middle">
                <Required>*</Required>密码：
                <TextBox
                  value={password}
                  onChange={text => this.setState({ password: text })}
                  placeholder="请输入登录密码"
                />
                {errors["password"] && (
                  <ValidationError>
                    {errors["password"].message}
                  </ValidationError>
                )}
              </FieldRow>
            )}
            <Row>
              <Required>*</Required>角色
              {errors["roleIds"] && (
                <ValidationError>{errors["roleIds"].message}</ValidationError>
              )}
            </Row>
            <Row>
              {_.values(roles).map((role, i) => (
                <PermissionRow key={i}>
                  <input
                    type="checkbox"
                    onChange={this.setRoleIds}
                    value={role.id}
                    checked={_.includes(roleIds, role.id)}
                  />
                  <span>{role.name}</span>
                </PermissionRow>
              ))}
            </Row>
            {title == "编辑用户" && (
              <Row align="right">
                <LinkButton onClick={this.changePasswordState}>
                  重置密码
                </LinkButton>
              </Row>
            )}
          </EditorDialog>
        )}
      </div>
    );
  }
}
