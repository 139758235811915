import React, { Component } from "react";
import styled from "styled-components";
import schema from "schm";
import _ from "lodash";
import { Grid, Col, Row } from "../components/FlexboxGrid";
import TextBox from "../components/TextBox";
import Button from "../components/Button";
import ValidationError from "../components/ValidationError";
import ApiCaller from "../utils/ApiCaller";

const Page = styled(Col)`
  background-color: #2d3a4b;
  height: 100%;
  padding: 155px 35px 15px 35px;
`;

const Logo = styled.h1`
  color: #e6e6e6;
  font-size: 10.63rem;
  font-weight: 800;
  letter-spacing: -0.6rem;
  margin-top: 4rem;
  line-height: 1.1;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
`;

const Title = styled.h3`
  color: #eee;
  font-size: 26px;
  font-weight: bold;
`;

const Form = styled(Col)`
  margin-top: 0.5rem;
  width: 19rem;
  input,
  button {
    margin-top: 1.2rem;
  }
  .error {
    margin-top: 0.5rem;
  }
`;

const loginSchema = schema({
  phoneNumber: {
    type: String,
    required: [true, "请输入手机号"],
    match: [/^1[3-9]\d{9}$/, "请输入有效的手机号"],
  },
  password: {
    type: String,
    required: [true, "请输入密码"],
    minlength: [6, "密码至少6位"],
  },
});

class Login extends Component {
  state = {
    phoneNumber: "",
    password: "",
    errors: {},
  };

  login = async () => {
    try {
      this.setState({ errors: {} });
      const { phoneNumber, password } = await loginSchema.validate(this.state);
      const success = await ApiCaller.login(phoneNumber, password);
      if (success) {
        window.location.href = "/main";
      }
    } catch (errors) {
      this.setState({ errors: _.keyBy(errors, "param") });
    }
  };

  render() {
    const { phoneNumber, password, errors } = this.state;
    return (
      <Page align="center">
        <Title>系统登录</Title>
        <Form>
          <TextBox
            type="tel"
            name="phoneNumber"
            value={phoneNumber}
            placeholder="手机号"
            onChange={phoneNumber => this.setState({ phoneNumber })}
          />
          {errors["phoneNumber"] && (
            <ValidationError>{errors["phoneNumber"].message}</ValidationError>
          )}
          <TextBox
            type="password"
            name="password"
            value={password}
            placeholder="密码"
            onChange={password => this.setState({ password })}
          />
          {errors["password"] && (
            <ValidationError>{errors["password"].message}</ValidationError>
          )}
          <Button primary onClick={this.login}>
            登录
          </Button>
          {errors["other"] && (
            <ValidationError>{errors["other"].message}</ValidationError>
          )}
        </Form>
      </Page>
    );
  }
}

export default Login;
