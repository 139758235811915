import React from "react";
import styled from "styled-components";
import schema from "schm";
import _ from "lodash";
import { Row, Col } from "../components/FlexboxGrid";
import Dialog from "../components/Dialog";
import TextBox from "../components/TextBox";
import ValidationError from "../components/ValidationError";
import EditorDialog from "./EditorDialog";
import ApiCaller from "../utils/ApiCaller";
import {
  menus,
  knowledgePointFuns,
  questionFuns,
  questionGroupFuns,
  examinationPaperFuns,
} from "../utils/constants";
import { createOptions } from "../components/Selector";
import Alert from "../components/Alert";

const FieldRow = styled(Row)`
  margin-bottom: 0.5em;
  input {
    width: 30%;
    height: 36px;
    line-height: 36px;
    padding-right: 30px;
    border-radius: 4px;
    outline: 0;
    padding: 0 15px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
  }
`;

const TableDiv = styled.table`
  width: 100%;
  margin: 10px 0 60px 0;
  border: 1px solid #eee;
  td,
  th {
    border: 1px solid #eee;
    padding: 0.5rem;
    font-size: 14px;
  }
  input {
    margin-right: 10px;
  }
`;

const PermissionItem = styled.label`
  width: auto !important;
  margin: 0 !important;
  text-align: center !important;
  padding: 5px;
  cursor: pointer;
`;

const Required = styled.span`
  margin-right: 5px;
  color: red;
`;

const roleSchema = schema({
  id: String,
  name: {
    type: String,
    required: [true, "请输入角色名称"],
    maxlength: [10, "角色名称最多10个字符"],
  },
  menuIds: Array,
  functionIds: Array,
});

export default class RoleEditor extends React.Component {
  constructor(props) {
    super(props);
    const { id, name, menuIds, functionIds } = props.role;
    this.state = {
      id,
      name,
      menuIds,
      functionIds,
      errors: {},
    };
  }

  createOrUpdateRole = async () => {
    try {
      this.setState({ errors: {} });
      const role = await roleSchema.validate(this.state);
      const result = await ApiCaller.createOrUpdateRole(role);
      if (result.status == 200) {
        Alert.info("编辑成功");
        this.props.created && (await this.props.created());
      } else {
        this.setState({ errors: { existenceError: { message: result.data } } });
      }
    } catch (errors) {
      this.setState({ errors: _.keyBy(errors, "param") });
    }
  };

  setMenuIds = () => {
    let menuIds = this.state.menuIds;
    if (event.target.checked) {
      menuIds.push(event.target.value);
      this.setAllFunctionIds(event.target.value, "push");
    } else {
      _.remove(menuIds, item => item == event.target.value);
      this.setAllFunctionIds(event.target.value, "remove");
    }
    this.setState({ menuIds: menuIds });
  };

  setAllFunctionIds = (menuId, type) => {
    let functionIds = this.state.functionIds;
    createOptions(
      menuId == 1
        ? knowledgePointFuns
        : menuId == 2
        ? questionFuns
        : menuId == 3
        ? questionGroupFuns
        : menuId == 4 && examinationPaperFuns
    ).forEach(fun => {
      type == "push"
        ? functionIds.push(fun.value)
        : type == "remove" && _.remove(functionIds, item => item == fun.value);
    });
    this.setState({ functionIds: functionIds });
  };

  setFunctionIds = () => {
    let functionIds = this.state.functionIds;
    if (event.target.checked) {
      functionIds.push(event.target.value);
    } else {
      _.remove(functionIds, item => item == event.target.value);
    }
    this.setState({ functionIds: functionIds });
  };

  render() {
    const { title } = this.props;
    const { name, menuIds, functionIds, errors } = this.state;
    return (
      <EditorDialog
        title={title}
        confirm={this.createOrUpdateRole}
        close={() => Dialog.close()}
      >
        <FieldRow valign="middle">
          <Required>*</Required>角色名称：
          <TextBox
            value={name}
            onChange={text => this.setState({ name: text })}
          />
          {errors && errors["name"] && (
            <ValidationError>{errors["name"].message}</ValidationError>
          )}
          {errors && errors["existenceError"] && (
            <ValidationError>
              {errors["existenceError"].message}
            </ValidationError>
          )}
        </FieldRow>
        <Row>权限设置：</Row>
        <Row>
          <TableDiv>
            <tbody>
              {createOptions(menus).map((menu, i) => (
                <tr key={i}>
                  <td>
                    <PermissionItem>
                      <input
                        type="checkbox"
                        onChange={this.setMenuIds}
                        value={menu.value}
                        checked={_.includes(menuIds, menu.value)}
                      />
                      <span>{menu.label}</span>
                    </PermissionItem>
                  </td>
                  <td>
                    <Row>
                      {_.sortBy(
                        createOptions(
                          menu.value == 1
                            ? knowledgePointFuns
                            : menu.value == 2
                            ? questionFuns
                            : menu.value == 3
                            ? questionGroupFuns
                            : examinationPaperFuns
                        ),
                        item => item.value
                      ).map((fun, i) => (
                        <Col style={{ width: "33%" }} key={i}>
                          <PermissionItem>
                            <input
                              type="checkbox"
                              onChange={this.setFunctionIds}
                              value={fun.value}
                              checked={_.includes(functionIds, fun.value)}
                              disabled={!_.includes(menuIds, menu.value)}
                            />
                            <span>{fun.label}</span>
                          </PermissionItem>
                        </Col>
                      ))}
                    </Row>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableDiv>
        </Row>
      </EditorDialog>
    );
  }
}
