import _ from "lodash";

export const subjects = {
  1: "数学",
  2: "英语",
  3: "语文",
  4: "物理",
};

export const grades = {
  1: "一年级",
  2: "二年级",
  3: "三年级",
  4: "四年级",
  5: "五年级",
  6: "六年级",
  7: "初一",
  8: "初二",
  9: "初三",
  10: "高一",
  11: "高二",
  12: "高三",
};

export const questionTypes = {
  choice: "单选题",
  multichoice: "多选题",
  fill: "填空题",
  // fillMulti: "填空多问",
  essay: "解答题",
  // essayMulti: "解答多问",
};

export const questionTags = [
  "例题",
  "奥数计算",
  "校内知识",
  "课前测",
  "新授摸底",
  "新授A卷",
  "新授B卷",
  "新授C卷",
  "新授D卷",
  // "纯图题",
];

export const questionFeatures = ["常考题", "易错题", "好题", "压轴题", "创新题", "综合题"];

export const questionIdeologies = [
  "数形结合",
  "转化与化归",
  "分类讨论",
  "函数与方程",
  "数学建模",
  "整体思想",
  "换元思想",
];

export const difficulties = {
  1: "极简",
  2: "简单",
  3: "普通",
  4: "较难",
  5: "难",
  6: "极难",
};

export const importances = {
  1: "普通",
  2: "重要",
  3: "很重要",
  4: "非常重要",
  5: "极其重要",
};

export const blankLines = {
  choice: 6,
  multichoice: 6,
  fill: 6,
  fillMulti: 6,
  essay: 10,
  essayMulti: 10,
};

export const orderName = {
  updatedAt: "时间升序",
  "-updatedAt": "时间降序",
  diffCoefficient: "难度升序",
  "-diffCoefficient": "难度降序",
};

export const statuses = {
  1: "草稿",
  2: "初审通过",
  3: "ps完成",
  4: "视频已传",
  5: "终审通过",
};

export const statusThemes = {
  1: "draft",
  2: "firstTrialPassed",
  3: "psComplete",
  4: "videoUpload",
  5: "finalApproval",
};

export const menus = {
  1: "知识点结构管理",
  2: "题目管理",
  3: "题组管理",
  4: "试卷管理",
};

export const knowledgePointFuns = {
  1: "新增知识树",
  2: "编辑知识树",
  3: "删除知识树",
  4: "新增知识点",
  5: "编辑知识点",
  6: "删除知识点",
  61: "查看测试科目",
};

export const questionFuns = {
  7: "新增题目",
  8: "编辑题目",
  81: "编辑已答题目",
  9: "删除题目",
  10: "组题按钮",
  11: "组卷按钮",
  12: "上传视频",
};

export const questionGroupFuns = {
  13: "编辑题组",
  14: "删除题组",
};

export const examinationPaperFuns = {
  15: "复制试卷",
  16: "编辑试卷",
  17: "删除试卷",
  18: "查看所有试卷",
};

export const permissionItems = _.invert({
  ...knowledgePointFuns,
  ...questionFuns,
  ...questionGroupFuns,
  ...examinationPaperFuns,
});
