import React from "react";
import styled from "styled-components";

const ValidationError = styled.p.attrs({
  className: "error",
})`
  margin: 0;
  color: red;
  padding: 0 10px;
  font-size: 14px;
`;

export default ValidationError;
