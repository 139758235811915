import React, { Component } from "react";
import styled, { css } from "styled-components";
import { Row } from "../components/FlexboxGrid";

const ArrowBottom = styled.div`
  margin-top: 12px;
  margin-left: 10px;
  border-width: 6px;
  border-bottom-width: 0;
  border-style: dashed;
  border-top-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
`;

const ArrowTop = styled.div`
  margin-bottom: 12px;
  margin-left: 10px;
  border-width: 7px;
  border-top-width: 0;
  border-style: dashed;
  border-bottom-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
`;

const DropdownMenuDiv = styled.div`
  height: 30px;
  min-width: 100px;
  line-height: 30px;
  font-size: 0.82rem;
`;

const MenusDiv = styled.div`
  position: absolute;
  min-width: 100px;
  padding: 5px 0;
  margin: 2px 0 0;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  .MenuItem {
    width: 100%;
    text-align: center;
  }
`;

export default class DropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
    };
  }

  select = () => {
    this.setState({ selected: !this.state.selected });
  };

  fold = () => {
    setTimeout(() => {
      this.setState({ selected: false });
    }, 200);
  };

  menuClick = (e, i) => {
    e.stopPropagation();
    this.props.menuClick(i);
  };

  render() {
    return (
      <DropdownMenuDiv style={{ margin: "10px", cursor: "pointer" }}>
        <Row onClick={this.select} tabIndex={-1} onBlur={this.fold}>
          {this.props.value}
          {this.state.selected ? <ArrowTop /> : <ArrowBottom />}
        </Row>
        {this.state.selected && (
          <MenusDiv>
            {this.props.menus.map((menu, i) => (
              <div
                className="MenuItem"
                key={i}
                onClick={e => this.menuClick(e, i)}
              >
                {menu}
              </div>
            ))}
          </MenusDiv>
        )}
      </DropdownMenuDiv>
    );
  }
}
