import React from "react";
import styled, { css } from "styled-components";

const Input = styled.input`
  background-color: #fff;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  display: block;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  font-size: 0.875rem;

  &:focus {
    border-color: #1ab394;
    outline: 0;
  }

  ${p =>
    p.width &&
    css`
      width: ${p.width};
    `};

  ${p =>
    p.isValid === false &&
    css`
      &::placeholder {
        font-size: 14px;
        color: #ed2e17;
      }
    `};
`;

export default class TextBox extends React.Component {
  handleChange = event => {
    this.props.onChange && this.props.onChange(event.target.value);
  };

  render() {
    const { isValid, value, onChange, ...other } = this.props;
    return (
      <Input
        type="text"
        value={value || ""}
        onChange={this.handleChange}
        isValid={isValid}
        {...other}
      />
    );
  }
}
