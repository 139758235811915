import axios from "axios";
import _ from "lodash";
import config from "../../config";
import Session from "./Session";
import createCategoryTree from "./CategoryUtil";
import AppState from "../AppState";

export default class ApiCaller {
  static async login(phoneNumber, password) {
    const result = await axios.post("/login", {
      phoneNumber,
      password,
    });
    if (result.status == 200) {
      const token = result.headers["zhimo-token"];
      Session.create({ token });
      return true;
    } else if (result.status == 401) {
      throw [
        {
          param: "other",
          message: "登录失败，用户名或密码错误，或者用户被禁用",
        },
      ];
    } else {
      throw [{ param: "other", message: "网络异常" }];
    }
  }

  static async getCurrentUser() {
    const result = await axios.get(`/users/info`);
    if (result.status == 200) {
      return result.data;
    }
  }

  static async uploadFile(file, onUploadProgress, maxContentLength = 2000) {
    let data = new FormData();
    data.set("file", file);
    const result = await axios.post(`${config.fileServer}/files/uploadFile`, data, {
      onUploadProgress,
      maxContentLength,
    });
    if (result.status == 200) {
      return result.data;
    } else {
      return null;
    }
  }

  static async getLiveCover(vid) {
    try {
      const result = await axios.get(`${config.msLiveUrl}/video/info?vid=${vid}`);
      if (result.status == 200) {
        return result.data.image;
      }
    } catch (err) {
      console.log("getLiveCover error: " + JSON.stringify(err));
    }
  }

  static async getPlayLiveUrl(vid) {
    const result = await axios.get(`${config.msLiveUrl}/video/mobileUrl?vid=${vid}`);
    if (result.status == 200) {
      return result.data.copy[0].playurl;
    }
  }

  static async questionIsUsed(id) {
    const result = await axios.get("/questions/questionIsUsed/" + id);
    if (result.status == 200) {
      return result.data;
    }
  }

  static async fetchQuestions(ids) {
    const result = await axios.post("/questions/fetch", { ids: ids });
    if (result.status == 200) {
      // sort questions according to ids
      const questions = _.keyBy(result.data.questions, "id");
      return ids.map(id => questions[id]);
    }
  }

  static async createOrUpdateQuestion(question) {
    const result = await axios.post("/questions/add", question);
    if (result.status == 200) {
      return result.data;
    }
  }

  static async deleteQuestion(questionId) {
    return await axios.post("/questions/delete/" + questionId);
  }

  static async deleteVideo(questionId) {
    return await axios.post("/questions/deleteVideo/" + questionId);
  }

  static async deletedQuestionCount() {
    const result = await axios.get("/questions/deleted/count");
    if (result.status == 200) {
      return result.data;
    }
  }

  static async deletedQuestions(pagination) {
    const result = await axios.post("/questions/delete/list", pagination);
    if (result.status == 200) {
      return result.data;
    }
  }

  static async restoreQuestion(questionId) {
    return await axios.post("/questions/restore/" + questionId);
  }

  static async questionCartFetch(type) {
    const result = await axios.get(`/questionCart/fetch/${type}/${AppState.account.id}`);
    if (result.status == 200) {
      return result.data.questions;
    }
  }

  static async questionCartAdd(type, question) {
    try {
      const result = await axios.post("/questionCart/addQuestion", {
        type,
        accountId: AppState.account.id,
        questionIds: [question],
      });
      return result.status == 200;
    } catch (err) {
      console.log("questionCartAdd error: " + JSON.stringify(err));
    }
  }

  static async updateQuestionCart(type, questionIds) {
    const result = await axios.post("/questionCart/updateQuestionCart", {
      type,
      accountId: AppState.account.id,
      questionIds: questionIds,
    });
    if (result.status == 200) {
      return result.data;
    }
  }

  static createExamPaper(examPaper) {
    return axios.post("/examinationpapers/add", examPaper);
  }

  static async updateExamPaper(examPaper) {
    return await axios.post("/examinationpapers/update", examPaper);
  }

  static async deleteExamPaper(examPaperId) {
    return await axios.post("/examinationpapers/delete/" + examPaperId);
  }

  static async fetchExamPaperById(id) {
    try {
      const result = await axios.post("/examinationpapers/list", {
        ids: [id],
      });
      return result.data[0];
    } catch (err) {
      console.log("fetchExamPaperById error: " + JSON.stringify(err));
    }
  }

  static async findUsingPapers(questionId) {
    const result = await axios.get(`/questions/findUsingPapers/${questionId}`);
    if (result.status == 200) {
      return result.data;
    }
  }

  static async fetchExamPaperQuestions(examPaper) {
    const rawQuestions = await ApiCaller.fetchQuestions(_.map(examPaper.questions, "questionId"));
    const questions = _.zipWith(examPaper.questions, rawQuestions, function(question, raw) {
      return {
        ...raw,
        blankLine: question.blankLine == null ? raw.blankLine : question.blankLine,
        showQR: question.showQR,
        showFrom: question.showFrom,
      };
    });
    return questions;
  }

  static async fetchGroupQuestions(questions) {
    let fetchedQuestions = [];
    for (let question of questions) {
      if (question.groupQuestions) {
        question.groupQuestions = await ApiCaller.fetchQuestions(
          _.map(question.groupQuestions, "questionId")
        );
        fetchedQuestions.push(question);
      } else {
        fetchedQuestions.push(question);
      }
    }
    return fetchedQuestions;
  }

  static async loadCategories(subjects) {
    let categories = {};
    for (const subject of Object.keys(subjects)) {
      categories[subject] = await ApiCaller.loadCategoriesBySubject(subject);
    }
    return categories;
  }

  static async loadCategoriesBySubject(subject) {
    try {
      const result = await axios.get("/categories/subject/" + subject);
      if (result.status == 200) {
        const categories = result.data.map(category => ({
          ...category,
          value: category.id,
          label: category.number + " " + category.name,
          collapsed: true,
        }));
        return createCategoryTree(categories);
      }
    } catch (err) {
      console.log("loadCategories error: " + JSON.stringify(err));
    }
    return [];
  }

  static async addCategory(category) {
    const result = await axios.post("/categories/add", category);
    if (result.status == 200) {
      return result.data;
    } else {
      return null;
    }
  }

  static async updateCategory(category) {
    const result = await axios.post("/categories/update", category);
    if (result.status == 200) {
      return result.data;
    } else {
      return null;
    }
  }

  static async deleteCategory(id) {
    const result = await axios.post("/categories/delete/" + id);
    if (result.status == 200) {
      return true;
    } else {
      return result.data;
    }
  }

  static async getCategories(ids) {
    const result = await axios.post("/categories/getCategoryByIds", { ids });
    return result.data;
  }

  static async getSubjects() {
    const result = await axios.get("/subjects/list");
    if (result.status == 200) {
      return _.mapValues(_.keyBy(result.data, "number"), "name");
    } else {
      return {};
    }
  }

  static async getNoTestSubjects() {
    const result = await axios.get("/subjects/getNoTestSubjects");
    if (result.status == 200) {
      return _.mapValues(_.keyBy(result.data, "number"), "name");
    } else {
      return {};
    }
  }

  static async addSubject(subject) {
    const result = await axios.post("/subjects/add", subject);
    if (result.status == 200) {
      return result.data;
    } else {
      throw [result.data];
    }
  }

  static async deleteSubject(number) {
    const result = await axios.post("/subjects/delete/" + number);
    if (result.status == 200) {
      return true;
    } else {
      return result.data;
    }
  }

  static async getVideoUploadToken() {
    const result = await axios.post("/video/videoUploadToken");
    if (result.status == 200) {
      return result.data.token;
    } else {
      return null;
    }
  }

  static async createOrUpdateRole(role) {
    return await axios.post("/role/createOrUpdate", role);
  }

  static async createOrUpdateAccount(account) {
    return await axios.post("/users/createOrUpdateAccount", account);
  }

  static async changePassword(account) {
    return await axios.post("/users/changePassword", account);
  }

  static async deleteRole(id) {
    return await axios.post("/role/delete/" + id);
  }

  static async getRoles() {
    const result = await axios.post("/role/list");
    if (result.status == 200) {
      return _.keyBy(result.data, "id");
    }
  }

  static async getRolesByIds(ids) {
    const result = await axios.post("/role/getRolesByIds", { ids: ids });
    if (result.status == 200) {
      return _.keyBy(result.data, "id");
    }
  }

  static async toggleEnabled(id) {
    return await axios.post("/users/delete/" + id);
  }

  static async getUserCounts() {
    const result = await axios.get("/users/getUserCounts");
    if (result.status == 200) {
      return result.data;
    }
  }
}
