import React from "react";
import styled, { css } from "styled-components";
import latex2Html from "../utils/latex2Html";
import { Col, Row } from "./FlexboxGrid";
import FileUploader from "./FileUploader";

const InputBox = styled.textarea`
  min-height: 33px;
  height: ${p => p.height || "180px"};
  width: calc(100% - 6em);
  outline: 0;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  overflow: auto;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:focus {
    outline: 0;
    border-color: #409eff;
  }

  ${p =>
    p.isValid === false &&
    css`
      &::placeholder {
        font-size: 14px;
        color: #ed2e17;
      }
    `};
`;

const Editor = styled(Col)`
  width: calc(100% - 4em);
  button {
    align-self: flex-start;
  }
`;

export default class LatexEditor extends React.Component {
  state = {};

  static getDerivedStateFromProps({ latex, images }, { html: prevHtml }) {
    let html = "";
    try {
      html = latex2Html(latex);
    } catch (error) {
      html = prevHtml || `<p style="color:red">语法错误：${error.message}</p>`;
    }
    return {
      latex: latex || "",
      images: images || [],
      html,
    };
  }

  running = false;

  handleChange = event => {
    const latex = event.target.value;
    this.setState({ latex });
    this.props.onChange && this.props.onChange(latex);
    if (this.running) return;
    this.renderPreview(latex);
  };

  insertImage = image => {
    const latex =
      this.state.latex + `\\includegraphics[width=20%]{${image.fileUrl}}`;
    const images = this.state.images.concat(image.fileUrl);
    this.setState({ latex, images });
    this.props.onChange && this.props.onChange(latex);
    this.props.onImageChange && this.props.onImageChange(images);
    this.renderPreview(latex);
  };

  renderPreview(latex) {
    this.running = true;
    try {
      const html = latex2Html(latex);
      this.setState({ html });
      this.props.onHtmlChange && this.props.onHtmlChange(html);
    } finally {
      this.running = false;
    }
  }

  render() {
    const {
      latex: _latex,
      isValid,
      onChange,
      onHtmlChange,
      onImageChange,
      ...other
    } = this.props;
    const { latex, html } = this.state;
    return (
      <Editor>
        <Row top space="between">
          <InputBox
            spellCheck="false"
            value={latex}
            isValid={isValid}
            onChange={this.handleChange}
            {...other}
          />
          <FileUploader
            fileType=".jpg,.jpeg,.svg,.png"
            uploadText="上传图片"
            onUploaded={this.insertImage}
          />
        </Row>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Editor>
    );
  }
}
