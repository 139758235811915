import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { createGlobalStyle } from "styled-components";
import "reboot.css";
//import "react-tabs/style/react-tabs.css";
import "react-ui-tree/lib/react-ui-tree.css";
import "react-dropdown-tree-select/dist/styles.css";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";
import "katex/dist/katex.min.css";
import "./src/utils/AxiosConfig";
import App from "./src/App";
import { loadAppState } from "./src/AppState";

const GlobalStyles = createGlobalStyle`
  html, body, #app {
    width: 100%;
    height: 100%;
  }
  #app {
    color: #333;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: "MicrosoftYaHei", "PingFangSC-Regular", "Source Han Sans CN","tahoma", "Helvetica Neue", "Roboto", "Segoe UI", sans-serif;
  }
  .swal-modal.dialog {
    width: 80%;
  }
  .swal-modal.addQuestionDialog {
    width: 40%;
  }
  .swal-modal.previewQuestionDialog {
    width: 794px;
    min-height: 922px;
    .swal-content{
      width: 100%;
      height: 100%;
    }
  }
  .katex {
    font-size: 1em;
    .mfrac {
      font-size: 1.21em;
    }
  }
  .figure {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .subfigure {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

Modal.setAppElement("#app");

loadAppState().then(() => {
  ReactDOM.render(
    <>
      <GlobalStyles /> <App />
    </>,
    document.getElementById("app")
  );
});
