import React, { useState } from "react";
import styled, { css } from "styled-components";
import { store, view } from "react-easy-state";
import { Link } from "react-router-dom";
import { local } from "dayjs";
import schema from "schm";
import _ from "lodash";
import compose from "../utils/compose";
import parseQuery from "../enhancers/parseQuery";
import didMount from "../enhancers/didMount";
import unMount from "../enhancers/unMount";
import DataStore from "../utils/DataStore";
import PageFrame from "../widgets/PageFrame";
import LatexContent from "../components/LatexContent";
import { Col, Row } from "../components/FlexboxGrid";
import Button from "../components/Button";
import ToggleButton from "../components/ToggleButton";
import Icon from "../components/Icon";
import Tag, { Mark } from "../components/Tag";
import Dialog from "../components/Dialog";
import Popup from "../widgets/Popup";
import QuestionDetail from "./QuestionDetail";
import TextBox from "../components/TextBox";
import ValidationError from "../components/ValidationError";
import Toast from "../components/Toast";
import ApiCaller from "../utils/ApiCaller";
import Alert from "../components/Alert";
import { grades, questionTypes, difficulties, questionFuns } from "../utils/constants";
import AppState from "../AppState";
import HashMap from "../utils/HashMap";
import QuestionGroup from "../widgets/QuestionGroup";
import QuestionItem from "../widgets/QuestionItem";
import QuestionHistory from "./QuestionHistory";
import AddQuestionDialog from "../widgets/AddQuestionDialog";
import LinkButton from "../components/LinkButton";
import hasPermission from "../utils/hasPermission";
import RadioButtonList from "../components/RadioButtonList";
// import DropdownMenu from "../components/DropdownMenu";
import Dropdown, {
  DropdownToggle,
  DropdownMenu,
  DropdownMenuWrapper,
  MenuItem,
  DropdownButton,
} from "@trendmicro/react-dropdown";

// Be sure to include styles at some point, probably during your bootstraping
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";

const dataStore = new DataStore({});

const ToggleIcon = styled(Icon)`
  div {
    display: flex;
    margin-left: 1rem;
  }
  svg {
    fill: #333;
    width: 3rem;
    height: 1.5rem;
  }
`;

const CustomDropdownMenu = props => <Dropdown.Menu {...props} style={{ padding: "2px 0" }} />;
CustomDropdownMenu.propTypes = Dropdown.Menu.propTypes;
CustomDropdownMenu.defaultProps = Dropdown.Menu.defaultProps;

const QuestionHead = styled(Row)`
  padding: 10px 2px;
  h4,
  label {
    margin: 0;
  }
`;

const CardDiv = styled(Col)`
  display: flex;
  flex-direction: row;
`;

const Card = styled(Col)`
  width: calc(100% - 15px);
  margin-left: 10px;
  margin-bottom: 20px;
  background-color: #fff;
  color: #303133;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  border-radius: 4px;
  transition: 0.3s;
  #overflow: hidden;
`;

const ButtonRow = styled(Row)`
  padding: 0.5em 0.5em;
  border-top: 1px solid #ebeef5;
  button {
    height: 100%;
    padding: 0.375rem 1rem;
    margin-left: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  label {
    margin-bottom: 0;
  }
`;

const FieldRow = styled(Row)`
  margin-bottom: 0.5em;
  label {
    margin-right: 1em;
    white-space: nowrap;
  }
  input {
    width: 90%;
    height: 36px;
    line-height: 36px;
    padding-right: 30px;
    border-radius: 4px;
    outline: 0;
    padding: 0 15px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
  }
  input[type="number"] {
    width: 5em;
    text-align: center;
    margin-right: 0.3em;
  }
`;

const RadioButtonRow = styled(Row)`
  margin-bottom: 0.8em;
  label {
    margin-right: 1em;
    white-space: nowrap;
  }
  .inlineBox {
    margin-right: 1em;
  }
  .numberBox {
    width: 9em;
    text-align: center;
  }
  .blankLine {
    width: 5em;
    text-align: center;
    margin-right: 0.3em;
  }
`;

const QuItem = styled(Row)`
  width: 100%;
`;

const DisplayAllInfo = styled(Row)`
  justify-content: center;
  color: #409eff;
  cursor: pointer;
`;

const paperSchema = schema({
  id: String,
  title: {
    type: String,
    required: [true, "  "],
    maxlength: [50, "试卷名称最多50个字符"],
  },
  subtitle: {
    type: String,
    maxlength: [50, "试卷副标题最多50个字符"],
  },
  knowledgePoints: String,
  duration: Number,
  studentInfoDisplay: Boolean,
  printFontSize: Number,
  questions: [Object],
  createdBy: String,
});

const handleUp = index => {
  if (index === 0) {
    Alert.error("已经是一个了!");
    return;
  }

  const tmp = dataStore.state.questionCartData[index];
  dataStore.state.questionCartData.splice(index, 1, dataStore.state.questionCartData[index - 1]);
  dataStore.state.questionCartData.splice(index - 1, 1, tmp);
};

const handleDown = index => {
  if (index === dataStore.state.questionCartData.length - 1) {
    Alert.error("已经是最后一位了!");
    return;
  }

  const tmp = dataStore.state.questionCartData[index];
  dataStore.state.questionCartData.splice(index, 1, dataStore.state.questionCartData[index + 1]);
  dataStore.state.questionCartData.splice(index + 1, 1, tmp);
};

function viewHistory(question) {
  Dialog.show(
    <Popup title="组卷历史记录">
      <QuestionHistory question={question} />
    </Popup>,
    {
      buttons: false,
      className: "dialog",
    }
  );
}

async function toggleShowFrom(question, index, value) {
  let showFrom = value == "on";
  if (showFrom && question.from == null) {
    const ok = await Alert.warning("确认展示试题来源吗？", {
      buttons: ["否", "是"],
    });
    if (ok != true) {
      return false;
    }
  }
  dataStore.state.questionCartData.splice(index, 1, {
    ...question,
    showFrom,
  });
  return true;
}

async function toggleShowQR(question, index, value) {
  let showQR = value == "on";
  if (showQR && question.videoFile == null) {
    const ok = await Alert.warning("该题还未上传视频，确认展示二维码吗？", {
      buttons: ["否", "是"],
    });
    if (ok !== true) {
      return false;
    }
  }
  dataStore.state.questionCartData.splice(index, 1, {
    ...question,
    showQR,
  });
  return true;
}

const ExamPaperEditor = props => {
  const [displayAllInfo, setDisplayAllInfo] = useState(false);
  if (dataStore.state.questionCartData == null) {
    return null;
  }
  if (dataStore.state.counts == null) {
    return null;
  }
  const { history, location, query, loading } = props;
  const errors = dataStore.state.errors;
  const bigEdit = !(dataStore.state.examPaper.submitCnt > 0);
  return (
    <PageFrame title={dataStore.state.title} loading={loading}>
      <FieldRow nowrap>
        <label>试卷名称：</label>
        <TextBox
          placeholder="请输入试卷名称（最多50个字符）"
          value={dataStore.state.examPaper.title}
          onChange={text => (dataStore.state.examPaper.title = text)}
          isValid={errors["title"] === undefined}
        />
        {errors["title"] && <ValidationError>{errors["title"].message}</ValidationError>}
      </FieldRow>
      <RadioButtonRow valign="middle">
        <label>学生信息：</label>
        <RadioButtonList
          options={[{ value: true, label: "显示" }, { value: false, label: "隐藏" }]}
          value={dataStore.state.examPaper.studentInfoDisplay}
          onChange={value => {
            dataStore.state.examPaper.studentInfoDisplay = value;
          }}
        />
      </RadioButtonRow>
      <RadioButtonRow valign="middle">
        <label>打印字号：</label>
        <RadioButtonList
          options={[{ value: 16, label: "小学 (16号)" }, { value: 14, label: "初/高中 (14号)" }]}
          value={dataStore.state.examPaper.printFontSize}
          onChange={value => {
            dataStore.state.examPaper.printFontSize = value;
          }}
        />
      </RadioButtonRow>
      {displayAllInfo && (
        <div>
          <FieldRow nowrap>
            <label>试卷副标题：</label>
            <TextBox
              placeholder="请输入试卷副标题（最多50个字符）"
              value={dataStore.state.examPaper.subtitle}
              onChange={text => (dataStore.state.examPaper.subtitle = text)}
              isValid={errors["subtitle"] === undefined}
            />
            {errors["subtitle"] && <ValidationError>{errors["subtitle"].message}</ValidationError>}
          </FieldRow>
          <FieldRow nowrap>
            <label>试卷知识点：</label>
            <TextBox
              placeholder="请输入试卷知识点，用于搜索"
              value={dataStore.state.examPaper.knowledgePoints}
              onChange={text => (dataStore.state.examPaper.knowledgePoints = text)}
            />
          </FieldRow>
          <FieldRow valign="middle" nowrap>
            <label>建议考试时间：</label>
            <TextBox
              type="number"
              min="0"
              value={dataStore.state.examPaper.duration}
              onChange={value => (dataStore.state.examPaper.duration = value)}
            />
            分钟
            {errors["duration"] && <ValidationError>{errors["duration"].message}</ValidationError>}
          </FieldRow>
          <FieldRow nowrap>
            <label>知识点：</label>
            <Row shrink={1}>
              {dataStore.state.counts.categories.keys().map(category => (
                <Tag key={category}>
                  {dataStore.state.categories[category]}
                  <Mark>{dataStore.state.counts.categories[category]}</Mark>
                </Tag>
              ))}
            </Row>
          </FieldRow>
          <FieldRow>
            <label>年级：</label>
            {dataStore.state.counts.grades.keys().map(grade => (
              <Tag key={grade}>
                {grades[grade]}
                <Mark>{dataStore.state.counts.grades[grade]}</Mark>
              </Tag>
            ))}
          </FieldRow>
          <FieldRow>
            <label>类型：</label>
            {dataStore.state.counts.types.keys().map(type => (
              <Tag key={type}>
                {questionTypes[type]}
                <Mark>{dataStore.state.counts.types[type]}</Mark>
              </Tag>
            ))}
          </FieldRow>
          <FieldRow>
            <label>归类：</label>
            {dataStore.state.counts.tags.keys().map(tag => (
              <Tag key={tag}>
                {tag}
                <Mark>{dataStore.state.counts.tags[tag]}</Mark>
              </Tag>
            ))}
          </FieldRow>
          <FieldRow>
            <label>难度：</label>
            {dataStore.state.counts.difficulties.keys().map(difficulty => (
              <Tag key={difficulty}>
                {difficulties[difficulty]}
                <Mark>{dataStore.state.counts.difficulties[difficulty]}</Mark>
              </Tag>
            ))}
          </FieldRow>
        </div>
      )}
      <DisplayAllInfo valign="middle" onClick={() => setDisplayAllInfo(!displayAllInfo)}>
        {displayAllInfo ? (
          <>
            收起
            <img
              style={{ transform: "rotate(180deg)" }}
              src={require("../resources/images/upArrowBlue.png")}
            />
          </>
        ) : (
          <>
            更多设置
            <img src={require("../resources/images/upArrowBlue.png")} />
          </>
        )}
      </DisplayAllInfo>
      <QuestionHead space="between">
        <h4>题目详情：</h4>
        <label>共{dataStore.state.questionCartData.length}题</label>
      </QuestionHead>
      {!_.isEmpty(dataStore.state.questionCartData) ? (
        dataStore.state.questionCartData.map((question, index) => (
          <CardDiv key={index}>
            <div>{index + 1}.</div>
            <ListItem
              key={question.id}
              index={index}
              question={question}
              bigEdit={bigEdit}
              isButtonRow="true"
            />
          </CardDiv>
        ))
      ) : (
        <Col align="center">
          <ValidationError>组卷中心没有题目，请先添加题目</ValidationError>
        </Col>
      )}
      {bigEdit && (
        <Row align="right">
          <LinkButton onClick={() => showDialog("addQuestion")}>+添加题目</LinkButton>
        </Row>
      )}
      <ButtonRow>
        <Button primary onClick={() => createOrUpdateExamPaper()}>
          {dataStore.state.title}
        </Button>
        <Button primary onClick={() => history.goBack()}>
          返回
        </Button>
      </ButtonRow>
      <input type="hidden" defaultValue={dataStore.state.messages} />
    </PageFrame>
  );
};

const ListItem = ({ question, index, bigEdit, isButtonRow }) => {
  const { blankLine, showQR, showFrom, groupQuestions, questionsPerLine } = question;
  return (
    <QuItem>
      <Card>
        {groupQuestions ? (
          <QuestionGroup
            container={Col}
            questionGroup={question}
            buttons={
              <Row>
                <label>一行{questionsPerLine}题</label>&nbsp;&nbsp;&nbsp;
                <label>难度系数:{question.diffCoefficient || 0}</label>
                &nbsp;&nbsp;&nbsp;
                <label>共{groupQuestions.length}题</label>
              </Row>
            }
            questionButtons={subQuestion => (
              <ButtonRow valign="middle" align="right">
                <label>空{subQuestion.blankLine}行</label>&nbsp;
                <Link to={`/question/edit?id=${subQuestion.id}`}>
                  <Button primary>编辑</Button>
                </Link>
                <Button primary onClick={() => viewQuestion(subQuestion)}>
                  查看
                </Button>
              </ButtonRow>
            )}
          />
        ) : (
          <QuestionItem container={Col} question={question} />
        )}
        {isButtonRow == "true" && (
          <ButtonRow space="between">
            <Row>
              {bigEdit && (
                <>
                  <Button className="up-arrow" onClick={() => handleUp(index)}>
                    <Icon src={require("../resources/icons/arrow-up.svg")} />
                    上移
                  </Button>
                  <Button className="down-arrow" onClick={() => handleDown(index)}>
                    <Icon src={require("../resources/icons/arrow-down.svg")} />
                    下移
                  </Button>
                </>
              )}
            </Row>
            <Row>
              <Row valign="middle">
                空行数：
                <TextBox
                  type="number"
                  value={blankLine}
                  min={0}
                  width="4em"
                  onChange={blankLine => {
                    dataStore.state.questionCartData.splice(index, 1, {
                      ...question,
                      blankLine: _.toNumber(blankLine),
                    });
                  }}
                />
                行 &nbsp;&nbsp;
              </Row>
              <Row valign="middle">
                展示来源
                <ToggleButton
                  value={showFrom ? "on" : "off"}
                  onClick={value => toggleShowFrom(question, index, value)}
                >
                  {{
                    off: <ToggleIcon src={require("../resources/icons/off.svg")} />,
                    on: <ToggleIcon src={require("../resources/icons/on.svg")} />,
                  }}
                </ToggleButton>
              </Row>
              <Row valign="middle">
                展示二维码
                <ToggleButton
                  value={showQR ? "on" : "off"}
                  onClick={value => toggleShowQR(question, index, value)}
                >
                  {{
                    off: <ToggleIcon src={require("../resources/icons/off.svg")} />,
                    on: <ToggleIcon src={require("../resources/icons/on.svg")} />,
                  }}
                </ToggleButton>
              </Row>
              <Row>
                {/* 聚合操作按钮区域 */}
                <Dropdown onSelect={eventKey => {}}>
                  <Dropdown.Toggle btnStyle="flat">操作</Dropdown.Toggle>
                  <Dropdown.Menu>
                    {(AppState.account.isAdmin ||
                      hasPermission("编辑已答题目") ||
                      ((question.studentCount == null || question.studentCount == 0) &&
                        hasPermission("编辑题目"))) && (
                      <MenuItem eventKey={2}>
                        <Link
                          to={
                            groupQuestions
                              ? `/groupQuestion/edit?id=${question.id}`
                              : `/question/edit?id=${question.id}`
                          }
                        >
                          编辑
                        </Link>
                      </MenuItem>
                    )}
                    {!groupQuestions && (
                      <MenuItem eventKey={3} onClick={() => viewQuestion(question)}>
                        查看
                      </MenuItem>
                    )}
                    <MenuItem eventKey={4} onClick={() => viewHistory(question)}>
                      历史
                    </MenuItem>
                    {bigEdit && (
                      <>
                        <MenuItem
                          eventKey={4}
                          onClick={() => {
                            removeQuestion(question.id);
                          }}
                        >
                          移除
                        </MenuItem>
                        <MenuItem
                          eventKey={5}
                          onClick={() => {
                            dataStore.state.curQuestionId = question.id;
                            showDialog("replaceQuestion");
                          }}
                        >
                          替换
                        </MenuItem>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                {/* 聚合操作按钮区域 */}
              </Row>
            </Row>
          </ButtonRow>
        )}
      </Card>
    </QuItem>
  );
};

function showDialog(type) {
  Dialog.show(
    <AddQuestionDialog
      type={type}
      questionCartData={dataStore.state.questionCartData}
      dialogClose={() => Dialog.close()}
      addQuestion={questions => addQuestion(questions)}
      replaceQuestion={questions => replaceQuestion(questions)}
    />,
    {
      buttons: false,
      className: "addQuestionDialog",
    }
  );
}

async function addQuestion(questions) {
  const groupQuestions = await ApiCaller.fetchGroupQuestions(questions);
  dataStore.state.questionCartData.push(groupQuestions[0]);
  const questionFlatMap = _.flatMap(
    dataStore.state.questionCartData,
    question => question.groupQuestions || [question]
  );
  const categoryIds = _.uniq(_.map(_.flatten(_.map(questionFlatMap, "categories")), "id"));
  const categories = await ApiCaller.getCategories(categoryIds);
  dataStore.state.categories = _.mapValues(_.keyBy(categories, "id"), "label");
  dataStore.state.counts = countQuestions(dataStore.state.questionCartData);
  if (dataStore.state.title == "编辑试卷") {
    createOrUpdateExamPaper("添加成功");
  }
  Dialog.close();
}

function countQuestions(questions) {
  let categories = new HashMap(0);
  let grades = new HashMap(0);
  let types = new HashMap(0);
  let tags = new HashMap(0);
  let difficulties = new HashMap(0);
  const questionFlatMap = _.flatMap(questions, question => question.groupQuestions || [question]);
  for (const question of questionFlatMap) {
    for (const category of question.categories) {
      if (_.has(dataStore.state.categories, category.id)) {
        categories[category.id] += 1;
      }
    }
    if (question.tags) {
      for (const tag of question.tags) {
        tags[tag] += 1;
      }
    }
    grades[question.grade] += 1;
    types[question.type] += 1;
    if (question.difficulty) {
      difficulties[question.difficulty] += 1;
    }
  }
  return { categories, grades, types, tags, difficulties };
}

async function createOrUpdateExamPaper(message) {
  if (dataStore.state.questionCartData.length < 1) {
    Alert.error("最少要1道题");
    return;
  }
  try {
    dataStore.state.errors = {};
    if (dataStore.state.examPaper.createdBy == null) {
      dataStore.state.examPaper.createdBy = AppState.account.id;
    }
    const examPaper = await paperSchema.validate(dataStore.state.examPaper);
    const questions = dataStore.state.questionCartData.map(question => {
      return {
        questionId: question.id,
        blankLine: question.blankLine,
        showQR: question.showQR,
        showFrom: question.showFrom,
        score: 10,
      };
    });
    const result = await ApiCaller.createExamPaper({
      ...examPaper,
      questions,
    });
    if (result.status == 200) {
      if (dataStore.state.title == "生成试卷") {
        await ApiCaller.updateQuestionCart("exam", []);
      }
      if (message) {
        await Toast.info(message);
      } else {
        await Toast.info(dataStore.state.title.replace("试卷", "成功"));
      }
      if (dataStore.state.title == "复制试卷" || dataStore.state.title == "生成试卷") {
        window.history.back();
      }
    } else {
      Toast.error(result.data);
    }
  } catch (errors) {
    dataStore.state.errors = _.keyBy(errors, "param");
  }
}

function viewQuestion(question) {
  Dialog.show(
    <Popup title="查看题目">
      <QuestionDetail question={question} />
    </Popup>,
    {
      buttons: false,
      className: "dialog",
    }
  );
}

async function removeQuestion(questionId) {
  if (dataStore.state.title != "生成试卷" && dataStore.state.questionCartData.length == 1) {
    Alert.error("最少要1道题");
    return;
  }
  const arr = dataStore.state.questionCartData;
  arr.splice(arr.findIndex(item => item.id === questionId), 1);
  if (dataStore.state.title == "生成试卷") {
    await ApiCaller.updateQuestionCart("exam", _.map(arr, "id"));
  }
  dataStore.state.counts = countQuestions(dataStore.state.questionCartData);
}

async function replaceQuestion(questions) {
  const groupQuestions = await ApiCaller.fetchGroupQuestions(questions);
  dataStore.state.questionCartData.splice(
    dataStore.state.questionCartData.findIndex(item => item.id === dataStore.state.curQuestionId),
    1,
    groupQuestions[0]
  );
  if (dataStore.state.title == "生成试卷") {
    await ApiCaller.updateQuestionCart("exam", _.map(dataStore.state.questionCartData, "id"));
  }
  const questionFlatMap = _.flatMap(
    dataStore.state.questionCartData,
    question => question.groupQuestions || [question]
  );
  const categoryIds = _.uniq(_.map(_.flatten(_.map(questionFlatMap, "categories")), "id"));
  const categories = await ApiCaller.getCategories(categoryIds);
  dataStore.state.categories = _.mapValues(_.keyBy(categories, "id"), "label");
  dataStore.state.counts = countQuestions(dataStore.state.questionCartData);
  if (dataStore.state.title == "编辑试卷") {
    createOrUpdateExamPaper("替换成功");
  }
  Dialog.close();
}

export default compose(
  parseQuery,
  unMount(() => {
    dataStore.state.questionCartData = [];
  }),
  didMount(async p => {
    dataStore.state.errors = {};
    if (_.isEmpty(p.query.id)) {
      dataStore.state.title = "生成试卷";
      dataStore.state.questionCart = await ApiCaller.questionCartFetch("exam");
      dataStore.state.examPaper = {
        title: "",
        subtitle: "",
        knowledgePoints: "",
        duration: 10,
        studentInfoDisplay: false,
        printFontSize: 16,
      };
      dataStore.state.questions = await ApiCaller.fetchQuestions(dataStore.state.questionCart);
    } else {
      dataStore.state.examPaper = await ApiCaller.fetchExamPaperById(p.query.id);
      dataStore.state.questions = await ApiCaller.fetchExamPaperQuestions(
        dataStore.state.examPaper
      );
      if (dataStore.state.examPaper.subtitle === null) {
        dataStore.state.examPaper.subtitle = "";
      }
      if (dataStore.state.examPaper.knowledgePoints === null) {
        dataStore.state.examPaper.knowledgePoints = "";
      }
      if (p.location.pathname.endsWith("/edit")) {
        dataStore.state.title = "编辑试卷";
      } else if (p.location.pathname.endsWith("/copy")) {
        dataStore.state.title = "复制试卷";
        dataStore.state.examPaper.id = null;
        dataStore.state.examPaper.title = "";
        dataStore.state.examPaper.subtitle = "";
        dataStore.state.examPaper.createdBy = null;
      }
    }
    dataStore.state.questionCartData = await ApiCaller.fetchGroupQuestions(
      dataStore.state.questions
    );
    const questionFlatMap = _.flatMap(
      dataStore.state.questionCartData,
      question => question.groupQuestions || [question]
    );
    const categoryIds = _.uniq(_.map(_.flatten(_.map(questionFlatMap, "categories")), "id"));
    const categories = await ApiCaller.getCategories(categoryIds);
    dataStore.state.categories = _.mapValues(_.keyBy(categories, "id"), "name");
    dataStore.state.counts = countQuestions(dataStore.state.questionCartData);
  }),
  view
)(ExamPaperEditor);
