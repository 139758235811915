import { store } from "react-easy-state";
import axios from "axios";
import Alert from "../components/Alert";

export default class DataStore {
  state = store({
    loading: false,
    data: null,
  });

  options = {};

  async loadData(callback) {
    this.state.loading = true;
    const options = this.options;
    const result = await axios.request({
      url: options.url,
      ...options.config,
    });
    this.state.loading = false;
    if (result.status == 200) {
      this.state.data = options.extract(result);
      callback && callback(this.state.data);
    } else {
      Alert.error(result.data.message);
      this.state.data = null;
    }
  }

  reload() {
    return this.loadData();
  }
}
