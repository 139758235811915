import React, { Component } from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import { Col, Row } from "../components/FlexboxGrid";
import LatexContent from "../components/LatexContent";
import AppState from "../AppState";
import ApiCaller from "../utils/ApiCaller";
import { statuses, statusThemes, grades, questionTypes, difficulties } from "../utils/constants";

const Card = styled(Col)`
  margin-bottom: 20px;
  background-color: #fff;
  color: #303133;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  border-radius: 4px;
  transition: 0.3s;
  overflow: hidden;
`;

const CardHeader = styled(Row)`
  position: relative;
  padding: 18px 20px;
  border-bottom: 1px solid #ebeef5;
  overflow: hidden;
  h4,
  label {
    z-index: 2;
    margin: 0;
  }
`;

const CardBody = styled(Col)`
  padding: 18px 20px;
`;

const CardFoot = styled(Row)`
  padding: 0 20px 20px;
`;

const OptionList = styled(Col)`
  margin-top: 1em;
`;

const OptionItem = styled(Row)`
  align-items: center;
  margin-bottom: 0.5em;
  label {
    margin-right: 5px;
    margin-bottom: 0;
  }
`;
const Themes = {
  draft: css`
    background: #828282;
  `,
  firstTrialPassed: css`
    background: #ffa53b;
  `,
  psComplete: css`
    background: #4196de;
  `,
  videoUpload: css`
    background: #ca6cff;
  `,
  finalApproval: css`
    background: #25a73f;
  `,
};
const Status = styled.div`
  z-index: 1;
  position: absolute;
  color: #fff;
  font-size: 14px;
  text-align: center;
  transform: rotate(-35deg);
  width: 120px;
  line-height: 30px;
  left: -32px;
  top: 5px;
  ${p => Themes[p.className]};
`;

const Labels = styled.div`
  color: gray;
  margin-top: 2em;
`;

const Categories = styled(Row)`
  margin-top: 0.5em;
`;

export default class QuestionItem extends Component {
  state = {
    categories: [],
  };

  async componentDidMount() {
    let categoriesDate = await ApiCaller.getCategories(_.map(this.props.question.categories, "id"));
    this.setState({
      categories: categoriesDate.map(item => item.name),
    });
  }

  render() {
    const {
      status,
      subject,
      grade,
      type,
      body,
      options,
      difficulty,
      videoFile,
      diffCoefficient,
      studentCount,
      subQuestions,
      from,
    } = this.props.question;
    return (
      <Card>
        <CardHeader space="between">
          {status && <Status className={statusThemes[status]}>{statuses[status]}</Status>}
          <h4>
            {status && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
            ID:
            {this.props.question.id}
            &nbsp;&nbsp;&nbsp;
            {AppState.subjects[subject]}-{grades[grade]}-{questionTypes[type]}
            &nbsp;&nbsp;&nbsp; 视频:
            {videoFile && videoFile.ccvid ? "已上传" : "未上传"}
          </h4>
          <label>
            {difficulties[difficulty]}
            &nbsp;&nbsp;&nbsp;难度系数:
            {diffCoefficient || 0}
            &nbsp; ({studentCount || 0}
            人作答)
          </label>
        </CardHeader>
        <CardBody>
          <LatexContent latex={body.content} />
          {subQuestions && (
            <OptionList>
              {subQuestions.map((subQuestion, index) => (
                <OptionItem key={index}>
                  <label>{String.fromCharCode(97 + index)})</label>
                  <LatexContent latex={subQuestion.body.content} />
                </OptionItem>
              ))}
            </OptionList>
          )}

          {(type == "choice" || type == "multichoice") && (
            <OptionList>
              {options.map(
                option =>
                  option.value && (
                    <OptionItem key={option.label}>
                      <label>{option.label}.</label>
                      <LatexContent latex={option.value.content} />
                    </OptionItem>
                  )
              )}
            </OptionList>
          )}
          <Labels>
            {from && (
              <Row>
                来源：
                {[from.year, from.area, from.paper, from.number].join("-")}
              </Row>
            )}
            <Categories shrink={1}>
              <label>知识点：</label>
              <span>{this.state.categories.join("、")}</span>
            </Categories>
          </Labels>
        </CardBody>
        <CardFoot align="right">{this.props.buttons}</CardFoot>
      </Card>
    );
  }
}
