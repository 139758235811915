import React from "react";
import styled from "styled-components";

const Tag = styled.label`
  position: relative;
  background-color: rgba(64, 158, 255, 0.1);
  color: #409eff;
  padding: 0 10px;
  height: 32px;
  line-height: 30px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid rgba(64, 158, 255, 0.2);
  white-space: nowrap;
  margin-right: 10px;
  margin-bottom: 0;
`;

export const Mark = styled.sup`
  position: absolute;
  top: 0;
  right: 10px;
  transform: translateY(-50%) translateX(100%);
  background-color: #f56c6c;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #fff;
`;

export default Tag;
