import axios from "axios";
import appConfig from "../../config";
import Session from "./Session";

axios.defaults.baseURL = appConfig.apiServer;

axios.defaults.validateStatus = function() {
  return true;
};

axios.interceptors.request.use(
  function(config) {
    if (config.url.startsWith("/")) {
      const account = Session.current();
      if (account != null) {
        config.headers["Zhimo-Token"] = account.token;
      }
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
