import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import queryString from "query-string";
import Tree from "react-ui-tree";
import { store, view } from "react-easy-state";
import { MenuItem, DropdownButton } from "@trendmicro/react-dropdown";
import _ from "lodash";
import { Col, Row } from "../components/FlexboxGrid";
import Button from "../components/Button";
import DropdownList from "../components/DropdownList";
import Dialog from "../components/Dialog";
import Pager from "../components/Pager";
import Alert from "../components/Alert";
import fetchData from "../enhancers/fetchData";
import { createOptions } from "../components/Selector";
import MultiSelector from "../components/MultiSelector";
import SearchInput from "../components/SearchInput";
import PageFrame from "../widgets/PageFrame";
import Popup from "../widgets/Popup";
import compose from "../utils/compose";
import parseQuery from "../enhancers/parseQuery";
import didMount from "../enhancers/didMount";
import DataStore from "../utils/DataStore";
import QuestionDetail from "./QuestionDetail";
import QuestionHistory from "./QuestionHistory";
import QuestionItem from "../widgets/QuestionItem";
import ApiCaller from "../utils/ApiCaller";
import Svg from "react-svg";
import Toast from "../components/Toast";
import {
  statuses,
  grades,
  questionTypes,
  questionTags,
  questionFeatures,
  questionIdeologies,
  difficulties,
  orderName,
  questionFuns,
} from "../utils/constants";
import AppState from "../AppState";
import hasPermission from "../utils/hasPermission";

const DefaultPageSize = 10;

const dataStore = new DataStore();

const FilterBox = styled(Col)`
  padding: 0.5em;
  border: 1px solid #ebeef5;
  margin-bottom: 10px;
  label {
    white-space: nowrap;
    margin: 0;
  }
`;

const SearchBox = styled(Row)`
  margin-bottom: 10px;
`;

const FieldRow = styled(Row)`
  margin-bottom: 0.8em;
`;

const ButtonRow = styled.div`
  button {
    height: 40px;
    padding: 0.375rem 1rem;
    margin: 4px 8px 0px 8px;
  }
`;

const GroupBtn = styled(Row)`
  margin-right: 0.5em;
  button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-color: hsla(0, 0%, 100%, 0.5);
    margin-top: 16px;
  }
  button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-top: 16px;
  }
  button {
    margin: 0;
    display: flex;
    svg {
      fill: #fff;
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }
  }
`;
const BtnRow = styled(Row)`
  margin-top: 16px;
  button {
    margin-right: 0.5em;
  }
`;
const DisplayFilter = styled(Row)`
  color: #409eff;
  cursor: pointer;
`;

function filterQuestions(history, location, query, newFilter, newOrder) {
  const { order, page, filter = "{}", ...other } = query;
  let newQuery = {
    ...other,
    order: newOrder || order,
    page: 1,
    filter: JSON.stringify({
      ...JSON.parse(filter),
      ...newFilter,
    }),
  };
  history.push(location.pathname + "?" + queryString.stringify(newQuery));
}

function viewQuestion(question) {
  Dialog.show(
    <Popup title="查看题目">
      <QuestionDetail question={question} />
    </Popup>,
    {
      buttons: false,
      className: "dialog",
    }
  );
}

function viewHistory(question) {
  Dialog.show(
    <Popup title="组卷历史记录">
      <QuestionHistory question={question} />
    </Popup>,
    {
      buttons: false,
      className: "dialog",
    }
  );
}

async function addGroupQuestion(questionId) {
  if (hasPermission("组题按钮")) {
    const success = await ApiCaller.questionCartAdd("question", questionId);
    if (success) {
      dataStore.state.questionGroupCart = await ApiCaller.questionCartFetch("question");
      await Toast.success("添加到题组中心成功！");
    }
  } else {
    Alert.warning("没有组题的权限!");
  }
}

async function addQuestion(questionId) {
  if (hasPermission("组卷按钮")) {
    const success = await ApiCaller.questionCartAdd("exam", questionId);
    if (success) {
      dataStore.state.questionCart = await ApiCaller.questionCartFetch("exam");
      await Toast.success("添加到组卷中心成功！");
    }
  } else {
    Alert.warning("没有组卷的权限!");
  }
}

async function deleteQuestion(questionId, reload) {
  if (hasPermission("删除题目")) {
    const ok = await Alert.warning("确认要删除？", { buttons: ["否", "是"] });
    if (ok === true) {
      const result = await ApiCaller.deleteQuestion(questionId);
      if (result.status == 200) {
        reload();
        dataStore.state.deletedCount += 1;
        Alert.info("删除成功");
      } else {
        Alert.warning(result.data);
      }
    }
  } else {
    Alert.warning("没有删除题目的权限!");
  }
}

const CategoryTree = styled(Col)`
  padding: 0.5em;
  border: 1px solid #ebeef5;
  width: 230px;
`;
const QuestionColumn = styled(Col)`
  width: calc(100% - 240px);
`;

const TreeNode = styled.span`
  padding: 0.1em 0.8em;
  ${p =>
    p.selected &&
    css`
      background-color: #409eff;
      color: #fff;
    `};
`;

const VideoStatusFilter = styled(DropdownList)`
  ${p =>
    p.margin &&
    css`
      margin: ${p => p.margin};
    `};
  height: 36px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  color: #606266;
  background: #fff;
`;

const OrderRow = styled(Row)`
  background: #f5f5f5;
  border: 1px solid #ebeff6;
  height: 50px;
  button {
    font-family: MicrosoftYaHei;
    font-size: 17px;
    color: #2d353a;
    border: none;
    background: transparent;
  }
`;

const QuestionList = props => {
  const { history, location, query, loading, data } = props;
  const [displayAllFilter, setDisplayAllFilter] = useState(false);
  const filter = JSON.parse(query.filter || "{}");
  if (data == null) return null;
  const order = query.order || "-updatedAt";
  const reload = () => {
    history.push(location.pathname + "?" + queryString.stringify(query));
  };
  const tagOptions = createOptions(questionTags).concat({
    label: "未归类",
    value: null,
  });
  return (
    <PageFrame
      title="题目管理"
      loading={loading}
      actions={
        <Row valign="middle">
          <GroupBtn>
            <Button primary>
              <Svg src={require("../resources/icons/documentation.svg")} />
              {dataStore.state.questionGroupCart && dataStore.state.questionGroupCart.length}
            </Button>
            <Button primary onClick={() => history.push("/groupQuestion/edit")}>
              题组中心
            </Button>
          </GroupBtn>
          <GroupBtn>
            <Button primary>
              <Svg src={require("../resources/icons/documentation.svg")} />
              {dataStore.state.questionCart && dataStore.state.questionCart.length}
            </Button>
            <Button primary onClick={() => history.push("/examPaper/edit")}>
              组卷中心
            </Button>
          </GroupBtn>
          <BtnRow>
            {hasPermission("新增题目") && (
              <Link to={`/question/edit`}>
                <Button primary>新建题目</Button>
              </Link>
            )}
            <Link to={`/question/deleted`}>
              <Button default>
                回收站(
                {dataStore.state.deletedCount})
              </Button>
            </Link>
          </BtnRow>
        </Row>
      }
    >
      <SearchBox>
        <SearchInput
          width="300px"
          margin="0 0.5rem 0 0"
          placeholder="请输入题目ID进行搜索"
          defaultValue={filter.id}
          onEnter={value =>
            filterQuestions(history, location, query, {
              id: value,
            })
          }
        />
        <SearchInput
          width="300px"
          margin="0 0.5rem 0 0"
          placeholder="请输入题干关键字对题目进行搜索"
          defaultValue={filter.keyword}
          onEnter={value =>
            filterQuestions(history, location, query, {
              keyword: value,
            })
          }
        />
        <VideoStatusFilter
          firstSelected={true}
          defaultValue={filter.videoStatus}
          options={[
            { label: "视频状态-不限", value: "all" },
            { label: "视频状态-已上传", value: "yes" },
            { label: "视频状态-未上传", value: "no" },
          ]}
          onChange={value =>
            filterQuestions(history, location, query, {
              videoStatus: value,
            })
          }
        />
      </SearchBox>
      <FilterBox>
        <FieldRow nowrap valign="middle">
          <label>年份：</label>
          <SearchInput
            width="240px"
            margin="0 0.5rem 0 0"
            placeholder="请输入年份对题目来源搜索"
            defaultValue={filter.year}
            onEnter={value =>
              filterQuestions(history, location, query, {
                year: value,
              })
            }
          />
          <label>地区：</label>
          <SearchInput
            width="240px"
            margin="0 0.5rem 0 0"
            placeholder="请输入地区对题目来源搜索"
            defaultValue={filter.area}
            onEnter={value =>
              filterQuestions(history, location, query, {
                area: value,
              })
            }
          />
          <label>试卷名：</label>
          <SearchInput
            width="260px"
            margin="0 0.5rem 0 0"
            placeholder="请输入试卷名对题目来源搜索"
            defaultValue={filter.paper}
            onEnter={value =>
              filterQuestions(history, location, query, {
                paper: value,
              })
            }
          />
        </FieldRow>
        <FieldRow valign="middle">
          <label>状态：</label>
          <MultiSelector
            selectAll="全选"
            options={createOptions(statuses)}
            values={filter.statuses}
            onChange={values => filterQuestions(history, location, query, { statuses: values })}
          />
        </FieldRow>
        <FieldRow valign="middle">
          科目：
          <MultiSelector
            selectAll="全选"
            options={createOptions(AppState.subjects)}
            values={filter.subjects}
            onChange={values => filterQuestions(history, location, query, { subjects: values })}
          />
        </FieldRow>
        <FieldRow valign="middle">
          年级：
          <MultiSelector
            selectAll="全选"
            options={createOptions(grades)}
            values={filter.grades}
            onChange={values => filterQuestions(history, location, query, { grades: values })}
          />
        </FieldRow>
        <FieldRow valign="middle">
          类型：
          <MultiSelector
            selectAll="全选"
            options={createOptions(questionTypes)}
            values={filter.types}
            onChange={values => filterQuestions(history, location, query, { types: values })}
          />
        </FieldRow>
        <FieldRow valign="middle">
          难度：
          <MultiSelector
            selectAll="全选"
            options={createOptions(difficulties)}
            values={filter.difficulties}
            onChange={values =>
              filterQuestions(history, location, query, {
                difficulties: values,
              })
            }
          />
        </FieldRow>
        {displayAllFilter && (
          <div>
            <FieldRow valign="middle">
              题类：
              <MultiSelector
                selectAll="全选"
                options={createOptions(questionFeatures)}
                values={filter.features}
                onChange={values => filterQuestions(history, location, query, { features: values })}
              />
            </FieldRow>
            <FieldRow valign="middle">
              数学思想：
              <MultiSelector
                selectAll="全选"
                options={createOptions(questionIdeologies)}
                values={filter.ideologies}
                onChange={values =>
                  filterQuestions(history, location, query, { ideologies: values })
                }
              />
            </FieldRow>
            <FieldRow valign="middle">
              归类：
              <MultiSelector
                selectAll="全选"
                options={tagOptions}
                values={filter.tags}
                onChange={values => filterQuestions(history, location, query, { tags: values })}
              />
            </FieldRow>
          </div>
        )}
        <DisplayFilter
          align="center"
          valign="middle"
          onClick={() => setDisplayAllFilter(!displayAllFilter)}
        >
          {displayAllFilter ? (
            <>
              收起
              <img
                style={{ transform: "rotate(180deg)" }}
                src={require("../resources/images/upArrowBlue.png")}
              />
            </>
          ) : (
            <>
              更多
              <img src={require("../resources/images/upArrowBlue.png")} />
            </>
          )}
        </DisplayFilter>
      </FilterBox>
      <Row space="between">
        <CategoryTree>
          <Tree
            paddingLeft={20}
            draggable={false}
            tree={{
              name: "所有分类",
              children:
                filter.subjects && filter.subjects.length > 0
                  ? _.filter(_.flatten(_.values(AppState.categoryTrees)), cat =>
                      filter.subjects.includes(cat.subject)
                    )
                  : _.flatten(_.values(AppState.categoryTrees)),
            }}
            renderNode={node => (
              <TreeNode
                selected={_.includes(filter.categories, [node.path, node.id].join(""))}
                onMouseDown={e => e.stopPropagation()}
                onClick={() => {
                  filterQuestions(history, location, query, {
                    categories: [[node.path, node.id].join("")],
                  });
                }}
              >
                {node.name}
              </TreeNode>
            )}
          />
        </CategoryTree>
        <QuestionColumn>
          <OrderRow valign="middle">
            <DropdownButton
              title={orderName[order] || "排序规则"}
              onSelect={newOrder => {
                filterQuestions(history, location, query, {}, newOrder);
              }}
            >
              <MenuItem eventKey="updatedAt" active={order == "updatedAt"}>
                时间升序
              </MenuItem>
              <MenuItem eventKey="-updatedAt" active={order == "-updatedAt"}>
                时间降序
              </MenuItem>
              <MenuItem eventKey="diffCoefficient" active={order == "diffCoefficient"}>
                难度升序
              </MenuItem>
              <MenuItem eventKey="-diffCoefficient" active={order == "-diffCoefficient"}>
                难度降序
              </MenuItem>
            </DropdownButton>
          </OrderRow>
          {data.questions.map(question => (
            <QuestionItem
              key={question.id}
              question={question}
              buttons={
                <ButtonRow>
                  <Button
                    primary
                    onClick={() => addGroupQuestion(question.id)}
                    disabled={_.includes(dataStore.state.questionGroupCart, question.id)}
                  >
                    组题
                  </Button>
                  <Button
                    primary
                    onClick={() => addQuestion(question.id)}
                    disabled={_.includes(dataStore.state.questionCart, question.id)}
                  >
                    组卷
                  </Button>
                  {(AppState.account.isAdmin ||
                    hasPermission("编辑已答题目") ||
                    ((question.studentCount == null || question.studentCount == 0) &&
                      hasPermission("编辑题目"))) && (
                    <Link to={`/question/edit?id=${question.id}`}>
                      <Button primary>编辑</Button>
                    </Link>
                  )}
                  <Button primary onClick={() => viewQuestion(question)}>
                    查看
                  </Button>
                  <Button primary onClick={() => viewHistory(question)}>
                    历史
                  </Button>
                  <Button danger onClick={() => deleteQuestion(question.id, reload)}>
                    删除
                  </Button>
                </ButtonRow>
              }
            />
          ))}
          <Pager
            history={history}
            location={location}
            query={query}
            total={data.total}
            pageSize={DefaultPageSize}
            pageSizes={[10, 20, 30]}
          />
        </QuestionColumn>
      </Row>
    </PageFrame>
  );
};

export default compose(
  parseQuery,
  fetchData({
    url: p => "/questions/fetch",
    config: p => {
      const query = {
        page: 1,
        size: DefaultPageSize,
        filter: JSON.stringify({
          subjects: createOptions(AppState.subjects).map(subject => subject.value),
        }),
        order: "-updatedAt",
        ...p.query,
      };
      return {
        method: "POST",
        data: {
          isGroup: false,
          ...JSON.parse(query.filter),
          order: query.order,
          currentPage: query.page - 1 || query.currentPage,
          pageSize: query.size || DefaultPageSize,
          skip: (query.currentPage - 1) * query.pageSize,
          limit: query.pageSize,
        },
      };
    },
    extract: result => result.data,
  }),
  didMount(async p => {
    dataStore.state.deletedCount = await ApiCaller.deletedQuestionCount();
    dataStore.state.questionCart = await ApiCaller.questionCartFetch("exam");
    dataStore.state.questionGroupCart = await ApiCaller.questionCartFetch("question");
  }),
  view
)(QuestionList);
