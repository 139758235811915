import React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Row, Col } from "../components/FlexboxGrid";
import Button from "../components/Button";
import PaperPreview from "../widgets/PaperPreview";
import compose from "../utils/compose";
import parseQuery from "../enhancers/parseQuery";
import fetchData from "../enhancers/fetchData";
import ApiCaller from "../utils/ApiCaller";
import DataStore from "../utils/DataStore";
import { store, view } from "react-easy-state";

const dataStore = new DataStore({});

const Page = styled(Col)`
  padding: 0 20px 20px 20px;
`;

const Header = styled(Row)`
  padding: 10px 0;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 10px;
  h3 {
    margin: 0;
  }
  button {
    margin-left: 1em;
  }
  @media print {
    display: none !important;
  }
`;

const ExamPaperPreview = ({ history, data }) => {
  if (dataStore.state.data == null) return null;
  const { paper, questions } = dataStore.state.data;
  return (
    <Page>
      <Header valign="middle" space="between">
        <h3>试卷预览</h3>
        <Row>
          {paper.submitCnt == 0 && (
            <Link to={`/examPaper/edit?id=` + paper.id}>
              <Button primary>编辑</Button>
            </Link>
          )}
          <Button primary onClick={() => print()}>
            打印
          </Button>
          {history.length > 1 && (
            <Button default onClick={() => history.goBack()}>
              返回
            </Button>
          )}
        </Row>
      </Header>
      <PaperPreview examPaper={paper} questions={questions} />
    </Page>
  );
};

export default compose(
  parseQuery,
  fetchData({
    url: p => "/examinationpapers/list",
    config: p => {
      return {
        method: "POST",
        data: {
          ids: [p.query.id],
        },
      };
    },
    extract: async result => {
      const paper = result.data[0];
      const fetchedQuestions = await ApiCaller.fetchGroupQuestions(questions);
      dataStore.state.data = { paper, questions: fetchedQuestions };
    },
  }),
  view
)(ExamPaperPreview);
