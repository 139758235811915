import React from "react";
import styled from "styled-components";
import { Row, Col } from "../components/FlexboxGrid";
import Space from "../components/Space";

const Preview = styled(Col)`
  padding: 0 10px;
  .header {
    padding: 10px 0;
    margin-bottom: 10px;
    h3 {
      margin: 0;
    }
  }
  .grid {
    right: 30px;
    position: absolute;
    td {
      border: 2px solid #000;
      text-align: center;
      width: 4em;
      padding: 4px;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    table {
      text-align: left;
    }
  }
  .question {
    text-align: left;
    page-break-inside: avoid;
    margin-top: 1em;

    .body,
    .option {
      display: flex;
      flex-direction: row;
      margin-top: 1em;
      line-height: 1.5;
      font-family: "SimHei";
      font-size: 16px;
    }
    .content {
      width: 100%;
      .col-1 {
        width: 100%;
        margin-right: 5px;
        margin-bottom: 5px;
      }
      .lineSpacing {
        margin-right: 2rem;
      }
      .questionBody {
        width: 100%;
      }
      .options {
        display: flex
        flex-direction: row;
        flex-wrap: wrap;
        .option1 {
          width: 100%;
        }
        .option2 {
          width: 50%;
        }        
        .option3 {
          width: 33%;          
        }       
        .option4 {
          width: 25%;          
        }       
      }
    }
  }
`;

const Group = styled(Row)`
  > div {
    width: ${p => 100 / p.questionsPerLine}%;
    word-break: break-all;
  }
`;

function renderSubQuestions(question) {
  if (question.type == "fillMulti" || question.type == "essayMulti") {
    return (
      <Row>
        {question.subQuestions.map((subQuestion, index) => (
          <Row nowrap key={index} className="col-1">
            <label>({index + 1})&nbsp;</label>
            <div
              dangerouslySetInnerHTML={{
                __html: subQuestion.body && subQuestion.body.html,
              }}
            />
          </Row>
        ))}
      </Row>
    );
  } else if (question.type == "group") {
    return (
      <Group questionsPerLine={question.questionsPerLine}>
        {question.subQuestions.map((groupQuestion, index) => (
          <Col key={index}>
            <Row nowrap className="lineSpacing">
              <Col>({index + 1})&nbsp;</Col>
              <Col shrink={1} className="questionBody">
                <div
                  dangerouslySetInnerHTML={{
                    __html: groupQuestion.body && groupQuestion.body.html,
                  }}
                />
                <div className="options">
                  {groupQuestion.options &&
                    groupQuestion.options.map(
                      option =>
                        option.value && (
                          <div className={"option" + question.optionsPerLine} key={option.label}>
                            <div className="option" key={option.label}>
                              <Col>{option.label}.</Col>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: option.value && option.value.html,
                                }}
                              />
                            </div>
                          </div>
                        )
                    )}
                </div>
                {groupQuestion.subQuestions &&
                  groupQuestion.subQuestions.map((subQuestion, index) => (
                    <Row nowrap key={index}>
                      <Col>{String.fromCharCode(97 + index)})&nbsp;</Col>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: subQuestion.body && subQuestion.body.html,
                        }}
                      />
                    </Row>
                  ))}
              </Col>
            </Row>
            <Space lines={groupQuestion.blankLine} />
          </Col>
        ))}
      </Group>
    );
  }
  return null;
}

function renderAnswer(question) {
  if (question.type == "fillMulti" || question.type == "essayMulti") {
    return (
      <div>
        {question.subQuestions.map((subQuestion, index) => (
          <Row nowrap key={index}>
            <Col>({index + 1})&nbsp;</Col>
            <Col shrink={1}>
              <Row nowrap>
                <Col>答案：</Col>
                {subQuestion.answer && subQuestion.answer.html ? (
                  <p
                    className="answer"
                    dangerouslySetInnerHTML={{
                      __html: subQuestion.answer && subQuestion.answer.html,
                    }}
                  />
                ) : (
                  subQuestion.correctOptions &&
                  subQuestion.correctOptions.sort()
                )}
              </Row>
              {subQuestion.analysis && subQuestion.analysis.html && (
                <Row nowrap>
                  <Col>解析：</Col>
                  <p
                    className="analysis"
                    dangerouslySetInnerHTML={{
                      __html: subQuestion.analysis.html,
                    }}
                  />
                </Row>
              )}
            </Col>
          </Row>
        ))}
      </div>
    );
  } else {
    return (
      <div>
        {question.subQuestions ? (
          question.subQuestions.map((subQuestion, index) =>
            subQuestion.subQuestions ? (
              <Row nowrap key={index}>
                <Col>({index + 1})&nbsp;</Col>
                <Col shrink={1}>
                  {subQuestion.subQuestions.map((subQuestion, index) => (
                    <Row nowrap key={index}>
                      <label>
                        {String.fromCharCode(97 + index)}
                        )&nbsp;
                      </label>
                      <div>
                        <Row nowrap>
                          <label>答案：</label>
                          {subQuestion.answer && subQuestion.answer.html ? (
                            <p
                              className="answer"
                              dangerouslySetInnerHTML={{
                                __html:
                                  subQuestion.answer && subQuestion.answer.html,
                              }}
                            />
                          ) : (
                            subQuestion.correctOptions &&
                            subQuestion.correctOptions.sort()
                          )}
                        </Row>
                        {subQuestion.analysis && subQuestion.analysis.html && (
                          <Row nowrap>
                            <label>解析：</label>
                            <p
                              className="analysis"
                              dangerouslySetInnerHTML={{
                                __html: subQuestion.analysis.html,
                              }}
                            />
                          </Row>
                        )}
                      </div>
                    </Row>
                  ))}
                </Col>
              </Row>
            ) : (
                <Row nowrap key={index}>
                  <Col>({index + 1})&nbsp;</Col>
                  <Col shrink={1}>
                    <Row nowrap>
                      <Col>答案：</Col>
                      {subQuestion.answer && subQuestion.answer.html ? (
                        <p
                          className="answer"
                          dangerouslySetInnerHTML={{
                            __html: subQuestion.answer && subQuestion.answer.html,
                          }}
                        />
                      ) : (
                          subQuestion.correctOptions &&
                          subQuestion.correctOptions.sort()
                        )}
                    </Row>
                    {subQuestion.analysis && subQuestion.analysis.html && (
                      <Row nowrap>
                        <Col>解析：</Col>
                        <p
                          className="analysis"
                          dangerouslySetInnerHTML={{
                            __html: subQuestion.analysis.html,
                          }}
                        />
                      </Row>
                    )}
                  </Col>
                </Row>
              )
          )
        ) : (
            <Col>
              <Row nowrap>
                <Col>答案：</Col>
                {question.answer && question.answer.html ? (
                  <p
                    className="answer"
                    dangerouslySetInnerHTML={{
                      __html: question.answer && question.answer.html,
                    }}
                  />
                ) : (
                    question.correctOptions && question.correctOptions.sort()
                  )}
              </Row>
              {question.analysis && question.analysis.html && (
                <Row nowrap>
                  <Col>解析：</Col>
                  <p
                    className="analysis"
                    dangerouslySetInnerHTML={{
                      __html: question.analysis.html,
                    }}
                  />
                </Row>
              )}
            </Col>
          )}
      </div>
    );
  }
}

const QuestionPreview = ({ question }) => {
  return (
    <Preview>
      <div className="header">
        <h3>试卷标题</h3>
        <table className="grid">
          <tbody>
            <tr>
              <td>星数</td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row">
        <table>
          <tbody>
            <tr>
              <td>年级：--</td>
              <td>　课次：--</td>
              <td>　　精练老师：--</td>
            </tr>
            <tr>
              <td colSpan="2">线下精练课校区：--</td>
              <td>时间：--</td>
            </tr>
            <tr>
              <td>学生姓名：--</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="question">
        <div className="body">
          <span>1．</span>
          <div className="content">
            <div
              dangerouslySetInnerHTML={{
                __html: question.body && question.body.html,
              }}
            />
            <div className="options">
              {renderSubQuestions(question)}
              {question.options &&
                question.options.map(
                  option =>
                    option.value && (
                      <div className={'option' + question.optionsPerLine} key={option.label}>
                        <div className="option" key={option.label}>
                          <label>{option.label}.</label>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: option.value && option.value.html,
                            }}
                          />
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
        <Space lines={2} />
        {question.groupQuestions == null && renderAnswer(question)}
      </div>
    </Preview>
  )
};

export default QuestionPreview;
