import React from "react";
import styled from "styled-components";
import _ from "lodash";

const Button = styled.span`
  cursor: pointer;
`;

export default class ToggleButton extends React.Component {
  constructor(props) {
    super(props);
    this.values = _.keys(props.children);
    this.state = {
      index: this.values.indexOf(props.value),
      value: props.value,
    };
  }

  toggleState = async () => {
    let index = this.state.index + 1;
    if (index == this.values.length) {
      index = 0;
    }
    const value = this.values[index];
    if (this.props.onClick) {
      const result = await this.props.onClick(value);
      if (result === false) return;
    }
    this.setState({ index, value });
  };

  render() {
    return (
      <Button className="toggle-button" onClick={this.toggleState}>
        {this.props.children[this.state.value]}
      </Button>
    );
  }
}
