import React, { useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import { store, view } from "react-easy-state";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { Col, Row } from "../components/FlexboxGrid";
import Button from "../components/Button";
import TextBox from "../components/TextBox";
import Selector, { createOptions } from "../components/Selector";
import MultiSelector from "../components/MultiSelector";
import LatexEditor from "../components/LatexEditor";
import Toast from "../components/Toast";
import PageFrame from "../widgets/PageFrame";
import QuestionOptions from "../widgets/QuestionOptions";
import compose from "../utils/compose";
import parseQuery from "../enhancers/parseQuery";
import didMount from "../enhancers/didMount";
import unMount from "../enhancers/unMount";
import DataStore from "../utils/DataStore";
import CheckBoxList from "../components/CheckBoxList";
import RadioButtonList from "../components/RadioButtonList";
import ValidationError from "../components/ValidationError";
import Dialog from "../components/Dialog";
import Popup from "../widgets/Popup";
import Alert from "../components/Alert";
import LinkButton from "../components/LinkButton";
import QuestionPreview from "../widgets/QuestionPreview";
import {
  statuses,
  grades,
  questionTypes,
  questionTags,
  questionFeatures,
  questionIdeologies,
  difficulties,
  blankLines,
} from "../utils/constants";
import {
  choiceQuestionSchema,
  answerQuestionSchema,
  moreQuestionSchema,
} from "../utils/questionSchema";
import ApiCaller from "../utils/ApiCaller";
import AppState from "../AppState";
import config from "../../config";
import hasPermission from "../utils/hasPermission";
import DropdownList from "../components/DropdownList";
import { notEqual } from "assert";

const EmptyOptions = [
  {
    label: "A",
    value: { content: "" },
  },
  {
    label: "B",
    value: { content: "" },
  },
  {
    label: "C",
    value: { content: "" },
  },
  {
    label: "D",
    value: { content: "" },
  },
];

const BlankSubQuestions = [
  {
    categories: [],
    body: { content: "", html: "", imgs: [] },
    answer: { content: "", html: "", imgs: [] },
    analysis: { content: "", html: "", imgs: [] },
  },
];

const DelQuestionBtn = styled.label`
  position: absolute;
  right: 60px;
  font-size: 14px;
  color: red;
  cursor: pointer;
`;

const TreeVerticalLine = styled(Col)`
  border-left: 4px solid #ddd;
`;

const TreeHorizontalLine = styled(Row)`
  width: 30px;
  height: 4px;
  background: #ddd;
`;

const SplitLine = styled.hr`
  border-top: 1px solid #ddd;
  width: 100%;
  margin-bottom: 0.8em;
`;

const QuestionCard = styled(Col)`
  border: 1px solid #ddd;
  background: rgba(140, 209, 247, 0.3);
  padding: 10px 20px;
`;

const FieldRow = styled(Row)`
  margin-bottom: 0.8em;
  label {
    margin-bottom: 0;
    white-space: nowrap;
  }
  .inlineBox {
    margin-right: 1em;
  }
  .numberBox {
    width: 9em;
    text-align: center;
  }
  .blankLine {
    width: 5em;
    text-align: center;
    margin-right: 0.3em;
  }
`;

const SubQuestions = styled(Row)`
  width: 100%;
  .subQuestion {
    align-items: center;
    width: 100%;
    margin-top: 20px;
    .subQuestionCard {
      width: calc(100% - 34px);
    }
  }
`;

const ButtonRow = styled(Row)`
  justify-content: flex-end;
  padding: 0 1em 1em;
  button {
    padding: 0.375rem 1rem;
    margin: 4px 8px 0px 8px;
  }
`;

const VideoRow = styled(Col)`
  display: flex;
  width: calc(100% - 6em);
  .video {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    margin-bottom: 0.5em;
    span,
    .imgDiv {
      width: calc(100% - 6em);
      cursor: pointer;
      color: black;
    }
    button {
      width: 6em;
    }
  }
  .buttonDiv {
    display: flex;
    flex-direction: column;
    button {
      width: 100px;
      margin-bottom: 10px;
      padding: 0.375rem 1rem;
      margin: 4px 8px 0px 8px;
    }
    .prompt {
      color: red;
      font-size: 12px;
    }
  }
`;
const DisplayFilter = styled(Row)`
  justify-content: center;
  color: #409eff;
`;

const Select = styled.select`
  width: 5.5rem;
  padding: 0;
  text-align: center;
`;

const dataStore = new DataStore({ changed: false });

async function saveQuestion(history) {
  const type = dataStore.state.data.type;
  let questionSchema =
    type == "choice" || type == "multichoice"
      ? choiceQuestionSchema
      : type == "fill" || type == "essay"
      ? answerQuestionSchema
      : moreQuestionSchema;
  try {
    dataStore.state.errors = {};
    const question = await questionSchema.validate(dataStore.state.data);
    if (question.subQuestions && question.subQuestions.length < 2) {
      Alert.error("最少要2个小问");
      return;
    }
    if (question.id) {
      const isUsed = await ApiCaller.questionIsUsed(question.id);
      if (isUsed != "none") {
        const ok = await Alert.warning(
          `该题目已被${isUsed == "paper" ? "组卷" : "组成题组"}，是否要继续保存？`,
          {
            buttons: ["取消", "保存"],
          }
        );
        if (ok !== true) {
          return;
        }
      }
    }
    const savedQuestion = await ApiCaller.createOrUpdateQuestion(question);
    if (savedQuestion) {
      dataStore.state.data.id = savedQuestion.id;
      await Toast.info("保存成功");
      history.goBack();
    } else {
      Toast.error("保存出错");
    }
  } catch (errors) {
    console.log(errors);
    dataStore.state.errors = _.keyBy(errors, "param");
  }
}

const QuestionEditor = props => {
  const { history, location, query, loading } = props;
  const [displayAllFilter, setDisplayAllFilter] = useState(false);
  if (dataStore.state.data == null) return null;
  const {
    from,
    status,
    subject,
    grade,
    type,
    tags,
    features,
    ideologies,
    difficulty,
    score,
    blankLine,
    body,
    subQuestion,
    options,
    correctOptions,
    answer,
    analysis,
    categories,
    subcategories,
  } = dataStore.state.data;
  const errors = dataStore.state.errors;
  return (
    <PageFrame title={dataStore.state.title} loading={loading}>
      <FieldRow valign="middle">
        <label>年份：</label>
        <TextBox
          type="number"
          className="inlineBox numberBox"
          value={from.year}
          min={0}
          placeholder="题目来源年份"
          onChange={year => (dataStore.state.data.from.year = year)}
        />
        <label>地区：</label>
        <TextBox
          className="inlineBox"
          value={from.area}
          placeholder="题目来源地区"
          onChange={area => (dataStore.state.data.from.area = area)}
        />
        <label>试卷名：</label>
        <TextBox
          className="inlineBox"
          value={from.paper}
          placeholder="题目来源试卷名"
          onChange={paper => (dataStore.state.data.from.paper = paper)}
        />
        <label>题号：</label>
        <TextBox
          type="number"
          className="numberBox"
          value={from.number}
          min={0}
          placeholder="题目来源题号"
          onChange={number => (dataStore.state.data.from.number = number)}
        />
      </FieldRow>
      <FieldRow valign="middle">
        <label>状态：</label>
        <Selector
          name="status"
          options={createOptions(statuses)}
          value={status}
          onChange={option => (dataStore.state.data.status = option.value)}
        />
        {errors["status"] && <ValidationError>{errors["status"].message}</ValidationError>}
      </FieldRow>
      <FieldRow valign="middle">
        <label>科目：</label>
        <Selector
          name="subject"
          options={createOptions(AppState.subjects)}
          value={subject}
          onChange={option => (dataStore.state.data.subject = option.value)}
        />
        {errors["subject"] && <ValidationError>{errors["subject"].message}</ValidationError>}
      </FieldRow>
      <FieldRow nowrap>
        <label>年级：</label>
        <Selector
          name="grade"
          options={createOptions(grades)}
          value={grade}
          onChange={option => (dataStore.state.data.grade = option.value)}
        />
      </FieldRow>
      {errors["grade"] && (
        <ValidationError className="newline">{errors["grade"].message}</ValidationError>
      )}
      <FieldRow valign="middle">
        <label>类型：</label>
        <Selector
          name="type"
          options={createOptions(questionTypes)}
          value={type}
          onChange={option => {
            dataStore.state.data.type = option.value;
            dataStore.state.data.blankLine = blankLines[option.value];
            if (option.value == "choice" || option.value == "multichoice") {
              dataStore.state.data.answer = { content: "" };
              dataStore.state.data.analysis = { content: "" };
              if (dataStore.state.data.options == null) {
                dataStore.state.data.options = _.cloneDeep(EmptyOptions);
              }
              if (option.value == "choice") {
                dataStore.state.data.correctOptions = _.take(correctOptions);
              }
            } else if (option.value == "fillMulti" || option.value == "essayMulti") {
              dataStore.state.data.options = null;
              if (dataStore.state.data.subQuestions == null) {
                dataStore.state.data.subQuestions = _.cloneDeep(BlankSubQuestions);
              }
            } else {
              dataStore.state.data.options = null;
              dataStore.state.data.subQuestions = null;
              if (dataStore.state.data.answer == null) {
                dataStore.state.data.answer = { content: "" };
              }
            }
          }}
        />
        {errors["type"] && <ValidationError>{errors["type"].message}</ValidationError>}
      </FieldRow>
      <FieldRow valign="middle">
        <label>难度：</label>
        <Selector
          name="difficulty"
          checkType="doubleCancel"
          options={createOptions(difficulties)}
          value={difficulty}
          onChange={option => (dataStore.state.data.difficulty = option.value)}
        />
        {errors["difficulty"] && <ValidationError>{errors["difficulty"].message}</ValidationError>}
      </FieldRow>
      {displayAllFilter && (
        <div>
          <FieldRow nowrap>
            <label>题类：</label>
            <MultiSelector
              name="features"
              options={createOptions(questionFeatures)}
              values={features || []}
              onChange={values =>
                (dataStore.state.data.features = _.isEmpty(values) ? null : values)
              }
            />
          </FieldRow>
          <FieldRow nowrap>
            <label>数学思想：</label>
            <MultiSelector
              name="ideologies"
              options={createOptions(questionIdeologies)}
              values={ideologies || []}
              onChange={values =>
                (dataStore.state.data.ideologies = _.isEmpty(values) ? null : values)
              }
            />
          </FieldRow>
          <FieldRow nowrap>
            <label>归类：</label>
            <MultiSelector
              name="tags"
              options={createOptions(questionTags)}
              values={tags || []}
              onChange={values => (dataStore.state.data.tags = _.isEmpty(values) ? null : values)}
            />
          </FieldRow>
        </div>
      )}
      <DisplayFilter onClick={() => setDisplayAllFilter(!displayAllFilter)}>
        {displayAllFilter ? (
          <div>
            收起
            <img
              style={{ transform: "rotate(180deg)" }}
              src={require("../resources/images/upArrowBlue.png")}
            />
          </div>
        ) : (
          <div>
            更多
            <img src={require("../resources/images/upArrowBlue.png")} />
          </div>
        )}
      </DisplayFilter>
      <SplitLine />
      {type != "fillMulti" && type != "essayMulti" && (
        <>
          <FieldRow valign="middle">
            <label>知识点：</label>
            <input type="hidden" value={dataStore.state.changed} />
            <DropdownTreeSelect
              placeholderText="请选择知识点"
              data={dataStore.state.categoryTree[subject]}
              onChange={onCategorySelect}
            />
            {errors["categories"] && (
              <ValidationError>{errors["categories"].message}</ValidationError>
            )}
            {/* <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <label>题目分数：</label>
            <TextBox
              type="number"
              className="blankLine"
              value={score}
              min={0}
              onChange={score => (dataStore.state.data.score = score)}
            />
            分 */}
          </FieldRow>
          <FieldRow>
            <label>题干：</label>
            <LatexEditor
              placeholder="请输入题干"
              latex={body.content}
              images={body.imgs}
              isValid={errors["body.content"] === undefined}
              onChange={content => (dataStore.state.data.body.content = content)}
              onImageChange={images => (dataStore.state.data.body.imgs = images)}
              onHtmlChange={html => (dataStore.state.data.body.html = html)}
            />
          </FieldRow>
        </>
      )}
      {(type == "choice" || type == "multichoice") && (
        <>
          <FieldRow>
            <label>选项：</label>
            <QuestionOptions
              name="options"
              options={options}
              errors={errors}
              onChange={(index, content) => {
                dataStore.state.data.options[index].value.content = content;
              }}
              onImageChange={(index, images) => {
                dataStore.state.data.options[index].value.imgs = images;
              }}
              onHtmlChange={(index, html) =>
                (dataStore.state.data.options[index].value.html = html)
              }
            />
          </FieldRow>
          <FieldRow valign="middle">
            <label>答案：</label>
            {type == "choice" ? (
              <RadioButtonList
                options={["A", "B", "C", "D"]}
                value={correctOptions && correctOptions[0]}
                onChange={value => {
                  dataStore.state.data.correctOptions = [value];
                }}
              />
            ) : (
              <CheckBoxList
                options={["A", "B", "C", "D"]}
                value={correctOptions || []}
                onChange={options => {
                  dataStore.state.data.correctOptions = options;
                }}
              />
            )}
            {errors["correctOptions"] && (
              <ValidationError>{errors["correctOptions"].message}</ValidationError>
            )}
          </FieldRow>
        </>
      )}
      {(type == "fill" || type == "essay") && (
        <FieldRow>
          <label>答案：</label>
          <LatexEditor
            height="90px"
            placeholder="请输入题目的答案"
            latex={answer.content}
            images={answer.imgs}
            isValid={errors["answer.content"] === undefined}
            onChange={content => (dataStore.state.data.answer.content = content)}
            onImageChange={images => (dataStore.state.data.answer.imgs = images)}
            onHtmlChange={html => (dataStore.state.data.answer.html = html)}
          />
        </FieldRow>
      )}
      {type != "fillMulti" && type != "essayMulti" && (
        <FieldRow>
          <label>解析：</label>
          <LatexEditor
            height="90px"
            placeholder="请输入题目的解析"
            latex={analysis.content}
            images={analysis.imgs}
            onChange={content => (dataStore.state.data.analysis.content = content)}
            onImageChange={images => (dataStore.state.data.analysis.imgs = images)}
            onHtmlChange={html => (dataStore.state.data.analysis.html = html)}
          />
        </FieldRow>
      )}
      {(type == "fillMulti" || type == "essayMulti") && (
        <>
          <QuestionCard>
            <FieldRow valign="middle">
              <label>知识点：</label>
              <input type="hidden" value={dataStore.state.changed} />
              <DropdownTreeSelect
                placeholderText="请选择知识点"
                data={dataStore.state.categoryTree[subject]}
                onChange={onCategorySelect}
              />
              {errors["categories"] && (
                <ValidationError>{errors["categories"].message}</ValidationError>
              )}
              {/* <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <label>题目分数：</label>
              <TextBox
                type="number"
                className="blankLine"
                value={score}
                min={0}
                onChange={score => (dataStore.state.data.score = score)}
              />
              分 */}
            </FieldRow>
            <FieldRow>
              <label>主题干：</label>
              <LatexEditor
                placeholder="请输入题干"
                latex={body.content}
                images={body.imgs}
                isValid={errors["body.content"] === undefined}
                onChange={content => (dataStore.state.data.body.content = content)}
                onImageChange={images => (dataStore.state.data.body.imgs = images)}
                onHtmlChange={html => (dataStore.state.data.body.html = html)}
              />
            </FieldRow>
          </QuestionCard>
          <FieldRow nowrap>
            <TreeVerticalLine />
            <SubQuestions>
              {dataStore.state.data.subQuestions.map((question, index) => (
                <FieldRow nowrap className="subQuestion" key={index}>
                  <TreeHorizontalLine />
                  <QuestionCard className="subQuestionCard">
                    <FieldRow valign="middle">
                      <label>
                        第{index + 1}
                        小问-知识点：
                      </label>
                      <input type="hidden" value={dataStore.state.changed} />
                      <DropdownTreeSelect
                        placeholderText="请选择知识点"
                        data={deriveSubcategories(
                          dataStore.state.data.categories,
                          _.map(question.categories, "id")
                        )}
                        onChange={(currentNode, selectedNodes) =>
                          (question.categories = selectedNodes)
                        }
                      />
                      {errors[`subQuestions.${index}.categories`] && (
                        <ValidationError>
                          {errors[`subQuestions.${index}.categories`].message}
                        </ValidationError>
                      )}
                      {dataStore.state.data.subQuestions.length > 1 && (
                        <DelQuestionBtn onClick={() => delSubQuestion(index)}>
                          删除小问
                        </DelQuestionBtn>
                      )}
                    </FieldRow>
                    <FieldRow>
                      <label>题干：</label>
                      <LatexEditor
                        placeholder="请输入题干"
                        latex={question.body.content}
                        images={question.body.imgs}
                        isValid={errors[`subQuestions.${index}.body.content`] === undefined}
                        onChange={content => (question.body.content = content)}
                        onImageChange={images => (question.body.imgs = images)}
                        onHtmlChange={html => (question.body.html = html)}
                      />
                    </FieldRow>
                    <FieldRow>
                      <label>答案：</label>
                      <LatexEditor
                        height="90px"
                        placeholder="请输入题目的答案"
                        latex={question.answer.content}
                        images={question.answer.imgs}
                        isValid={errors["answer.content"] === undefined}
                        onChange={content => (question.answer.content = content)}
                        onImageChange={images => (question.answer.imgs = images)}
                        onHtmlChange={html => (question.answer.html = html)}
                      />
                    </FieldRow>
                    <FieldRow>
                      <label>解析：</label>
                      <LatexEditor
                        height="90px"
                        placeholder="请输入题目的解析"
                        latex={question.analysis.content}
                        images={question.analysis.imgs}
                        onChange={content => (question.analysis.content = content)}
                        onImageChange={images => (question.analysis.imgs = images)}
                        onHtmlChange={html => (question.analysis.html = html)}
                      />
                    </FieldRow>
                  </QuestionCard>
                </FieldRow>
              ))}
            </SubQuestions>
          </FieldRow>
          <LinkButton onClick={() => addSubQuestion()}>添加小问</LinkButton>
        </>
      )}
      <FieldRow valign="middle">
        <label>题目间隔：</label>
        <TextBox
          type="number"
          className="blankLine"
          value={blankLine}
          min={0}
          onChange={blankLine => (dataStore.state.data.blankLine = blankLine)}
        />
        行{errors["blankLine"] && <ValidationError>{errors["blankLine"].message}</ValidationError>}
        {(dataStore.state.data.type == "choice" || dataStore.state.data.type == "multichoice") && (
          <div style={{ marginLeft: "2rem" }}>
            <label>选项排版：</label>
            <Select
              value={dataStore.state.data.optionsPerLine}
              onChange={event => (dataStore.state.data.optionsPerLine = event.target.value)}
            >
              <option value="1">一行1题</option>
              <option value="2">一行2题</option>
              <option value="3">一行3题</option>
              <option value="4">一行4题</option>
            </Select>
          </div>
        )}
      </FieldRow>
      <SplitLine />
      <FieldRow>
        <label>视频讲解：</label>
        <VideoRow>
          <div className="video">
            {dataStore.state.data.videoFile ? (
              dataStore.state.liveCover ? (
                <div className="imgDiv">
                  <img
                    src={`${dataStore.state.liveCover}`}
                    onClick={() => playLive(dataStore.state.data.videoFile.ccvid)}
                  />
                  <span>&nbsp;ID: {dataStore.state.data.videoFile.ccvid}</span>
                </div>
              ) : (
                <span>视频已上传(转码中)</span>
              )
            ) : (
              <span>未上传</span>
            )}
            {dataStore.state.data.videoFile && (
              <Button primary onClick={() => deleteVideo(query.id)}>
                删除
              </Button>
            )}
          </div>
          {hasPermission("上传视频") && (
            <div className="buttonDiv">
              <iframe
                src={`${config.msLiveUrl}/uploadcc.html?categoryId=551F613735060026&eventId=${
                  dataStore.state.eventId
                }&token=${dataStore.state.token}`}
                style={{
                  border: "none",
                  opacity: 1,
                  width: "100%",
                  height: "100%",
                }}
              />
              <span className="prompt">(*请上传不大于500MB的文件，建议比例：16：9)</span>
            </div>
          )}
        </VideoRow>
      </FieldRow>
      <ButtonRow>
        <Button primary onClick={() => saveQuestion(history)}>
          保存
        </Button>
        <Button primary onClick={() => preview(dataStore.state.data)}>
          预览
        </Button>
        <Button primary onClick={() => history.goBack()}>
          返回
        </Button>
      </ButtonRow>
      <input type="hidden" defaultValue={dataStore.state.messages} />
    </PageFrame>
  );
};

function addSubQuestion() {
  if (dataStore.state.questionIsUsed) {
    Alert.error("该题组已被使用，不能增加小问");
    return;
  }
  dataStore.state.data.subQuestions.push({
    categories: [],
    body: { content: "", html: "", imgs: [] },
    answer: { content: "", html: "", imgs: [] },
    analysis: { content: "", html: "", imgs: [] },
  });
}

async function delSubQuestion(index) {
  if (dataStore.state.questionIsUsed) {
    Alert.error("该题组已被使用，不能删除小问");
    return;
  }
  const ok = await Alert.warning("确认要删除？", { buttons: ["否", "是"] });
  if (ok && dataStore.state.data.subQuestions.length > 1) {
    dataStore.state.data.subQuestions.splice(index, 1);
  }
}

function preview(question) {
  Dialog.show(<QuestionPreview question={question} />, {
    buttons: false,
    className: "previewQuestionDialog",
  });
}

async function deleteVideo(questionId) {
  const ok = await Alert.warning("确认要删除？", { buttons: ["否", "是"] });
  if (ok === true) {
    const result = await ApiCaller.deleteVideo(questionId);
    if (result.status == 200) {
      await Toast.info("删除成功");
      dataStore.state.data.videoFile = null;
      dataStore.state.liveCover = null;
    }
  }
}

function onCategorySelect(currentNode, selectedNodes) {
  dataStore.state.data.categories = selectedNodes.map(node => ({
    id: node.id,
    name: node.name,
    number: node.number,
    path: node.path ? node.path + node.id : "," + node.id,
  }));
  updateCategoryTree(
    dataStore.state.categoryTree[currentNode.subject],
    dataStore.state.data.categories
  );
}

function updateCategoryTree(categories, selectCategories, checked) {
  let expanded = false;
  for (let category of categories) {
    category.checked = checked || _.includes(_.map(selectCategories, "id"), category.id);
    expanded = expanded || category.checked;
    if (category.children && category.children.length > 0) {
      category.expanded = updateCategoryTree(category.children, selectCategories, category.checked);
    }
  }
  return expanded;
}

function deriveSubcategories(categories, selectCategories) {
  return categories.map(category => ({
    ...category,
    checked: _.includes(selectCategories, category.id),
  }));
}

async function playLive(ccvid) {
  dataStore.state.playLiveUrl = await ApiCaller.getPlayLiveUrl(ccvid);
  window.open(`${dataStore.state.playLiveUrl}`);
}

async function setVideoFile(e) {
  if (dataStore.state.data && e.data) {
    dataStore.state.data.videoFile = e.data;
    if (dataStore.state.data.videoFile.ccvid) {
      dataStore.state.liveCover = await ApiCaller.getLiveCover(
        dataStore.state.data.videoFile.ccvid
      );
    }
  }
}

export default compose(
  parseQuery,
  unMount(() => {
    window.removeEventListener("message", setVideoFile, false);
  }),
  didMount(async p => {
    window.addEventListener("message", setVideoFile, false);
    dataStore.state.eventId = new Date().getTime().toString();
    dataStore.state.token = await ApiCaller.getVideoUploadToken();
    dataStore.state.changed = false;
    dataStore.state.categoryTree = _.cloneDeep(AppState.categoryTrees);
    dataStore.state.errors = {};
    if (p.query.id) {
      dataStore.state.questionIsUsed = await ApiCaller.questionIsUsed(p.query.id);
      dataStore.state.data = null;
      dataStore.state.title = "编辑题目";
      dataStore.options = {
        url: "/questions/fetch",
        config: {
          method: "POST",
          data: {
            ids: [p.query.id],
          },
        },
        extract: result => {
          let question = result.data.questions[0];
          if (question.from == null) {
            question.from = {};
          }
          if (question.remark == null) {
            question.remark = { content: "" };
          }
          if (question.optionsPerLine == null) {
            question.optionsPerLine = 1;
          }
          return question;
        },
      };
      dataStore.loadData(async data => {
        let categories = dataStore.state.categoryTree[data.subject];
        updateCategoryTree(categories, data.categories);
        dataStore.state.data.videoFile = data.videoFile;
        dataStore.state.data.optionsPerLine = data.optionsPerLine;
        dataStore.state.changed = true;
        if (data.videoFile && data.videoFile.ccvid) {
          dataStore.state.liveCover = await ApiCaller.getLiveCover(data.videoFile.ccvid);
        }
      });
    } else {
      dataStore.state.questionIsUsed = false;
      dataStore.state.data = {
        from: {},
        status: "1",
        subject: "001", //数学
        type: "choice",
        body: { content: "" },
        answer: { content: "" },
        analysis: { content: "" },
        remark: { content: "" },
        options: _.cloneDeep(EmptyOptions),
        correctOptions: [],
        categories: [],
        tags: null,
        blankLine: blankLines["choice"],
        videoFile: null,
        subQuestions: null,
        optionsPerLine: 1,
      };
      dataStore.state.title = "新建题目";
    }
  }),
  view
)(QuestionEditor);
