const peg = require("pegjs");

const grammar = String.raw`
{
  function extractOptional(optional, index) {
    return optional ? optional[index] : null;
  }

  function extractList(list, index) {
    return list.map(function(element) { return element[index]; });
  }

  function buildList(head, tail, index) {
    return [head].concat(extractList(tail, index))
      .filter(function(element) { return element !== null; });
  }
}
start = space? commands:(command space?)* { return extractList(commands, 0); }
command
  = "\\begin" enviroment:argument options:options? args:argument* { return {type: 'begin', enviroment, options, args}; }
  / "\\end" enviroment:argument { return {type: 'end', enviroment}; }
  / "\\" name:[\\(){}\[\]] { return {type: 'command', name}; }
  / "\\" name:name options:options? args:argument* { return {type: 'command', name, options, args}; }
  / "$$" chars:[^$]+ "$$" { return {type: 'displaymath', content: chars.join('')}; }
  / "$" chars:[^$]+ "$" { return {type: 'math', content: chars.join('')}; }
  / chars: [^$\\]+ { return {type: 'text', content: chars.join('')}; }
options = "[" head:option tail:("," option)* "]" { return buildList(head, tail, 1); }
option = name:name value:("=" optValue)? { return {name, value: extractOptional(value, 1)}; }
argument = "{" value:argValue "}" { return value; }
name = chars:[A-Za-z]+ { return chars.join(''); }
optValue = chars:[^,\[\]]+ { return chars.join(''); }
argValue = chars:[^{}]+ { return chars.join(''); }
      / cmd:command {return cmd;}
space = [ \t\n\r]*
`;

const parser = peg.generate(grammar);

function parseLatex(latex) {
  const result = parser.parse(latex);
  let currentEnviroment = {
    type: "enviroment",
    name: "root",
    commands: [],
  };
  const enviroments = [currentEnviroment];
  for (const command of result) {
    switch (command.type) {
      case "begin":
        const newEnviroment = {
          type: "enviroment",
          name: command.enviroment,
          commands: [],
        };
        currentEnviroment.commands.push(newEnviroment);
        currentEnviroment = newEnviroment;
        enviroments.push(currentEnviroment);
        break;
      case "end":
        if (currentEnviroment.name !== command.enviroment) {
          throw new Error(
            `end #{command.enviroment} doesn't match previous begin.`
          );
        }
        enviroments.pop();
        currentEnviroment = enviroments[enviroments.length - 1];
        break;
      default:
        currentEnviroment.commands.push(command);
        break;
    }
  }
  return currentEnviroment;
}

// module.exports = parseLatex;
export default parseLatex;
