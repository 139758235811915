import React from "react";
import styled from "styled-components";
import Svg from "react-svg";
import { Col } from "../components/FlexboxGrid";

const Frame = styled(Col)`
  padding: 100px;
  svg {
    with: 100px;
    height: 100px;
    fill: #212529;
  }
`;

const Loading = (
  <Frame align="center">
    <Svg src={require("../resources/images/loading.svg")} />
  </Frame>
);

export default Loading;
