export default class HashMap {
  constructor(defaultValue) {
    this.defaultValue = defaultValue;
    this.data = new Map();

    return new Proxy(this, {
      get(target, key) {
        if (key === "keys") {
          return () => Array.from(target.data.keys());
        }
        if (target.data.has(key)) {
          return target.data.get(key);
        } else {
          return target.defaultValue;
        }
      },
      set(target, key, value) {
        target.data.set(key, value);
        return true;
      },
    });
  }
}
