import React, { Component } from "react";

const unMount = callback => Comp => {
  return class unMount extends Component {
    componentWillUnmount() {
      callback(this.props);
    }

    render() {
      return <Comp {...this.props} />;
    }
  };
};

export default unMount;
