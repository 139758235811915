import React from "react";
import { store, view } from "react-easy-state";
import _ from "lodash";
import queryString from "query-string";
import { Col, Row } from "../components/FlexboxGrid";
import fetchData from "../enhancers/fetchData";
import compose from "../utils/compose";
import parseQuery from "../enhancers/parseQuery";
import didMount from "../enhancers/didMount";
import PageFrame from "../widgets/PageFrame";
import Button from "../components/Button";
import Table from "../components/Table";
import Dialog from "../components/Dialog";
import RoleEditor from "../widgets/RoleEditor";
import Pager from "../components/Pager";
import LinkButton from "../components/LinkButton";
import Alert from "../components/Alert";
import ApiCaller from "../utils/ApiCaller";
import DataStore from "../utils/DataStore";

const dataStore = new DataStore();
const DefaultPageSize = 10;

function addRole(reload) {
  Dialog.show(
    <RoleEditor
      title="新增角色"
      role={{
        name: "",
        menuIds: [],
        functionIds: [],
      }}
      created={() => {
        Alert.success("保存成功");
        Dialog.close();
        reload();
      }}
    />,
    {
      buttons: false,
      className: "dialog",
    }
  );
}

function editRole(role, reload) {
  Dialog.show(
    <RoleEditor
      title="编辑角色"
      role={role}
      created={() => {
        Dialog.close();
        reload();
      }}
    />,
    {
      buttons: false,
      className: "dialog",
    }
  );
}

async function deleteRole(role, reload) {
  if (dataStore.state.userCounts[role.id] > 0) {
    Alert.warning("只有角色用户数为0时才可删除");
  } else {
    const ok = await Alert.warning("确认要删除？", { buttons: ["否", "是"] });
    if (ok === true) {
      const result = await ApiCaller.deleteRole(role.id);
      if (result.status == 200) {
        reload();
        Alert.success("删除成功");
      } else {
        Alert.warning(result.data);
      }
    }
  }
}

const RoleList = props => {
  const { history, location, query, loading, data } = props;
  const filter = JSON.parse(query.filter || "{}");
  if (data == null) return null;
  const reload = () => {
    history.push(location.pathname + "?" + queryString.stringify(query));
  };
  return (
    <PageFrame title="角色管理">
      <Row valign="middle">
        <Button primary onClick={() => addRole(reload)}>
          新增角色
        </Button>
      </Row>
      <Table
        headerRow={
          <tr>
            <th>角色名称</th>
            <th>角色用户数</th>
            <th>操作</th>
          </tr>
        }
      >
        {data.content.length > 0 ? (
          data.content.map((role, index) => (
            <tr key={index}>
              <td className="center">{role.name}</td>
              <td className="center">
                {(dataStore.state.userCounts &&
                  dataStore.state.userCounts[role.id]) ||
                  0}
              </td>
              <td className="center">
                <LinkButton onClick={() => editRole(role, reload)}>
                  编辑
                </LinkButton>
                <LinkButton onClick={() => deleteRole(role, reload)}>
                  删除
                </LinkButton>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="8" className="center">
              暂无数据
            </td>
          </tr>
        )}
      </Table>
      <Pager
        history={history}
        location={location}
        query={query}
        total={data.totalElements}
        pageSize={DefaultPageSize}
        pageSizes={[10, 20, 30]}
      />
    </PageFrame>
  );
};

export default compose(
  parseQuery,
  fetchData({
    url: p => "/role/fetch",
    config: p => {
      const query = {
        page: 1,
        size: DefaultPageSize,
        filter: "{}",
        order: "-updatedAt",
        ...p.query,
      };
      return {
        method: "POST",
        data: {
          ...JSON.parse(query.filter),
          currentPage: query.page - 1 || query.currentPage,
          pageSize: query.size || DefaultPageSize,
          skip: (query.currentPage - 1) * query.pageSize,
          limit: query.pageSize,
        },
      };
    },
    extract: result => result.data,
  }),
  didMount(async p => {
    dataStore.state.userCounts = await ApiCaller.getUserCounts();
  }),
  view
)(RoleList);
