import katex from "katex";
import parseLatex from "./parseLatex.js";

function latex2Html(latex) {
  const enviroment = parseLatex(latex);
  return generateHtmlForCommands(enviroment.commands, []);
}

function generateHtmlForCommands(commands, containerStyle) {
  const html = [];
  for (const command of commands) {
    html.push(generateHtmlForCommandType(command, containerStyle));
  }
  return html.join("\n");
}

function generateHtmlForCommandType(command, containerStyle) {
  switch (command.type) {
    case "text":
      return `<span>${command.content}</span>`;
    case "math":
      return katex.renderToString(command.content);
    case "displaymath":
      return katex.renderToString(command.content, { displayMode: true });
    case "enviroment":
      return generateHtmlForEnviroment(command);
    case "command":
      return generateHtmlForCommandName(command, containerStyle);
    default:
      console.warn(command.type + " command is not supported.");
      break;
  }
}

function generateHtmlForEnviroment(enviroment) {
  switch (enviroment.name) {
    case "figure":
    case "subfigure":
      const style = [];
      const html = generateHtmlForCommands(enviroment.commands, style);
      return `<div class="${enviroment.name}" style="${style.join(
        ";"
      )}">${html}</div>`;
    default:
      console.warn(enviroment.name + " enviroment is not supported.");
      break;
  }
}

function generateHtmlForCommandName(command, containerStyle) {
  switch (command.name) {
    case "par":
      return `<p>${command.args[0]}</p>`;
    case "includegraphics":
      const style = [];
      for (const option of command.options) {
        if (option.name === "width") {
          style.push(`width:${option.value}`);
        }
      }
      return `<img style="${style.join(";")}" src="${command.args[0]}" />`;
    case "centering":
      containerStyle.push("justify-content: center");
      break;
    case "raggedright":
      containerStyle.push("justify-content: flex-end");
      break;
    case "caption":
    case "label":
      return `<label>${command.args[0]}</label>`;
    case "hspace":
      return `<span style="display:inline-block;width:${
        command.args[0]
      }"></span>`;
    case "\\":
      return "<br />";
    default:
      console.warn(command.name + " command is not supported.");
      break;
  }
}

// module.exports = latex2Html;
export default latex2Html;
