import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import _ from "lodash";
import ValidationError from "./ValidationError";

const ButtonList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Button = styled.li`
  white-space: nowrap;
  background: #fff;
  border: 1px solid #dcdfe6;
  font-weight: 500;
  color: #606266;
  text-align: center;
  margin: 0;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1;
  border-radius: 0;
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
  &:first-child:last-child {
    border-radius: 4px;
  }
  ${p =>
    p.checked
      ? css`
          color: #fff;
          background-color: #409eff;
          border-color: #409eff;
          box-shadow: -1px 0 0 0 #8cc5ff;
        `
      : css`
          &:hover {
            color: #409eff;
          }
        `};
`;

function checkOptions(options, values) {
  return options.map(option => ({
    ...option,
    checked: values.includes(option.value),
  }));
}

function checkedValues(options) {
  return options.filter(option => option.checked).map(option => option.value);
}

export default class MultiSelector extends Component {
  static propTypes = {
    options: PropTypes.array,
    values: PropTypes.array,
    onChange: PropTypes.func,
    selectAll: PropTypes.string,
  };

  state = {
    options: [],
    values: this.props.values || [],
    allChecked: false,
  };

  static getDerivedStateFromProps({ options, values = [] }) {
    return {
      options: checkOptions(options, values),
      allChecked: values.length == options.length,
    };
  }

  toggelChecked = option => {
    return () => {
      option.checked = !option.checked;
      const values = checkedValues(this.state.options);
      this.setState({ values });
      this.props.onChange && this.props.onChange(values);
    };
  };

  toggelAllChecked = () => {
    const allChecked = !this.state.allChecked;
    this.state.options.forEach(option => (option.checked = allChecked));
    const values = checkedValues(this.state.options);
    this.setState({ allChecked, values });
    this.props.onChange && this.props.onChange(values);
  };

  render() {
    return (
      <React.Fragment>
        <ButtonList>
          {this.props.selectAll && (
            <Button
              key={-1}
              checked={this.state.allChecked}
              onClick={this.toggelAllChecked}
            >
              {this.props.selectAll}
            </Button>
          )}
          {this.state.options.map((option, i) => (
            <Button
              key={i}
              checked={option.checked}
              onClick={this.toggelChecked(option)}
            >
              {option.label}
            </Button>
          ))}
        </ButtonList>
      </React.Fragment>
    );
  }
}
