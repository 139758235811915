import schema from "schm";

export const latexSchema = required =>
  schema({
    content: {
      type: String,
      required,
    },
    html: String,
    imgs: [String],
  });

export const videoFileSchema = schema({
  ccvid: String,
  eventId: Number,
  fileName: String,
});

export const questionSchema = schema({
  id: String,
  status: {
    type: String,
    default: "1", //草稿
    required: [true, "请选择适合的状态"],
  },
  subject: {
    type: String,
    default: "1", //数学
    required: [true, "请选择适合的科目"],
  },
  grade: {
    type: String,
    required: [true, "请选择适合的年级"],
  },
  type: {
    type: String,
    required: [true, "请选择题目类型"],
  },
  tags: Array,
  features: Array,
  ideologies: Array,
  difficulty: String,
  score: Number,
  blankLine: {
    type: Number,
    min: 0,
  },
  body: latexSchema(true),
  analysis: latexSchema(false),
  remark: latexSchema(false),
  categories: {
    type: Array,
  },
  videoFile: {
    type: Object,
    required: false,
  },
  from: {
    type: {
      year: Number,
      area: String,
      paper: String,
      number: Number,
    },
    required: false,
  },
});

const optionsSchema = schema({
  options: [
    {
      label: String,
      value: latexSchema(true),
    },
  ],
  correctOptions: {
    type: Array,
    default: [],
    minlength: [1, "请输入答案选项"],
  },
  optionsPerLine: {
    type: Number,
    default: 1,
  },
});

const answerSchema = schema({
  answer: latexSchema(false),
});

const moreSchema = schema({
  subQuestions: [
    {
      categories: {
        type: Array,
        minlength: [1, "请选择小问知识点"],
      },
      body: latexSchema(true),
      answer: latexSchema(false),
      analysis: latexSchema(false),
    },
  ],
});

export const groupQuestionSchema = schema({
  id: String,
  type: String,
  internalName: {
    type: String,
    required: [true, "  "],
    maxlength: [20, "题组名称最多20个字符"],
  },
  categories: {
    type: Array,
    required: [true, "  "],
  },
  subject: String,
  grade: String,
  body: latexSchema(true),
  diffCoefficient: Number,
  groupQuestions: [
    {
      questionId: String,
      blankLine: Number,
    },
  ],
  questionsPerLine: {
    type: Number,
    required: [true, "请选择排版"],
  }, //每行几题
});

export const choiceQuestionSchema = schema(questionSchema, optionsSchema);

export const answerQuestionSchema = schema(questionSchema, answerSchema);

export const moreQuestionSchema = schema(questionSchema, moreSchema);
