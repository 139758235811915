import React from "react";
import dayjs from "dayjs";

export default function DateTime({
  value,
  format = "YYYY-MM-DD HH:mm:ss",
  ...props
}) {
  const valueType = typeof value;
  const time =
    valueType === "string" || valueType === "number"
      ? value
      : valueType === "object"
        ? value.iso
        : null;
  return time && <span {...props}>{dayjs(time).format(format)}</span>;
}
