import React from "react";
import latex2Html from "../utils/latex2Html";
import ValidationError from "./ValidationError";

export default function LatexContent({ latex }) {
  try {
    const html = latex2Html(latex);
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  } catch (error) {
    return <ValidationError>语法错误：{error.message}</ValidationError>;
  }
}
