import injectElement from './inject-element';
/**
 * :NOTE: We are using get/setAttribute with SVG because the SVG DOM spec
 * differs from HTML DOM and can return other unexpected object types when
 * trying to directly access svg properties. ex: "className" returns a
 * SVGAnimatedString with the class value found in the "baseVal" property,
 * instead of simple string like with HTML Elements.
 */

var SVGInjector = function SVGInjector(elements, _temp, done) {
  var _ref = _temp === void 0 ? {} : _temp,
      _ref$evalScripts = _ref.evalScripts,
      evalScripts = _ref$evalScripts === void 0 ? 'always' : _ref$evalScripts,
      _ref$pngFallback = _ref.pngFallback,
      pngFallback = _ref$pngFallback === void 0 ? false : _ref$pngFallback,
      eachCallback = _ref.each,
      _ref$renumerateIRIEle = _ref.renumerateIRIElements,
      renumerateIRIElements = _ref$renumerateIRIEle === void 0 ? true : _ref$renumerateIRIEle;

  if (elements.length !== undefined) {
    var elementsLoaded = 0;
    Array.prototype.forEach.call(elements, function (element) {
      injectElement(element, evalScripts, pngFallback, renumerateIRIElements, function (svg) {
        if (eachCallback && typeof eachCallback === 'function') eachCallback(svg);
        if (done && elements.length === ++elementsLoaded) done(elementsLoaded);
      });
    });
  } else {
    if (elements) {
      injectElement(elements, evalScripts, pngFallback, renumerateIRIElements, function (svg) {
        if (eachCallback && typeof eachCallback === 'function') eachCallback(svg);
        if (done) done(1);
        elements = null;
      });
    } else {
      if (done) done(0);
    }
  }
};

export default SVGInjector;