import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import ApiCaller from "../utils/ApiCaller";

export default class FileUploader extends Component {
  static propTypes = {
    uploadText: PropTypes.string,
    name: PropTypes.string,
    fileType: PropTypes.string,
    uploadFile: PropTypes.func,
  };

  state = { uploading: false, progress: 0 };

  handleClick = () => {
    this.uploader.click();
  };

  uploadFile = async event => {
    let file = event.target.files[0];
    if (file) {
      this.setState({ uploading: true });
      const result = await ApiCaller.uploadFile(file, e => {
        if (e.lengthComputable) {
          this.setState({ progress: (e.loaded / e.total).toFixed(2) });
        }
      });
      this.setState({ uploading: false, progress: 0 });
      this.props.onUploaded && this.props.onUploaded(result);
    }
  };

  render() {
    const { uploading, progress } = this.state;
    return (
      <React.Fragment>
        <input
          ref={elem => (this.uploader = elem)}
          accept={this.props.fileType}
          name={this.props.name}
          type="file"
          style={{ display: "none" }}
          onChange={this.uploadFile}
        />
        <Button primary onClick={this.handleClick}>
          {uploading ? progress * 100 + "%" : this.props.uploadText}
        </Button>
      </React.Fragment>
    );
  }
}
