const config = {
  local: {
    apiServer: "https://tiku.keyilian.com/tiku-server",
    msLiveUrl: "https://tiku-dev.lianxuetang.com/ms-live",
    fileServer: "https://tiku.keyilian.com/tiku-upload",
    tikuJobServer: "https://tiku.keyilian.com/tiku-job",
    mkWebServer: "https://mk-dev.lianxuetang.com",
  },
  dev: {
    apiServer: "https://tiku.keyilian.com/tiku-server",
    msLiveUrl: "https://tiku-dev.lianxuetang.com/ms-live",
    fileServer: "https://tiku.keyilian.com/tiku-upload",
    tikuJobServer: "https://tiku.keyilian.com/tiku-job",
    mkWebServer: "https://mk-dev.lianxuetang.com",
  },
  review: {
    apiServer: "https://tiku.keyilian.com/tiku-server",
    msLiveUrl: "https://tiku-review.lianxuetang.com/ms-live",
    tikuJobServer: "https://tiku.keyilian.com/tiku-job",
    mkWebServer: "https://mk-review.lianxuetang.com",
    fileServer: "http://ms-file-upload:8080",
  },
  prod: {
    apiServer: "https://tiku.keyilian.com/tiku-server",
    msLiveUrl: "https://tiku.lianxuetang.com/ms-live",
    fileServer: "https://teacher.lianxuetang.com/file-upload",
    tikuJobServer: "https://tiku.keyilian.com//tiku-job",
    mkWebServer: "https://www.lianxuetang.com",
  },
};

const deploy = preval`
const deploy = process.env.DEPLOY || "local";
module.exports = deploy;
`;
console.log("Deploy target:", deploy);

export default config[deploy];
