import React, { Component } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import Svg from "react-svg";

const SearchDiv = styled.div`
  position: relative;
  display: inline-block;
  ${p =>
    p.width
      ? css`
          width: ${p => p.width};
        `
      : css`
          width: 100%;
        `};
  ${p =>
    p.margin
      ? css`
          margin: ${p => p.margin};
        `
      : css`
          margin: 0;
        `};
  input {
    width: 100%;
    height: 36px;
    line-height: 36px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    color: #606266;
    outline: 0;
    padding: 0 15px;
    padding-right: 30px;
  }
  div {
    position: absolute;
    right: 3px;
    top: 4px;
    svg {
      color: #b9babc;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }
  }
`;

export default class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }
  static propTypes = {
    width: PropTypes.any,
    margin: PropTypes.any,
    onEnter: PropTypes.func,
  };

  onkeydown = event => {
    if (event.keyCode == 13) {
      this.props.onEnter && this.props.onEnter(event.target.value);
    }
  };

  onClick = () => {
    this.props.onEnter && this.props.onEnter(this.textInput.current.value);    
  }

  render() {
    const { width, margin, onEnter, ...other } = this.props;
    return (
      <SearchDiv width={width} margin={margin}>
        <input ref={this.textInput} onKeyDown={this.onkeydown} {...other} />
        <Svg onClick={this.onClick} src={require("../resources/icons/search.svg")} />
      </SearchDiv>
    );
  }
}
