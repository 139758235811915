import React from "react";
import styled, { css } from "styled-components";
import queryString from "query-string";
import { store, view } from "react-easy-state";
import _ from "lodash";
import { Col, Row } from "../components/FlexboxGrid";
import Button from "../components/Button";
import Dialog from "../components/Dialog";
import Pager from "../components/Pager";
import fetchData from "../enhancers/fetchData";
import PageFrame from "../widgets/PageFrame";
import Popup from "../widgets/Popup";
import compose from "../utils/compose";
import parseQuery from "../enhancers/parseQuery";
import QuestionDetail from "./QuestionDetail";
import QuestionItem from "../widgets/QuestionItem";
import QuestionGroup from "../widgets/QuestionGroup";
import QuestionPreview from "../widgets/QuestionPreview";
import ApiCaller from "../utils/ApiCaller";

const DefaultPageSize = 10;

const ButtonRow = styled(Row)`
  justify-content: flex-end;
  padding: 0 1em 1em;
  button,
  label {
    margin-right: 10px;
  }
`;

function viewQuestion(question) {
  Dialog.show(
    <Popup title="查看题目">
      <QuestionDetail question={question} />
    </Popup>,
    {
      buttons: false,
      className: "dialog",
    }
  );
}

function preview(question) {
  Dialog.show(<QuestionPreview question={question} />, {
    buttons: false,
    className: "previewQuestionDialog",
  });
}

async function restoreQuestion(question, callback) {
  const result = await ApiCaller.restoreQuestion(question.id);
  if (result.data == "ok") {
    callback();
  }
}

const QuestionColumn = styled(Col)`
  width: 100%;
`;

const QuestionList = props => {
  const { history, location, query, loading, data } = props;
  if (data == null) return null;
  const reload = () => {
    history.push(location.pathname + "?" + queryString.stringify(query));
  };
  return (
    <PageFrame title="题目回收站" loading={loading}>
      <QuestionColumn>
        {data.questions.map(
          question =>
            question.groupQuestions ? (
              <QuestionGroup
                key={question.id}
                questionGroup={question}
                buttons={
                  <ButtonRow valign="middle">
                    <label>{question.groupQuestions.length}题</label>
                    <Button primary onClick={() => viewQuestion(question)}>
                      查看
                    </Button>
                    <Button
                      success
                      onClick={() => restoreQuestion(question, reload)}
                    >
                      恢复
                    </Button>
                  </ButtonRow>
                }
              />
            ) : (
              <QuestionItem
                key={question.id}
                question={question}
                buttons={
                  <ButtonRow>
                    <Button primary onClick={() => viewQuestion(question)}>
                      查看
                    </Button>
                    <Button
                      success
                      onClick={() => restoreQuestion(question, reload)}
                    >
                      恢复
                    </Button>
                  </ButtonRow>
                }
              />
            )
        )}
        <Pager
          history={history}
          location={location}
          query={query}
          total={data.total}
          pageSize={DefaultPageSize}
          pageSizes={[10, 20, 30]}
        />
      </QuestionColumn>
    </PageFrame>
  );
};

export default compose(
  parseQuery,
  fetchData({
    url: p => "/questions/deleted/list",
    config: p => {
      const query = {
        page: 1,
        size: DefaultPageSize,
        filter: "{}",
        order: "-updatedAt",
        ...p.query,
      };
      return {
        method: "POST",
        data: {
          ...JSON.parse(query.filter),
          order: query.order,
          currentPage: query.page - 1 || query.currentPage,
          pageSize: query.size || DefaultPageSize,
          skip: (query.currentPage - 1) * query.pageSize,
          limit: query.pageSize,
        },
      };
    },
    extract: async result => {
      const fetchedQuestions = await ApiCaller.fetchGroupQuestions(
        result.data.questions
      );
      return {
        total: result.data.total,
        questions: fetchedQuestions,
      };
    },
  }),
  view
)(QuestionList);
