import React from "react";
import styled, { css } from "styled-components";

const LinkButton = styled.a`
  color: #007bff !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;

  ${p =>
    p.disabled &&
    css`
      color: #7b7b7b !important;
      cursor: default;
    `};
  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

export default LinkButton;
