import React, { Component } from "react";
import axios from "axios";
import Alert from "../components/Alert";
import config from "../../config";

const fetchData = options => Comp => {
  return class FetchData extends Component {
    state = {
      loading: false,
      data: null,
    };

    async loadData(nextProps) {
      this.setState({ loading: true });
      const props = { ...config, ...nextProps };
      const result = await axios.request({
        url: options.url(props),
        ...(options.config && options.config(props)),
      });
      if (result.status == 200) {
        this.setState({
          loading: false,
          data: await options.extract(result),
        });
      } else {
        Alert.error(result.data.message);
        this.setState({
          loading: false,
          data: null,
        });
      }
    }

    componentWillReceiveProps(nextProps) {
      this.loadData(nextProps);
    }

    componentDidMount() {
      this.loadData(this.props);
    }

    render() {
      const { loading, data } = this.state;
      return <Comp loading={loading} data={data} {...this.props} />;
    }
  };
};

export default fetchData;
