import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import _ from "lodash";

const ButtonList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Button = styled.li`
  white-space: nowrap;
  background: #fff;
  border: 1px solid #dcdfe6;
  font-weight: 500;
  color: #606266;
  text-align: center;
  margin: 0;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1;
  border-radius: 0;
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
  &:first-child:last-child {
    border-radius: 4px;
  }
  ${p =>
    p.checked
      ? css`
          color: #fff;
          background-color: #409eff;
          border-color: #409eff;
        `
      : css`
          &:hover {
            color: #409eff;
          }
        `};
`;

export function createOptions(keyValues) {
  if (_.isArray(keyValues)) {
    return keyValues.map(key => ({ label: key, value: key }));
  } else {
    return _.map(_.toPairs(keyValues), ([value, label]) => ({ label, value }));
  }
}

function checkOptions(options, value) {
  return options.map(option => ({
    ...option,
    checked: option.value === value,
  }));
}

export default class Selector extends Component {
  static propTypes = {
    checkType: PropTypes.any,
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
  };

  state = {
    options: [],
  };

  static getDerivedStateFromProps({ options, value }) {
    return {
      options: checkOptions(options, value),
    };
  }

  doubleCancelChecked = option => {
    return () => {
      if (this.props.value && this.props.value == option.value) {
        this.setState({ value: option.null });
        this.props.onChange && this.props.onChange({ value: option.null });
      } else {
        this.setState({ value: option.value });
        this.props.onChange && this.props.onChange(option);
      }
    };
  };

  changeChecked = option => {
    return () => {
      this.setState({ value: option.value });
      this.props.onChange && this.props.onChange(option);
    };
  };

  render() {
    return (
      <React.Fragment>
        <ButtonList>
          {this.state.options.map((option, i) => (
            <Button
              key={i}
              checked={option.checked}
              onClick={
                this.props.checkType == "doubleCancel"
                  ? this.doubleCancelChecked(option)
                  : this.changeChecked(option)
              }
            >
              {option.label}
            </Button>
          ))}
        </ButtonList>
      </React.Fragment>
    );
  }
}
