'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Tree = function () {
  function Tree(obj) {
    var childNodeName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'children';

    _classCallCheck(this, Tree);

    this.cnt = 1;
    this.obj = obj || _defineProperty({}, childNodeName, []);
    this.indexes = {};
    this.childNodeName = childNodeName;
    this.build(this.obj);
  }

  _createClass(Tree, [{
    key: 'build',
    value: function build(obj) {
      var indexes = this.indexes;
      var startId = this.cnt;
      var self = this;

      var index = { id: startId, node: obj };
      indexes[this.cnt + ''] = index;
      this.cnt++;

      if (obj[self.childNodeName] && obj[self.childNodeName].length) {
        walk(obj[self.childNodeName], index);
      }

      function walk(objs, parent) {
        var children = [];
        objs.forEach(function (obj, i) {
          var index = {};
          index.id = self.cnt;
          index.node = obj;

          if (parent) index.parent = parent.id;

          indexes[self.cnt + ''] = index;
          children.push(self.cnt);
          self.cnt++;

          if (obj[self.childNodeName] && obj[self.childNodeName].length) walk(obj[self.childNodeName], index);
        });
        parent[self.childNodeName] = children;

        children.forEach(function (id, i) {
          var index = indexes[id + ''];
          if (i > 0) index.prev = children[i - 1];
          if (i < children.length - 1) index.next = children[i + 1];
        });
      }

      return index;
    }
  }, {
    key: 'getIndex',
    value: function getIndex(id) {
      var index = this.indexes[id + ''];
      if (index) return index;
    }
  }, {
    key: 'removeIndex',
    value: function removeIndex(index) {
      var self = this;
      del(index);

      function del(index) {
        delete self.indexes[index.id + ''];
        if (index[self.childNodeName] && index[self.childNodeName].length) {
          index[self.childNodeName].forEach(function (child) {
            del(self.getIndex(child));
          });
        }
      }
    }
  }, {
    key: 'get',
    value: function get(id) {
      var index = this.getIndex(id);
      if (index && index.node) return index.node;
      return null;
    }
  }, {
    key: 'remove',
    value: function remove(id) {
      var index = this.getIndex(id);
      var node = this.get(id);
      var parentIndex = this.getIndex(index.parent);
      var parentNode = this.get(index.parent);

      parentNode[this.childNodeName].splice(parentNode[this.childNodeName].indexOf(node), 1);
      parentIndex[this.childNodeName].splice(parentIndex[this.childNodeName].indexOf(id), 1);
      this.removeIndex(index);
      this.updateChildren(parentIndex[this.childNodeName]);

      return node;
    }
  }, {
    key: 'updateChildren',
    value: function updateChildren(children) {
      children.forEach(function (id, i) {
        var index = this.getIndex(id);
        index.prev = index.next = null;
        if (i > 0) index.prev = children[i - 1];
        if (i < children.length - 1) index.next = children[i + 1];
      }.bind(this));
    }
  }, {
    key: 'insert',
    value: function insert(obj, parentId, i) {
      var parentIndex = this.getIndex(parentId);
      var parentNode = this.get(parentId);

      var index = this.build(obj);
      index.parent = parentId;

      parentNode[this.childNodeName] = parentNode[this.childNodeName] || [];
      parentIndex[this.childNodeName] = parentIndex[this.childNodeName] || [];

      parentNode[this.childNodeName].splice(i, 0, obj);
      parentIndex[this.childNodeName].splice(i, 0, index.id);

      this.updateChildren(parentIndex[this.childNodeName]);
      if (parentIndex.parent) {
        this.updateChildren(this.getIndex(parentIndex.parent)[this.childNodeName]);
      }

      return index;
    }
  }, {
    key: 'insertBefore',
    value: function insertBefore(obj, destId) {
      var destIndex = this.getIndex(destId);
      var parentId = destIndex.parent;
      var i = this.getIndex(parentId)[this.childNodeName].indexOf(destId);
      return this.insert(obj, parentId, i);
    }
  }, {
    key: 'insertAfter',
    value: function insertAfter(obj, destId) {
      var destIndex = this.getIndex(destId);
      var parentId = destIndex.parent;
      var i = this.getIndex(parentId)[this.childNodeName].indexOf(destId);
      return this.insert(obj, parentId, i + 1);
    }
  }, {
    key: 'prepend',
    value: function prepend(obj, destId) {
      return this.insert(obj, destId, 0);
    }
  }, {
    key: 'append',
    value: function append(obj, destId) {
      var destIndex = this.getIndex(destId);
      destIndex[this.childNodeName] = destIndex[this.childNodeName] || [];
      return this.insert(obj, destId, destIndex[this.childNodeName].length);
    }
  }]);

  return Tree;
}();

module.exports = Tree;