import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

export default class DropdownList extends Component {
  static propTypes = {
    options: PropTypes.array,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    firstSelected: PropTypes.bool,
  };

  static defaultProps = {
    firstSelected: false,
    options: [],
  };

  state = {
    value:
      this.props.value ||
      this.props.defaultValue ||
      this.initialValue(this.props),
  };

  initialValue(props) {
    if (props.firstSelected && props.options.length > 0) {
      return _.first(props.options).value;
    }
  }

  handleChange = event => {
    this.value = event.target.value;
  };

  get value() {
    return this.state.value;
  }

  set value(val) {
    if (val != this.state.value) {
      this.setState({ value: val }, () => {
        if (this.props.onChange) {
          this.props.onChange(val);
        }
      });
    }
  }

  get selectedItem() {
    let selectedOption = this.props.options.find(
      option => option.value == this.state.value
    );
    return selectedOption && selectedOption.item;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      this.value = nextProps.value;
    } else if (this.props.options.length == 0 && nextProps.options.length > 0) {
      this.value = this.initialValue(nextProps);
    }
  }

  render() {
    const {
      options,
      value,
      defaultValue,
      onChange,
      firstSelected,
      ...other
    } = this.props;

    let createOption = (option, i) => {
      let { label, value, disabled } =
        typeof option == "string"
          ? { label: option, value: option, disabled: false }
          : option;
      return (
        <option disabled={disabled} key={i} value={value}>
          {label}
        </option>
      );
    };

    return (
      <select value={this.state.value} onChange={this.handleChange} {...other}>
        {firstSelected == false ? <option style={{ display: "none" }} /> : null}
        {options.map(createOption)}
      </select>
    );
  }
}
