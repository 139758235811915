import React from "react";
import styled from "styled-components";
import { Row, Col } from "../components/FlexboxGrid";
import Space from "../components/Space";

const Paper = styled(Col)``;

const Title = styled(Col)`
  max-width: 60%;
  text-align: center;
`;

const Student = styled.table`
  td,
  th {
    border: 2px solid #000;
    text-align: center;
    width: 6em;
    padding: 4px;
  }
  td {
    height: 3em;
  }
`;

const Question = styled(Col)`
  margin-top: 1em;
  div.body {
    text-align: left;
    img {
      vertical-align: text-top;
    }
  }
`;

const Option = styled(Row)`
  align-items: baseline;
  > span {
    margin-right: 0.5em;
  }
`;

const QuestionPreview = ({ number, question }) => (
  <Question>
    <Row nowrap>
      <label>
        {number}
        {question.groupQuestions ? 1 : 2}
        .&nbsp;
      </label>
      <div
        className="body"
        dangerouslySetInnerHTML={{
          __html: question.body && question.body.html,
        }}
      />
    </Row>
    {question.subQuestions && (
      <ol type="a">
        {question.subQuestions.map((subQuestion, index) => (
          <li key={index}>
            <div
              className="body"
              dangerouslySetInnerHTML={{
                __html: subQuestion.body && subQuestion.body.html,
              }}
            />
          </li>
        ))}
      </ol>
    )}
    {question.groupQuestions &&
      question.groupQuestions.map((groupQuestion, index) => (
        <Row nowrap key={index}>
          <Col>
            &nbsp;&nbsp;(
            {index + 1}
            ).
          </Col>
          <Col>
            {groupQuestion.subQuestions ? (
              <ol type="a">
                {groupQuestion.subQuestions.map((subQuestion, index) => (
                  <li key={index}>
                    <div
                      className="body"
                      dangerouslySetInnerHTML={{
                        __html: subQuestion.body && subQuestion.body.html,
                      }}
                    />
                  </li>
                ))}
              </ol>
            ) : (
              <div
                className="body"
                dangerouslySetInnerHTML={{
                  __html: groupQuestion.body && groupQuestion.body.html,
                }}
              />
            )}
          </Col>
        </Row>
      ))}
    {question.options &&
      question.options.map(option => (
        <Option key={option.label}>
          <span>{option.label}.</span>
          <div
            dangerouslySetInnerHTML={{
              __html: option.value && option.value.html,
            }}
          />
        </Option>
      ))}
    <Space lines={question.blankLine} />
  </Question>
);

const PaperPreview = ({ examPaper, questions }) => (
  <Paper>
    <Row space="between" valign="middle" nowrap>
      <Title>
        <h1>{examPaper.title}</h1>
        {examPaper.subtitle && <h2>{examPaper.subtitle}</h2>}
        {examPaper.duration > 0 && (
          <h3>
            考试时间不超过
            {examPaper.duration}
            分钟
          </h3>
        )}
      </Title>
      <Student>
        <thead>
          <tr>
            <th>用时</th>
            <th>姓名</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            <td />
          </tr>
        </tbody>
      </Student>
    </Row>
    {questions.map((question, index) => (
      <QuestionPreview key={index} number={index + 1} question={question} />
    ))}
  </Paper>
);

export default PaperPreview;
