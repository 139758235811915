import React from "react";
import styled from "styled-components";
import schema from "schm";
import _ from "lodash";
import { Row, Col } from "../components/FlexboxGrid";
import Dialog from "../components/Dialog";
import TextBox from "../components/TextBox";
import ValidationError from "../components/ValidationError";
import EditorDialog from "./EditorDialog";
import ApiCaller from "../utils/ApiCaller";

const FieldRow = styled(Row)`
  margin-bottom: 0.5em;
  input {
    margin-left: 0.5em;
  }
  li {
    padding: 10px 10px;
  }
`;

const subjectSchema = schema({
  id: String,
  number: {
    type: String,
    required: [true, "请输入科目编号"],
    maxlength: [5, "科目编号最多5个字符"],
  },
  name: {
    type: String,
    required: [true, "请输入科目名称"],
    maxlength: [10, "科目名称最多10个字符"],
  },
});

export default class SubjectEditor extends React.Component {
  constructor(props) {
    super(props);
    const { id, number, name } = props.subject;
    this.state = {
      id,
      number,
      name,
      errors: {},
    };
  }

  createOrUpdateSubject = async () => {
    try {
      const fields = await subjectSchema.validate(this.state);
      if (fields.id == null) {
        const subject = await ApiCaller.addSubject(fields);
        if (subject) {
          this.props.created && (await this.props.created(subject));
        }
      }
      return true;
    } catch (errors) {
      console.log(errors);
      this.setState({ errors: _.keyBy(errors, "param") });
      return false;
    }
  };

  render() {
    const { title } = this.props;
    const { number, name, errors } = this.state;
    return (
      <EditorDialog
        title={title}
        confirm={this.createOrUpdateSubject}
        close={() => Dialog.close()}
      >
        <FieldRow valign="middle">
          科目编号:
          <TextBox
            value={number}
            onChange={text => this.setState({ number: text })}
          />
          {errors["number"] && (
            <ValidationError>{errors["number"].message}</ValidationError>
          )}
        </FieldRow>
        <FieldRow nowrap>
          科目名称：
          <TextBox
            value={name}
            onChange={text => this.setState({ name: text })}
          />
          {errors["name"] && (
            <ValidationError>{errors["name"].message}</ValidationError>
          )}
        </FieldRow>
      </EditorDialog>
    );
  }
}
