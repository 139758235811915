import React from "react";
import styled, { css } from "styled-components";
import { store, view } from "react-easy-state";
import { Link } from "react-router-dom";
import _ from "lodash";
import compose from "../utils/compose";
import parseQuery from "../enhancers/parseQuery";
import didMount from "../enhancers/didMount";
import unMount from "../enhancers/unMount";
import DataStore from "../utils/DataStore";
import PageFrame from "../widgets/PageFrame";
import LatexContent from "../components/LatexContent";
import { Col, Row } from "../components/FlexboxGrid";
import Button from "../components/Button";
import Icon from "../components/Icon";
import Tag, { Mark } from "../components/Tag";
import Dialog from "../components/Dialog";
import Popup from "../widgets/Popup";
import QuestionDetail from "./QuestionDetail";
import TextBox from "../components/TextBox";
import ValidationError from "../components/ValidationError";
import Toast from "../components/Toast";
import ApiCaller from "../utils/ApiCaller";
import Alert from "../components/Alert";
import {
  grades,
  questionTypes,
  difficulties,
  questionFuns,
} from "../utils/constants";
import AppState from "../AppState";
import HashMap from "../utils/HashMap";
import { groupQuestionSchema } from "../utils/questionSchema";
import LatexEditor from "../components/LatexEditor";
import QuestionPreview from "../widgets/QuestionPreview";
import QuestionItem from "../widgets/QuestionItem";
import hasPermission from "../utils/hasPermission";

const dataStore = new DataStore({});

const QuestionHead = styled(Row)`
  padding: 10px 2px;
  h4,
  label {
    margin: 0;
  }
`;

const CardDiv = styled(Col)`
  display: flex;
  flex-direction: row;
`;

const Card = styled(Col)`
  width: calc(100% - 15px);
  margin-left: 10px;
  margin-bottom: 20px;
  background-color: #fff;
  color: #303133;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  border-radius: 4px;
  transition: 0.3s;
  overflow: hidden;
`;

const CardHeader = styled(Row)`
  padding: 18px 20px;
  border-bottom: 1px solid #ebeef5;
  h4,
  label {
    margin: 0;
  }
`;

const CardBody = styled(Col)`
  padding: 18px 20px;
`;

const OptionList = styled(Col)`
  margin-top: 1em;
`;

const OptionItem = styled(Row)`
  align-items: center;
  margin-bottom: 0.5em;
  label {
    margin-right: 1em;
    margin-bottom: 0;
  }
`;

const ButtonRow = styled(Row)`
  padding: 0.5em 0.5em;
  border-top: 1px solid #ebeef5;
  button {
    height: 100%;
    padding: 0.375rem 1rem;
    margin-left: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const FieldRow = styled(Row)`
  margin-bottom: 0.5em;
  label {
    margin-right: 1em;
    white-space: nowrap;
  }
  div {
    width: 90%;
  }
  input {
    width: 90%;
    height: 36px;
    line-height: 36px;
    padding-right: 30px;
    border-radius: 4px;
    outline: 0;
    padding: 0 15px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
  }

  input[type="number"] {
    width: 5em;
    text-align: center;
    margin-right: 0.3em;
  }
`;

const Select = styled.select`
  width: 5.5rem;
  padding: 0;
  text-align: center;
`;

const handleUp = index => {
  if (index === 0) {
    Alert.error("已经是一个了!");
    return;
  }

  const tmp = dataStore.state.questionCartData[index];
  dataStore.state.questionCartData.splice(
    index,
    1,
    dataStore.state.questionCartData[index - 1]
  );
  dataStore.state.questionCartData.splice(index - 1, 1, tmp);
};

const handleDown = index => {
  if (index === dataStore.state.questionCartData.length - 1) {
    Alert.error("已经是最后一位了!");
    return;
  }

  const tmp = dataStore.state.questionCartData[index];
  dataStore.state.questionCartData.splice(
    index,
    1,
    dataStore.state.questionCartData[index + 1]
  );
  dataStore.state.questionCartData.splice(index + 1, 1, tmp);
};

const QuestionGroupEditor = props => {
  if (dataStore.state.questionCart == null) {
    return null;
  }
  if (dataStore.state.questionCartData == null) {
    return null;
  }
  if (dataStore.state.counts == null) {
    return null;
  }
  if (!_.isEmpty(props.query) && dataStore.state.questionGroup == null) {
    return null;
  }
  const { history, location, query, loading } = props;
  const errors = dataStore.state.errors;
  return (
    <PageFrame title="编辑题组" loading={loading}>
      <FieldRow nowrap>
        <label>题组名称-内部搜索：</label>
        <TextBox
          placeholder="请输入题组名称（最多20个字符）"
          value={dataStore.state.questionGroup.internalName}
          onChange={text => (dataStore.state.questionGroup.internalName = text)}
          isValid={errors["internalName"] === undefined}
        />
        {errors["internalName"] && (
          <ValidationError>{errors["internalName"].message}</ValidationError>
        )}
      </FieldRow>
      <FieldRow nowrap>
        <label>知识点：</label>
        <Row shrink={1}>
          {dataStore.state.counts.categories.keys().map(category => (
            <Tag key={category}>
              {dataStore.state.categories[category]}
              <Mark>{dataStore.state.counts.categories[category]}</Mark>
            </Tag>
          ))}
        </Row>
      </FieldRow>
      <FieldRow>
        <label>年级：</label>
        {dataStore.state.counts.grades.keys().map(grade => (
          <Tag key={grade}>
            {grades[grade]}
            <Mark>{dataStore.state.counts.grades[grade]}</Mark>
          </Tag>
        ))}
      </FieldRow>
      <FieldRow>
        <label>类型：</label>
        {dataStore.state.counts.types.keys().map(type => (
          <Tag key={type}>
            {questionTypes[type]}
            <Mark>{dataStore.state.counts.types[type]}</Mark>
          </Tag>
        ))}
      </FieldRow>
      <FieldRow>
        <label>难度：</label>
        {dataStore.state.counts.difficulties.keys().map(difficulty => (
          <Tag key={difficulty}>
            {difficulties[difficulty]}
            <Mark>{dataStore.state.counts.difficulties[difficulty]}</Mark>
          </Tag>
        ))}
      </FieldRow>
      <QuestionHead space="between">
        <h4>题目详情：</h4>
        <label>共{dataStore.state.questionCartData.length}题</label>
      </QuestionHead>
      <FieldRow valign="middle" nowrap>
        <label>排版：</label>
        <Select
          value={dataStore.state.questionGroup.questionsPerLine}
          onChange={event =>
            (dataStore.state.questionGroup.questionsPerLine =
              event.target.value)
          }
        >
          <option value="1">一行1题</option>
          <option value="2">一行2题</option>
          <option value="3">一行3题</option>
          <option value="4">一行4题</option>
        </Select>
        {errors["questionsPerLine"] && (
          <ValidationError>
            {errors["questionsPerLine"].message}
          </ValidationError>
        )}
      </FieldRow>
      <FieldRow nowrap>
        <label>题组主标题-试卷展示：</label>
        <LatexEditor
          height="90px"
          placeholder="请输入题组主标题"
          latex={dataStore.state.questionGroup.body.content}
          images={dataStore.state.questionGroup.body.imgs}
          isValid={errors["body.content"] === undefined}
          onChange={content =>
            (dataStore.state.questionGroup.body.content = content)
          }
          onImageChange={images =>
            (dataStore.state.questionGroup.body.imgs = images)
          }
          onHtmlChange={html =>
            (dataStore.state.questionGroup.body.html = html)
          }
        />
      </FieldRow>
      {!_.isEmpty(dataStore.state.questionCartData) ? (
        dataStore.state.questionCartData.map((question, index) => (
          <CardDiv key={index}>
            <div>({index + 1})</div>
            <QuestionItemDiv
              key={question.id}
              index={index}
              question={question}
            />
          </CardDiv>
        ))
      ) : (
        <Col align="center">
          <ValidationError>题组中心没有题目，请先添加题目</ValidationError>
        </Col>
      )}
      <ButtonRow>
        <Button primary onClick={() => createOrUpdateQuestion()}>
          {dataStore.state.isCreate ? "生成题组" : "修改题组"}
        </Button>
        <Button
          primary
          onClick={() => {
            dataStore.state.questionGroup.subQuestions =
              dataStore.state.questionCartData;
            preview(dataStore.state.questionGroup);
          }}
        >
          预览
        </Button>
        <Button primary onClick={() => history.goBack()}>
          返回
        </Button>
      </ButtonRow>
      <input type="hidden" defaultValue={dataStore.state.messages} />
    </PageFrame>
  );
};

const QuestionItemDiv = ({ question, index }) => {
  const { blankLine } = question;
  return (
    <Card>
      <QuestionItem container={Col} question={question} />
      <ButtonRow space="between">
        <Row>
          <Button className="up-arrow" onClick={() => handleUp(index)}>
            <Icon src={require("../resources/icons/arrow-up.svg")} />
            上移
          </Button>
          <Button className="down-arrow" onClick={() => handleDown(index)}>
            <Icon src={require("../resources/icons/arrow-down.svg")} />
            下移
          </Button>
        </Row>
        <Row>
          <Row valign="middle">
            空行数：
            <TextBox
              type="number"
              value={blankLine}
              min={0}
              width="4em"
              onChange={blankLine => {
                dataStore.state.questionCartData.splice(index, 1, {
                  ...question,
                  blankLine: _.toNumber(blankLine),
                });
              }}
            />
            行 &nbsp;&nbsp;
          </Row>
          {(AppState.account.isAdmin ||
            hasPermission("编辑已答题目") ||
            ((question.studentCount == null || question.studentCount == 0) &&
              hasPermission("编辑题目"))) && (
            <Link to={`/question/edit?id=${question.id}`}>
              <Button primary>编辑</Button>
            </Link>
          )}
          <Button primary onClick={() => viewQuestion(question)}>
            查看
          </Button>
          <Button primary onClick={() => removeQuestion(question.id)}>
            移除
          </Button>
        </Row>
      </ButtonRow>
    </Card>
  );
};

function countQuestions(questions) {
  let categories = new HashMap(0);
  let grades = new HashMap(0);
  let subjects = new HashMap(0);
  let types = new HashMap(0);
  let tags = new HashMap(0);
  let difficulties = new HashMap(0);
  for (const question of questions) {
    for (const category of question.categories) {
      if (_.has(dataStore.state.categories, category.id)) {
        categories[category.id] += 1;
      }
    }
    if (question.tags) {
      for (const tag of question.tags) {
        tags[tag] += 1;
      }
    }
    grades[question.grade] += 1;
    subjects[question.subject] += 1;
    types[question.type] += 1;
    if (question.difficulty) {
      difficulties[question.difficulty] += 1;
    }
  }
  return { categories, grades, subjects, types, tags, difficulties };
}

async function createOrUpdateQuestion() {
  if (dataStore.state.questionCartData.length < 2) {
    Alert.error("题组的题目数必须大于1");
    return;
  }
  try {
    dataStore.state.errors = {};
    const groupQuestions = dataStore.state.questionCartData.map(question => {
      return {
        questionId: question.id,
        blankLine: question.blankLine,
      };
    });
    const categoryIds = _.uniq(
      _.map(
        _.flatten(_.map(dataStore.state.questionCartData, "categories")),
        "id"
      )
    );
    const questionGroup = await groupQuestionSchema.validate({
      ...dataStore.state.questionGroup,
      categories: await ApiCaller.getCategories(categoryIds),
      grade: dataStore.state.counts.grades.keys()[0],
      subject: dataStore.state.counts.subjects.keys()[0],
      diffCoefficient: _.round(
        _.sumBy(dataStore.state.questionCartData, "diffCoefficient") /
          dataStore.state.questionCartData.length,
        2
      ),
      groupQuestions,
    });
    const result = await ApiCaller.createOrUpdateQuestion(questionGroup);
    if (result) {
      if (dataStore.state.isCreate) {
        await ApiCaller.updateQuestionCart("question", []);
      }
      await Toast.info("保存成功");
      window.history.back();
    } else {
      Toast.error(result.data);
    }
  } catch (errors) {
    dataStore.state.errors = _.keyBy(errors, "param");
  }
}

function viewQuestion(question) {
  Dialog.show(
    <Popup title="查看题目">
      <QuestionDetail question={question} />
    </Popup>,
    {
      buttons: false,
      className: "dialog",
    }
  );
}

function preview(question) {
  Dialog.show(<QuestionPreview question={question} />, {
    buttons: false,
    className: "previewQuestionDialog",
  });
}

async function removeQuestion(questionId) {
  if (dataStore.state.questionIsUsed) {
    Alert.error("该题组已被使用，题目不能移除");
    return;
  }
  const arr = dataStore.state.questionCartData;
  arr.splice(arr.findIndex(item => item.id === questionId), 1);
  if (dataStore.state.isCreate) {
    await ApiCaller.updateQuestionCart("question", _.map(arr, "id"));
  }
  dataStore.state.counts = countQuestions(dataStore.state.questionCartData);
}

export default compose(
  parseQuery,
  unMount(() => {
    dataStore.state.questionCartData = [];
  }),
  didMount(async p => {
    dataStore.state.errors = {};
    dataStore.state.questionCart = await ApiCaller.questionCartFetch(
      "question"
    );
    if (_.isEmpty(p.query)) {
      dataStore.state.questionGroup = {
        internalName: "",
        type: "group",
        body: {
          content: "",
          html: "",
          imgs: [],
        },
        questionsPerLine: 1,
        subQuestions: [],
      };
      dataStore.state.isCreate = true;
      dataStore.state.questionIsUsed = false;
      dataStore.state.questionCartData = await ApiCaller.fetchQuestions(
        dataStore.state.questionCart
      );
    } else {
      dataStore.state.isCreate = false;
      dataStore.state.questionIsUsed = await ApiCaller.questionIsUsed(
        p.query.id
      );
      let questions = await ApiCaller.fetchQuestions([p.query.id]);
      dataStore.state.questionGroup = questions[0];
      dataStore.state.questionCartData = await ApiCaller.fetchExamPaperQuestions(
        {
          questions: dataStore.state.questionGroup.groupQuestions,
        }
      );
    }
    const categoryIds = _.uniq(
      _.map(
        _.flatten(_.map(dataStore.state.questionCartData, "categories")),
        "id"
      )
    );
    const categories = await ApiCaller.getCategories(categoryIds);
    dataStore.state.categories = _.mapValues(
      _.keyBy(categories, "id"),
      "name"
    );
    dataStore.state.counts = countQuestions(dataStore.state.questionCartData);
  }),
  view
)(QuestionGroupEditor);
