import React from "react";
import styled, { css } from "styled-components";

const Themes = {
  default: css`
    background-color: #e0e1e2;
    color: rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 0 1px transparent inset,
      0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    &:hover {
      background-color: #cacbcd;
      color: rgba(0, 0, 0, 0.8);
      box-shadow: 0 0 0 1px transparent inset,
        0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    }
  `,
  primary: css`
    background-color: #409eff;
    border-color: #409eff;
    color: #fff;
    &:hover {
      background-color: #66b1ff;
      border-color: #66b1ff;
    }
  `,
  success: css`
    background-color: #23d160;
    color: #fff;
    &:hover {
      background-color: #22c65b;
    }
  `,
  danger: css`
    background-color: #ff3860;
    color: #fff;
    &:hover {
      background-color: #ff2b56;
    }
  `,
  disabled: css`
    opacity: 0.5;
    pointer-events: none;
  `,
};

export const StyledButton = styled.button`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  padding: 0.375rem 0.75rem;
  border: 1px solid transparent;
  border-radius: 4px;
  opacity: 1;
  ${p => p.default && Themes.default};
  ${p => p.primary && Themes.primary};
  ${p => p.success && Themes.success};
  ${p => p.danger && Themes.danger};
  ${p => p.disabled && Themes.disabled};

  &:active,
  &:focus,
  &:hover {
    outline: 0;
  }
`;

export default class Button extends React.Component {
  state = { processing: false };

  onClick = () => {
    this.setState({ processing: true }, async () => {
      await this.props.onClick();
      this.setState({ processing: false });
    });
  };

  render() {
    const { processing } = this.state;
    const { disabled, onClick, ...other } = this.props;
    return (
      <StyledButton
        disabled={disabled || processing}
        onClick={this.onClick}
        {...other}
      />
    );
  }
}
