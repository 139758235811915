import React from "react";
import styled, { css } from "styled-components";
import Icon from "../components/Icon";
import { Row, Col } from "../components/FlexboxGrid";
import Dialog from "../components/Dialog";

const Frame = styled(Col)`
  h3 {
    color: #333;
    text-align: left;
    flex-grow: 1;
  }
  hr {
    width: 100%;
    border-top: none;
    border-bottom: 1px solid #e9e9e9;
  }
`;

const Button = styled(Icon)`
  svg {
    width: 1rem;
    height: 1rem;
    margin: 0.4rem 0.5rem 0;
  }
  cursor: pointer;
`;

export default function Popup({ title, close, children }) {
  if (close === undefined) {
    close = () => Dialog.close();
  }
  return (
    <Frame>
      <Row>
        <h3>{title}</h3>
        <Button onClick={close} src={require("../resources/icons/close.svg")} />
      </Row>
      <hr />
      {children}
    </Frame>
  );
}
