import React from "react";
import styled, { css } from "styled-components";

const StyledTable = styled.table`
  border: none;
  margin-top: 1rem;
  width: 100%;
  overflow: scroll;
  td,
  th {
    border-bottom: 1px solid #eee;
    padding: 0.5rem;
    font-size: 14px;
    a {
      vertical-align: middle;
    }
    a:not(:last-child) {
      margin-right: 0.5em;
    }
  }
  th {
    height: 57px;
    text-align: center;
    white-space: nowrap;
    background-color: rgba(140, 209, 247, 0.3);
  }
  td.center {
    height: 57px;
    text-align: center;
  }
  td.right {
    text-align: right;
  }
  td.nowrap {
    white-space: nowrap;
  }
  tr:hover {
    background-color: rgba(140, 209, 247, 0.1);
  }
  ${p =>
    p.clickable &&
    css`
      tr:hover {
        background-color: #eee;
      }
    `};
`;

const Table = ({ headerRow, children, ...other }) => (
  <StyledTable {...other}>
    <thead>{headerRow}</thead>
    <tbody>{children}</tbody>
  </StyledTable>
);

export default Table;
