import React, { useState } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Grid, Col, Row } from "./components/FlexboxGrid";
import SideNav from "./widgets/SideNav";
import TopBar from "./widgets/TopBar";
import Login from "./pages/Login";
import CategoryTree from "./pages/CategoryTree";
import QuestionList from "./pages/QuestionList";
import QuestionListDeleted from "./pages/QuestionListDeleted";
import QuestionEditor from "./pages/QuestionEditor";
import GroupQuestionList from "./pages/GroupQuestionList";
import ExamPaperList from "./pages/ExamPaperList";
import ExamPaperEditor from "./pages/ExamPaperEditor";
import QuestionGroupEditor from "./pages/QuestionGroupEditor";
import ExamPaperPreview from "./pages/ExamPaperPreview";
import ExamPaperPreviewAnswer from "./pages/ExamPaperPreviewAnswer";
import RoleList from "./pages/RoleList";
import AccountList from "./pages/AccountList";

const Container = styled(Row)`
  width: 100%;
  height: 100%;
`;

const Main = () => {
  const [sideNavVisible, setSideNavVisible] = useState(true);
  return (
    <Container nowrap>
      {sideNavVisible && <SideNav />}
      <Col grow={1} shrink={1}>
        <TopBar setSideNavVisible={setSideNavVisible} />
        <Switch>
          <Route exact path="/category" component={CategoryTree} />
          <Route exact path="/question/list" component={QuestionList} />
          <Route exact path="/question/edit" component={QuestionEditor} />
          <Route exact path="/question/deleted" component={QuestionListDeleted} />
          <Route exact path="/groupQuestion/list" component={GroupQuestionList} />
          <Route exact path="/groupQuestion/edit" component={QuestionGroupEditor} />
          <Route exact path="/examPaper/list" component={ExamPaperList} />
          <Route exact path="/examPaper/edit" component={ExamPaperEditor} />
          <Route exact path="/examPaper/copy" component={ExamPaperEditor} />
          <Route exact path="/role/list" component={RoleList} />
          <Route exact path="/account/list" component={AccountList} />
        </Switch>
      </Col>
    </Container>
  );
};

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/examPaper/preview" component={ExamPaperPreview} />
      <Route exact path="/examPaper/previewAnswer" component={ExamPaperPreviewAnswer} />
      <Route path="/" component={Main} />
    </Switch>
  </Router>
);

export default App;
