import cloneSvg from './clone-svg';
import svgCache from './svg-cache';
var requestQueue = [];
export var queueRequest = function queueRequest(url, callback) {
  requestQueue[url] = requestQueue[url] || [];
  requestQueue[url].push(callback);
};
export var processRequestQueue = function processRequestQueue(url) {
  for (var i = 0, len = requestQueue[url].length; i < len; i++) {
    // Make these calls async so we avoid blocking the page/renderer
    ;

    (function (index) {
      setTimeout(function () {
        requestQueue[url][index](cloneSvg(svgCache[url]));
      }, 0);
    })(i);
  }
};