import { store } from "react-easy-state";
import AccountManager from "./utils/AccountManager";
import ApiCaller from "./utils/ApiCaller";
import hasPermission from "./utils/hasPermission";
import _ from "lodash";

const AppState = store({
  account: {},
  menuIds: [],
  functionIds: [],
  roles: [],
  subjects: {},
  categoryTrees: {},
  version: 0,
});

export async function loadAppState() {
  const account = await AccountManager.loadAccount();
  if (Object.keys(account).length > 0) {
    const roles = await ApiCaller.getRolesByIds(account.roleIds);
    const accountRoles = account.roleIds.map(id => roles[id]);
    AppState.account = {
      ...account,
      roles: accountRoles,
      isAdmin: _.some(accountRoles, role => role.name == "ROLE_ADMIN"),
    };
    AppState.menuIds = _.uniq(_.flatMap(accountRoles, "menuIds"));
    AppState.functionIds = _.uniq(_.flatMap(accountRoles, "functionIds"));

    if (hasPermission("查看测试科目")) {
      AppState.subjects = await ApiCaller.getSubjects();
    } else {
      AppState.subjects = await ApiCaller.getNoTestSubjects();
    }
    AppState.categoryTrees = await ApiCaller.loadCategories(AppState.subjects);
  }
}

export default AppState;
