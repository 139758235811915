import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import queryString from "query-string";
import Tree from "react-ui-tree";
import { store, view } from "react-easy-state";
import { MenuItem, DropdownButton } from "@trendmicro/react-dropdown";
import _ from "lodash";
import { Col, Row } from "../components/FlexboxGrid";
import Button from "../components/Button";
import Dialog from "../components/Dialog";
import Pager from "../components/Pager";
import Alert from "../components/Alert";
import fetchData from "../enhancers/fetchData";
import { createOptions } from "../components/Selector";
import MultiSelector from "../components/MultiSelector";
import SearchInput from "../components/SearchInput";
import PageFrame from "../widgets/PageFrame";
import Popup from "../widgets/Popup";
import compose from "../utils/compose";
import parseQuery from "../enhancers/parseQuery";
import didMount from "../enhancers/didMount";
import DataStore from "../utils/DataStore";
import QuestionDetail from "./QuestionDetail";
import QuestionGroup from "../widgets/QuestionGroup";
import ApiCaller from "../utils/ApiCaller";
import Svg from "react-svg";
import Toast from "../components/Toast";
import { grades, orderName, questionGroupFuns } from "../utils/constants";
import AppState from "../AppState";
import hasPermission from "../utils/hasPermission";

const DefaultPageSize = 10;

const dataStore = new DataStore();

const FilterBox = styled(Col)`
  padding: 0.5em;
  border: 1px solid #ebeef5;
  margin-bottom: 10px;
`;

const FieldRow = styled(Row)`
  margin-bottom: 0.8em;
`;

const GroupBtn = styled.div`
  display: flex;
  margin-right: 0.5em;
  button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-color: hsla(0, 0%, 100%, 0.5);
    margin: 16px 0 16px 0;
  }
  button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin: 16px 0 16px 0;
  }
  button {
    margin: 0;
    display: flex;
    svg {
      fill: #fff;
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }
  }
`;

function filterQuestions(history, location, query, newFilter, newOrder) {
  const { order, page, filter = "{}", ...other } = query;
  let newQuery = {
    ...other,
    order: newOrder || order,
    page: 1,
    filter: JSON.stringify({
      ...JSON.parse(filter),
      ...newFilter,
    }),
  };
  history.push(location.pathname + "?" + queryString.stringify(newQuery));
}

function viewQuestion(question) {
  Dialog.show(
    <Popup title="查看题目">
      <QuestionDetail question={question} />
    </Popup>,
    {
      buttons: false,
      className: "dialog",
    }
  );
}

async function addQuestion(questionId) {
  const success = await ApiCaller.questionCartAdd("exam", questionId);
  if (success) {
    dataStore.state.questionCart = await ApiCaller.questionCartFetch("exam");
    await Toast.success("添加到组卷中心成功！");
  }
}

async function deleteQuestion(questionId, reload) {
  if (hasPermission("删除题组")) {
    const ok = await Alert.warning("确认要删除？", { buttons: ["否", "是"] });
    if (ok === true) {
      const result = await ApiCaller.deleteQuestion(questionId);
      if (result.status == 200) {
        reload();
        dataStore.state.deletedCount += 1;
        Alert.info("删除成功");
      } else {
        Alert.warning(result.data);
      }
    }
  } else {
    Alert.warning("没有删除题组的权限!");
  }
}

const CategoryTree = styled(Col)`
  padding: 0.5em;
  border: 1px solid #ebeef5;
  width: 230px;
`;
const QuestionColumn = styled(Col)`
  width: calc(100% - 240px);
`;

const TreeNode = styled.span`
  padding: 0.1em 0.8em;
  ${p =>
    p.selected &&
    css`
      background-color: #409eff;
      color: #fff;
    `};
`;

const OrderRow = styled(Row)`
  background: #f5f5f5;
  border: 1px solid #ebeff6;
  height: 50px;
  button {
    font-family: MicrosoftYaHei;
    font-size: 17px;
    color: #2d353a;
    border: none;
    background: transparent;
  }
`;

const ButtonRow = styled(Row)`
  button,
  label {
    margin-right: 10px;
  }
`;

const GroupQuestionList = props => {
  const { history, location, query, loading, data } = props;
  const filter = JSON.parse(query.filter || "{}");
  if (dataStore.state.data == null) return null;
  const order = query.order || "-updatedAt";
  const reload = () => {
    history.push(location.pathname + "?" + queryString.stringify(query));
  };
  return (
    <PageFrame
      title="题组管理"
      loading={loading}
      actions={
        <Row valign="middle">
          <SearchInput
            width="300px"
            margin="0 0.5rem 0 0"
            placeholder="请输入题组ID进行搜索"
            defaultValue={filter.id}
            onEnter={value =>
              filterQuestions(history, location, query, {
                id: value,
              })
            }
          />
          <SearchInput
            width="300px"
            margin="0 0.5rem 0 0"
            placeholder="请输入题组名称进行搜索"
            defaultValue={filter.keyword}
            onEnter={value =>
              filterQuestions(history, location, query, {
                keyword: value,
              })
            }
          />
          <GroupBtn>
            <Button primary>
              <Svg src={require("../resources/icons/documentation.svg")} />
              {dataStore.state.questionGroupCart && dataStore.state.questionGroupCart.length}
            </Button>
            <Button primary onClick={() => history.push("/groupQuestion/edit")}>
              题组中心
            </Button>
          </GroupBtn>
          <GroupBtn>
            <Button primary>
              <Svg src={require("../resources/icons/documentation.svg")} />
              {dataStore.state.questionCart && dataStore.state.questionCart.length}
            </Button>
            <Button primary onClick={() => history.push("/examPaper/edit")}>
              组卷中心
            </Button>
          </GroupBtn>
          <Link to={`/question/deleted`}>
            <Button default>
              回收站(
              {dataStore.state.deletedCount})
            </Button>
          </Link>
        </Row>
      }
    >
      <FilterBox>
        <FieldRow valign="middle">
          科目：
          <MultiSelector
            selectAll="全选"
            options={createOptions(AppState.subjects)}
            values={filter.subjects}
            onChange={values => filterQuestions(history, location, query, { subjects: values })}
          />
        </FieldRow>
        <FieldRow valign="middle">
          年级：
          <MultiSelector
            selectAll="全选"
            options={createOptions(grades)}
            values={filter.grades}
            onChange={values => filterQuestions(history, location, query, { grades: values })}
          />
        </FieldRow>
      </FilterBox>
      <Row space="between">
        <CategoryTree>
          <Tree
            paddingLeft={20}
            tree={{
              name: "所有分类",
              children:
                filter.subjects && filter.subjects.length > 0
                  ? _.filter(_.flatten(_.values(AppState.categoryTrees)), cat =>
                      filter.subjects.includes(cat.subject)
                    )
                  : _.flatten(_.values(AppState.categoryTrees)),
            }}
            renderNode={node => (
              <TreeNode
                selected={_.includes(filter.categories, [node.path, node.id].join(""))}
                onMouseDown={e => e.stopPropagation()}
                onClick={() => {
                  filterQuestions(history, location, query, {
                    categories: [[node.path, node.id].join("")],
                  });
                }}
              >
                {node.name}
              </TreeNode>
            )}
          />
        </CategoryTree>
        <QuestionColumn>
          <OrderRow valign="middle">
            <DropdownButton
              title={orderName[order] || "排序规则"}
              onSelect={newOrder => {
                filterQuestions(history, location, query, {}, newOrder);
              }}
            >
              <MenuItem eventKey="updatedAt" active={order == "updatedAt"}>
                时间升序
              </MenuItem>
              <MenuItem eventKey="-updatedAt" active={order == "-updatedAt"}>
                时间降序
              </MenuItem>
              <MenuItem eventKey="diffCoefficient" active={order == "diffCoefficient"}>
                难度升序
              </MenuItem>
              <MenuItem eventKey="-diffCoefficient" active={order == "-diffCoefficient"}>
                难度降序
              </MenuItem>
            </DropdownButton>
          </OrderRow>
          {dataStore.state.data.questions.map(question => (
            <QuestionGroup
              key={question.id}
              questionGroup={question}
              buttons={
                <ButtonRow valign="middle">
                  <label>{question.groupQuestions.length}题</label>
                  <label>难度系数:{question.diffCoefficient || 0}</label>
                  {/* <Button
                    primary
                    onClick={() => addQuestion(question.id)}
                    disabled={_.includes(
                      dataStore.state.questionCart,
                      question.id
                    )}
                  >
                    组卷
                  </Button> */}
                  <Button
                    primary
                    onClick={() => {
                      hasPermission("编辑题组")
                        ? history.push(`/groupQuestion/edit?id=${question.id}`)
                        : Alert.warning("没有编辑题组的权限!");
                    }}
                  >
                    编辑
                  </Button>
                  <Button danger onClick={() => deleteQuestion(question.id, reload)}>
                    删除
                  </Button>
                </ButtonRow>
              }
            />
          ))}
          <Pager
            history={history}
            location={location}
            query={query}
            total={dataStore.state.data.total}
            pageSize={DefaultPageSize}
            pageSizes={[10, 20, 30]}
          />
        </QuestionColumn>
      </Row>
    </PageFrame>
  );
};

export default compose(
  parseQuery,
  fetchData({
    url: p => "/questions/fetch",
    config: p => {
      const query = {
        page: 1,
        size: DefaultPageSize,
        filter: JSON.stringify({
          subjects: createOptions(AppState.subjects).map(subject => subject.value),
        }),
        order: "-updatedAt",
        ...p.query,
      };
      return {
        method: "POST",
        data: {
          isGroup: true,
          ...JSON.parse(query.filter),
          order: query.order,
          currentPage: query.page - 1 || query.currentPage,
          pageSize: query.size || DefaultPageSize,
          skip: (query.currentPage - 1) * query.pageSize,
          limit: query.pageSize,
        },
      };
    },
    extract: async result => {
      const fetchedQuestions = await ApiCaller.fetchGroupQuestions(result.data.questions);
      dataStore.state.data = {
        total: result.data.total,
        questions: fetchedQuestions,
      };
    },
  }),
  didMount(async p => {
    dataStore.state.deletedCount = await ApiCaller.deletedQuestionCount();
    dataStore.state.questionCart = await ApiCaller.questionCartFetch("exam");
    dataStore.state.questionGroupCart = await ApiCaller.questionCartFetch("question");
  }),
  view
)(GroupQuestionList);
